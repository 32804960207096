import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

export type TransactionsHeaderPropsLabels =
  | 'transporter'
  | 'customers'
  | 'depot'
  | 'workArea'
  | 'customer'
  | 'date'
  | 'arrivalLocation'
  | 'departureLocation'

export type TransactionsHeaderItem = {
  label: TransactionsHeaderPropsLabels
  value?: string | number
}
export type TransactionsHeaderProps = {
  items: TransactionsHeaderItem[]
}
export const TransactionsHeader: FC<TransactionsHeaderProps> = ({ items }) => {
  const definedItems = items.filter(item => item.value)
  const { t } = useTranslation()

  return (
    <Box sx={{ bgcolor: RfhColors.lightGrey }} p={2} py={1.5}>
      {definedItems.map(({ label, value }) => (
        <Box key={label + value}>
          <Typography component={'span'} variant={'body1'} className={'--bold'}>
            {t(`transactions.header.${label}`)}:{' '}
          </Typography>
          <Typography component={'span'} variant={'body1'}>
            {value}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
