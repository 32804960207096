import { AxiosError, AxiosResponse } from 'axios'
import { assign, interpret } from 'xstate'
import { createModel } from 'xstate/lib/model'

import {
  queryTransactions,
  QueryTransactionsParam,
  updateTransactionStatus,
} from 'src/services/transactionService'
import { FustAmount } from 'src/types/fust'
import { FustCard } from 'src/types/fustCard'
import { FustTransaction, TransactionType } from 'src/types/transaction'

export type IssuanceTransactionsDataGridMachineContext = {
  selectedTransactions: FustTransaction[]
  listOfTransactions: FustTransaction[]
  selectedTransactionDetail?: FustTransaction
  fustAmount: FustAmount[]
  fustPassNumber?: FustCard['FustpasNummer']
  transactionType?: TransactionType
  error?: string
}
export const issuanceTransactionsDataGridMachineModel = createModel(
  {
    listOfTransactions: [],
    selectedTransactionDetail: undefined,
    selectedTransactions: [],
    fustAmount: [],
    error: undefined,
  } as IssuanceTransactionsDataGridMachineContext,
  {
    events: {
      LOAD_TRANSACTIONS_LIST: (
        transactionType: TransactionType,
        params: Partial<QueryTransactionsParam>
      ) => ({
        transactionType,
        params,
      }),
      ASSIGN_SELECTED_TRANSACTIONS: (transactions: FustTransaction[]) => ({
        transactions,
      }),
      ASSIGN_FUST_AMOUNT: (fustAmount: FustTransaction['fustAantallen']) => ({
        fustAmount,
      }),
      VIEW_TRANSACTION_DETAIL: (transaction: FustTransaction) => ({
        transaction,
      }),
      CLOSE_TRANSACTION_DETAIL_VIEW: () => ({}),
      RESET_ERROR: () => ({}),
      UPDATE_TRANSACTION_STATUS: (txId: string, params: FustCard) => ({
        txId,
        params,
      }),
    },
  }
)
export const issuanceTransactionsDataGridMachine =
  issuanceTransactionsDataGridMachineModel.createMachine(
    {
      id: 'issuanceTransactionsDataGridMachine',
      predictableActionArguments: true,
      context: issuanceTransactionsDataGridMachineModel.initialContext,
      initial: 'viewing_list_of_transactions',
      tsTypes: {} as import('./issuanceTransactionsDataGridMachine.typegen').Typegen0,
      schema: {
        services: {} as {
          loadTransactions: { data: AxiosResponse<FustTransaction[]> }
          updateTransactionStatus: { data: AxiosResponse<void> }
        },
      },
      states: {
        viewing_list_of_transactions: {
          on: {
            VIEW_TRANSACTION_DETAIL: {
              actions: 'assignSelectedTransactionDetail',
              target: 'viewing_transaction_details',
            },
            LOAD_TRANSACTIONS_LIST: {
              target: 'loading_transactions',
            },
            ASSIGN_SELECTED_TRANSACTIONS: {
              actions: 'assignSelectedTransactions',
            },
            ASSIGN_FUST_AMOUNT: {
              actions: 'assignFustAmount',
            },
            UPDATE_TRANSACTION_STATUS: {
              target: 'updating_transaction_status',
            },
          },
        },
        updating_transaction_status: {
          invoke: {
            src: 'updateTransactionStatus',
            onDone: 'viewing_list_of_transactions',
            onError: {
              actions: 'assignError',
              target: 'viewing_list_of_transactions',
            },
          },
        },
        viewing_transaction_details: {
          on: {
            CLOSE_TRANSACTION_DETAIL_VIEW: {
              actions: 'clearSelectedTransactionDetail',
              target: 'viewing_list_of_transactions',
            },
          },
        },
        loading_transactions: {
          invoke: {
            src: 'loadTransactions',
            onDone: [
              {
                actions: 'assignLoadedTransactions',
                target: 'viewing_list_of_transactions',
              },
            ],
            onError: [
              {
                actions: 'assignError',
                target: 'viewing_list_of_transactions',
              },
            ],
          },
          tags: 'loading',
        },
      },
      on: {
        RESET_ERROR: {
          actions: 'resetError',
        },
      },
    },
    {
      actions: {
        assignError: assign((_context, event) => ({
          error: String((event.data as AxiosError).response?.data),
        })),
        resetError: assign(_ => ({ error: undefined })),
        assignSelectedTransactionDetail:
          issuanceTransactionsDataGridMachineModel.assign((_context, event) => {
            return {
              selectedTransactionDetail: event.transaction,
            }
          }),
        assignSelectedTransactions: issuanceTransactionsDataGridMachineModel.assign(
          (_context, event) => {
            return {
              selectedTransactions: event.transactions,
            }
          }
        ),
        assignFustAmount: issuanceTransactionsDataGridMachineModel.assign(
          (_context, event) => {
            return {
              fustAmount: event.fustAmount,
            }
          }
        ),
        clearSelectedTransactionDetail: issuanceTransactionsDataGridMachineModel.assign(
          () => {
            return {
              selectedTransactionDetail: undefined,
            }
          }
        ),
        assignLoadedTransactions: assign((_context, event) => {
          return {
            listOfTransactions: event.data.data,
          }
        }),
      },
      services: {
        updateTransactionStatus: async (_context, event) =>
          updateTransactionStatus(event.txId, event.params),
        loadTransactions: async (_context, event) =>
          queryTransactions(event.transactionType, event.params),
      },
    }
  )

export const issuanceTransactionsDataGridMachineService = interpret(
  issuanceTransactionsDataGridMachine
)
