import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import EditIcon from '@rfh/ui/shared/floriday-icons/icons/EditIcon'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { RoundIconButton } from 'src/components/RoundIconButton'
import { ValidatedCountTable } from 'src/components/ValidatedCountTable'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  rebookItemStateMachineModel,
  rebookItemStateService,
} from 'src/machines/rebookItemStateMachine'

const { SUBMIT_ITEM_STATE, EDIT_ITEM_STATE } = rebookItemStateMachineModel.events

export const Review: FC<{ service: typeof rebookItemStateService }> = ({ service }) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const navigate = useNavigate()

  return (
    <>
      <SuccessDialog
        title={'Omboeking succesvol afgerond'}
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => {
          navigate('/')
        }}
      />
      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <Stack
            direction={'row'}
            px={2}
            py={2}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <RfhTypography variant={'subtitle1'}>
              {t('rebook.validateBooking')}
            </RfhTypography>
            <RoundIconButton
              variant='outlined'
              icon={<EditIcon />}
              label='Aanpassen'
              onClick={() => {
                send(EDIT_ITEM_STATE())
              }}
            />
          </Stack>
          <ValidatedCountTable
            columns={[
              'fustCode',
              'itemState',
              'itemStateNew',
              'stapelwagens',
              'stapels',
              'stuks',
            ]}
            isAnnouncedTransaction={false}
            rows={state.context.fustAmount}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() => {
              send(SUBMIT_ITEM_STATE())
            }}
          >
            {t('button.handle')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
