import format from 'date-fns/format'

import { InfoOutlined } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { MultiSelectableDataGridRow } from '../components/MultiSelectableDataGrid'
import { FustTransaction, TransactionType } from '../types/transaction'

export type getDataGridTransactionsRowsProps = {
  listOfTransactionsState: FustTransaction[]
  onDetailClickHandler: (transaction: FustTransaction) => void
  transactionType?: TransactionType
}
export const getDataGridTransactionsRows = ({
  listOfTransactionsState,
  onDetailClickHandler,
  transactionType,
}: getDataGridTransactionsRowsProps): MultiSelectableDataGridRow[] => {
  return listOfTransactionsState.map((transaction: FustTransaction) => {
    const row = {
      id: transaction.id,
      columns: [
        {
          component: <span>{transaction.klantNaam}</span>,
        },
        {
          component: (
            <span>
              {transaction.fustAantallen
                .map(({ stapelwagens }) => stapelwagens)
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                )}
            </span>
          ),
        },
        {
          component: (
            <Box
              aria-label={'bekijk transactie details'}
              role={'button'}
              onClick={() => onDetailClickHandler(transaction)}
            >
              <InfoOutlined
                sx={{
                  display: 'inherit',
                  fontSize: '1.1rem',
                  color: RfhColors.dutchOrange,
                }}
              />
            </Box>
          ),
        },
      ],
    }

    if (transactionType === TransactionType.UitgifteDirect) {
      row.columns.splice(1, 0, {
        component: <span>{transaction.vervoerderNaam}</span>,
      })
    } else if (transactionType === TransactionType.UitgifteDock) {
      row.columns.splice(0, 0, {
        component: (
          <span>{format(new Date(transaction.createdOn), 'dd/MM HH:mm')}</span>
        ),
      })
    }

    return row
  })
}
