import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@rfh/ui/components/Inputs/TextField'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import { WorkAreasAutocomplete } from 'src/components/inputs/WorkAreasAutocomplete'
import { intakeFlowMachineModel } from 'src/machines/intakeFlowMachine'
import { Authority } from 'src/types/authority'
import { Branch } from 'src/types/branch'
import { Depot } from 'src/types/depot'
import { IntakeType } from 'src/types/intake'
import { TransactionType } from 'src/types/transaction'
import { WorkArea } from 'src/types/workArea'
import { IntakeCreateFlowContext } from 'src/views/IntakeFlow'

const {
  CONTINUE,
  ASSIGN_SELECTED_DEPOT,
  ASSIGN_SELECTED_WORKAREA,
  ASSIGN_SELECTED_AUTHORITY,
  ASSIGN_SELECTED_TRANSACTION_TYPE,
} = intakeFlowMachineModel.events

export const PreInformation: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(IntakeCreateFlowContext)
  const [state, send] = useActor(service)

  const authorities = state.context.scannedFustPassContext?.authorityList
  const selectedBranch = localStorage.getItem('mfb-selected-branch')

  const sortAuthorityByName = useMemo(
    () => authorities?.sort((a, b) => a.name.localeCompare(b.name)),
    [authorities]
  )

  return (
    <>
      <FlowView>
        <FlowViewContent>
          <RfhTypography variant={'subtitle1'}>{t('intake.general')}</RfhTypography>
          <RadioGroup
            row
            value={state.context.selectedTransactionType}
            onChange={event => {
              send(ASSIGN_SELECTED_TRANSACTION_TYPE(Number(event.target.value)))
            }}
          >
            <FormControlLabel
              value={TransactionType.InnameVast}
              control={<Radio />}
              label={IntakeType.fixed}
            />
            <FormControlLabel
              value={TransactionType.InnameMobiel}
              control={<Radio />}
              label={IntakeType.mobile}
            />
          </RadioGroup>
          {state.context.selectedTransactionType === TransactionType.InnameVast && (
            <>
              <DepotsAutocomplete
                value={state?.context?.selectedDepot}
                onChange={(_event, value) => {
                  send(ASSIGN_SELECTED_DEPOT(value as Depot))
                }}
              />
              {!!authorities?.length && (
                <Autocomplete
                  sx={{ mt: 2 }}
                  options={sortAuthorityByName || []}
                  getOptionLabel={option => `${option.id} - ${option.name}`}
                  value={state.context.selectedAuthority}
                  loadingText={t('loading')}
                  onChange={(_event, value) => {
                    send(ASSIGN_SELECTED_AUTHORITY(value as Authority))
                  }}
                  autoSelect
                  renderInput={params => (
                    <TextField
                      {...params}
                      autoFocus
                      fullWidth
                      placeholder={t('inputs.selectCustomer')}
                    />
                  )}
                />
              )}
            </>
          )}
          {state.context.selectedTransactionType === TransactionType.InnameMobiel && (
            <WorkAreasAutocomplete
              // Hide combobox for branches other than Aalsmeer. These branches had just one workarea and is selected automatically.
              sx={{ display: selectedBranch === Branch.Aalsmeer ? 'auto' : 'none' }}
              onLoadedData={data => {
                if (selectedBranch !== Branch.Aalsmeer) {
                  send(ASSIGN_SELECTED_WORKAREA(data[0]))
                }
              }}
              value={state.context.selectedWorkArea}
              onChange={(_event, value) => {
                send(ASSIGN_SELECTED_WORKAREA(value as WorkArea))
              }}
            />
          )}
        </FlowViewContent>
        <FlowViewActions>
          <Button
            disabled={!state.can('CONTINUE')}
            variant={'contained'}
            onClick={() => send(CONTINUE())}
          >
            {t('button.continue')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
