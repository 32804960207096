import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { CountTable, CountTableProps } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  rebookItemStateMachineModel,
  rebookItemStateService,
} from 'src/machines/rebookItemStateMachine'

const { ASSIGN_ITEM_STATE, CONTINUE } = rebookItemStateMachineModel.events

export const Rebooking: FC<{ service: typeof rebookItemStateService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const [canContinue, setCanContinue] = useState<boolean>(true)

  const handleRowAdd: CountTableProps['handleRowAdd'] = row => {
    send(ASSIGN_ITEM_STATE([...state.context.fustAmount, row]))
  }
  const handleRowRemove: CountTableProps['handleRowRemove'] = rowIndex => {
    send(ASSIGN_ITEM_STATE(state.context.fustAmount.filter((_, i) => i !== rowIndex)))
  }
  const handleRowEdit: CountTableProps['handleRowEdit'] = (row, rowIndex) => {
    send(
      ASSIGN_ITEM_STATE(
        state.context.fustAmount.map((fustAantal, i) =>
          i === rowIndex ? row : fustAantal
        )
      )
    )
  }

  return (
    <FlowView px={0} loading={state.hasTag('loading')}>
      <FlowViewContent>
        <RfhTypography variant='subtitle1' mb={2} px={2}>
          {t('rebook.general')}
        </RfhTypography>
        <CountTable
          rows={state.context.fustAmount}
          columns={[
            'fustCode',
            'itemState',
            'itemStateNew',
            'stapelwagens',
            'stapels',
            'stuks',
          ]}
          handleRowAdd={handleRowAdd}
          handleRowEdit={handleRowEdit}
          handleRowRemove={handleRowRemove}
          setCanContinue={setCanContinue}
          maxStkLength={5}
        />
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          disabled={!(state.can('CONTINUE') && canContinue)}
          variant={'contained'}
          onClick={() => send(CONTINUE())}
        >
          {t('button.confirm')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
