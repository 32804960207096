import { FC } from 'react'

import { BoxProps } from '@mui/material/Box/Box'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { StyledBox } from 'src/components/BranchCard.styles'
import { Branch } from 'src/types/branch'

export type BranchCardProps = {
  title: Branch
} & BoxProps

export const BranchCard: FC<BranchCardProps> = ({ title, ...rest }) => (
  <StyledBox role={'button'} {...rest}>
    <RfhTypography variant={'h5'}>{title}</RfhTypography>
  </StyledBox>
)
