import { produce } from 'immer'
import { spawn } from 'xstate'

import { Row } from '@rfh/ui/components/DataGrid/DataGrid'

import {
  issuanceTransactionsDataGridMachine,
  issuanceTransactionsDataGridMachineModel,
} from 'src/machines/issuanceTransactionsDataGridMachine'
import { FustAmount } from 'src/types/fust'
import { FustCard } from 'src/types/fustCard'
import { TransactionType } from 'src/types/transaction'

export const groupBy = <ListType, KeyType extends keyof any>(
  arr: ListType[],
  key: (object: ListType) => KeyType
): Record<KeyType, ListType[]> =>
  arr.reduce((groups, item) => {
    const arrayToPush = (groups[key(item)] ||= [])
    arrayToPush.push(item)
    return groups
  }, {} as Record<KeyType, ListType[]>)

export const getGroupByFustCodeTransactionsRows = (
  allFustAmounts: FustAmount[],
  hasTotals = true
): Row[] => {
  const groupOfFustCodes = groupBy(allFustAmounts, transaction => transaction.fustCode)

  return Object.keys(groupOfFustCodes).map(fustCode => {
    const row = {
      values: [
        fustCode,
        groupOfFustCodes[Number(fustCode)]
          .map(value => value.stapelwagens)
          .reduce((previousValue, currentValue) => {
            return previousValue + currentValue
          }, 0),
        groupOfFustCodes[Number(fustCode)]
          .map(value => value.stapels)
          .reduce((previousValue, currentValue) => {
            return previousValue + currentValue
          }, 0),
        groupOfFustCodes[Number(fustCode)]
          .map(value => value.stuks)
          .reduce((previousValue, currentValue) => {
            return previousValue + currentValue
          }, 0),
      ],
    }

    if (hasTotals) {
      row.values.push(
        groupOfFustCodes[Number(fustCode)]
          .map(value => value.totaal)
          .reduce((previousValue, currentValue) => {
            return previousValue + currentValue
          }, 0)
      )
    }

    return row
  })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const getSpawnedIssuanceTransactionsDataGridMachine = (
  fustPassNumber: FustCard['FustpasNummer'],
  transactionType: TransactionType
) => {
  return spawn(
    issuanceTransactionsDataGridMachine.withContext({
      ...issuanceTransactionsDataGridMachineModel.initialContext,
      fustPassNumber,
      transactionType,
    }),
    'issuanceTransactionsDataGridMachine'
  )
}

export const noNegativeNumber = (number: number): number => (number >= 0 ? number : 0)

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const calculateRemainderFustAmount = (
  originalFustAmount: FustAmount[],
  dividedFustAmount: FustAmount[]
): FustAmount[] =>
  produce(originalFustAmount, draft => {
    draft.forEach(amount => {
      const divideAmount = dividedFustAmount.find(
        divAmount => amount.fustCode === divAmount.fustCode
      )!

      if (divideAmount) {
        amount.stapelwagens = noNegativeNumber(
          amount.stapelwagens - divideAmount.stapelwagens
        )
        amount.stuks = noNegativeNumber(amount.stuks - divideAmount.stuks)
        amount.slepen = noNegativeNumber(amount.slepen - divideAmount.slepen)
        amount.stapels = noNegativeNumber(amount.stapels - divideAmount.stapels)
      }
    })
  })
