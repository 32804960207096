import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { FustAmountDataGrid } from 'src/components/IssuanceFlow/FustAmountDataGrid'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import { ConfirmFustPassNumberDialog } from 'src/components/dialogs/ConfirmFustPassNumberDialog'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'
import { FustAmount } from 'src/types/fust'
import { FustTransaction } from 'src/types/transaction'

export type ReviewTransactionSingleCustomerProps = {
  service: typeof issuanceDirectTransactionsService

  depotName?: string
  customerName?: string
  transactions: FustTransaction[]
}

const {
  HANDLE_TRANSACTION,
  CLOSE_SUCCESS_DIALOG,
  ASSIGN_SELECTED_TRANSACTION,
  ASSIGN_EDIT_TRANSACTION,
  CLOSE_FUST_PASS_NUMBER_DIALOG,
  CONTINUE,
} = issuanceDirectTransactionsMachineModel.events

export const ReviewTransactionSingleCustomer: FC<
  ReviewTransactionSingleCustomerProps
> = ({ service, depotName, customerName, transactions }) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const cumulativeFustAmount: FustAmount[] = ([] as FustAmount[]).concat(
    ...transactions.map((transaction: FustTransaction) => transaction.fustAantallen)
  )

  const fustPassNumber = useSelector(
    service,
    currentState =>
      currentState.context.scannedFustPassContext?.scannedFustPass?.fustpasNummer
  )

  return (
    <>
      <ConfirmFustPassNumberDialog
        isOpen={state.matches('confirming_with_fust_pass')}
        handleClose={
          /* istanbul ignore next */ () => send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }
        handleSubmit={/* istanbul ignore next */ () => send(CONTINUE())}
        fustPassNumberToConfirm={fustPassNumber!}
      />
      <SuccessDialog
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => {
          send(CLOSE_SUCCESS_DIALOG())
        }}
      />
      <FlowView loading={state.hasTag('loading')} p={0}>
        <FlowViewContent>
          <TransactionsHeader
            items={[
              {
                label: 'depot',
                value: state.context.numberOfCustomers > 1 ? depotName : '',
              },
              {
                label: 'customer',
                value: customerName,
              },
              {
                label: 'customers',
                value:
                  state.context.numberOfCustomers > 1
                    ? `${state.context.updatedNumberOfCustomers} / ${state.context.numberOfCustomers}`
                    : '',
              },
            ]}
          />

          <FustAmountDataGrid
            fustAmount={cumulativeFustAmount}
            title={t('issuance.validateOrders')}
            isDividable={
              transactions.length === 1 && !state.context.hasEditedTransaction
            }
            isEditable={
              transactions.length === 1 && !state.context.hasLateDividedTransaction
            }
            transaction={transactions[0]}
            onEditTransactionHandler={currentTransaction => {
              send(ASSIGN_EDIT_TRANSACTION(currentTransaction))
            }}
            onSplitTransactionHandler={currentTransaction => {
              send(ASSIGN_SELECTED_TRANSACTION(currentTransaction))
            }}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              send(HANDLE_TRANSACTION())
            }}
          >
            {t('button.handle')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
