import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { TransactionTypeForm } from 'src/components/IssuanceFlow/TransactionTypeForm'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import { WorkAreasAutocomplete } from 'src/components/inputs/WorkAreasAutocomplete'
import { Branch } from 'src/types/branch'
import { Depot } from 'src/types/depot'
import { TransactionType } from 'src/types/transaction'
import { WorkArea } from 'src/types/workArea'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export const PreInformation: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(IssuanceCreateFlowContext)
  const [state, send] = useActor(service)

  const selectedBranch = localStorage.getItem('mfb-selected-branch')

  return (
    <FlowView>
      <FlowViewContent>
        <Stack direction={'column'} gap={2}>
          <TransactionTypeForm />
          {state.context.selectedTransactionType === TransactionType.UitgifteWinkel && (
            <DepotsAutocomplete
              value={state?.context?.selectedDepot}
              onChange={(_event, value) => {
                send({
                  type: 'ASSIGN_SELECTED_DEPOT',
                  depot: value as Depot,
                })
              }}
            />
          )}
          {state.context.selectedTransactionType === TransactionType.UitgifteDirect &&
            selectedBranch === Branch.Aalsmeer && (
              <WorkAreasAutocomplete
                value={state?.context?.selectedWorkArea}
                onChange={(_event, value) => {
                  send({
                    type: 'ASSIGN_SELECTED_WORK_AREA',
                    workArea: value as WorkArea,
                  })
                }}
              />
            )}
        </Stack>
      </FlowViewContent>
      <FlowViewActions>
        <Button
          disabled={!state.can('CONTINUE')}
          variant={'contained'}
          onClick={() => send({ type: 'CONTINUE' })}
        >
          {t('button.continue')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
