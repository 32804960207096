/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC } from 'react'

import { useActor, useSelector } from '@xstate/react'

import { DivideTransactionView } from 'src/components/IssuanceFlow/views/DivideTransactionView'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'

const { UPDATE_SELECTED_TRANSACTION_FUST_AMOUNT, HANDLE_DIVIDE_TRANSACTION } =
  issuanceDirectTransactionsMachineModel.events

export type DivideTransactionProps = {
  service: typeof issuanceDirectTransactionsService
  customerName?: string
}

export const DivideTransaction: FC<DivideTransactionProps> = ({
  customerName,
  service,
}) => {
  const [state, send] = useActor(service)

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction!
  )

  const updatedFustAmount = useSelector(
    service,
    currentState => currentState.context.updatedFustAmount!
  )

  if (!selectedTransaction && !updatedFustAmount) {
    return null
  }

  return (
    <DivideTransactionView
      loading={state.hasTag('loading')}
      updatedFustAmount={updatedFustAmount}
      submissionClickHandler={() => {
        send(HANDLE_DIVIDE_TRANSACTION(selectedTransaction.id, updatedFustAmount))
      }}
      handleSplitChangeHandler={fustAmount => {
        send(UPDATE_SELECTED_TRANSACTION_FUST_AMOUNT(fustAmount))
      }}
    >
      <TransactionsHeader items={[{ label: 'customer', value: customerName }]} />
    </DivideTransactionView>
  )
}
