import { FC } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { FustAmountKeys, FustAmount, FustAmountColumnLabel } from 'src/types/fust'

export type ValidatedCountTableProps = {
  rows?: FustAmount[]
  isAnnouncedTransaction: boolean
  columns: FustAmountKeys[]
}

const ValidatedHeadRow: FC<{ columns: ValidatedCountTableProps['columns'] }> = ({
  columns,
}) => (
  <ValidatedGridContainer
    sx={{
      backgroundColor: ({ rfhColors }) => rfhColors.leafGreen,
      color: ({ rfhColors }) => rfhColors.white,
      fontWeight: 'bold',
    }}
    columns={columns.length}
  >
    {columns.map(column => (
      <ValidatedGridItem key={FustAmountColumnLabel[column]}>
        {column === 'itemState' && columns.includes('itemStateNew')
          ? 'StO' // alternative column title for rebook flow
          : FustAmountColumnLabel[column]}
      </ValidatedGridItem>
    ))}
  </ValidatedGridContainer>
)

const ValidatedRow: FC<{
  row: FustAmount
  index: number
  isAnnouncedTransaction: boolean
  columns: ValidatedCountTableProps['columns']
}> = ({ row, isAnnouncedTransaction, columns }) => {
  const announcedColor = row.correct ? '#C9F3E4' : '#F9C8CC'
  const unannouncedColor = RfhColors.white
  const borderAnnouncedColor = row.correct ? RfhColors.hydroGreen : RfhColors.alertRed
  const borderUnannouncedColor = RfhColors.fogWhite

  return (
    <ValidatedGridContainer
      sx={{
        backgroundColor: isAnnouncedTransaction ? announcedColor : unannouncedColor,
        borderBottom: `4px solid ${
          isAnnouncedTransaction ? borderAnnouncedColor : borderUnannouncedColor
        }`,
        '.MuiGrid-item:last-child': {
          fontWeight: 700,
        },
      }}
      columns={columns.length}
    >
      {columns.map((column, index) => (
        <ValidatedGridItem key={column + index}>{row[column]}</ValidatedGridItem>
      ))}
    </ValidatedGridContainer>
  )
}

const ValidatedGridContainer: FC<GridProps> = ({ children, ...rest }) => (
  <Grid container px={1} spacing={0} columns={5} {...rest}>
    {children}
  </Grid>
)

const ValidatedGridItem: FC<GridProps> = ({ children, ...rest }) => (
  <Grid
    item
    xs={1}
    sx={{
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      padding: ({ spacing }) => spacing(0, 1),
    }}
    {...rest}
  >
    {children}
  </Grid>
)

export const ValidatedCountTable: FC<ValidatedCountTableProps> = ({
  rows,
  isAnnouncedTransaction,
  columns,
}) => (
  <>
    <ValidatedHeadRow columns={columns} />
    {rows?.map((row, index) => (
      <ValidatedRow
        key={index}
        row={row}
        index={index}
        columns={columns}
        isAnnouncedTransaction={isAnnouncedTransaction}
      />
    ))}
  </>
)
