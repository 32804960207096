import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor, useSelector } from '@xstate/react'

import { CountTable } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  TransactionsHeader,
  TransactionsHeaderItem,
} from 'src/components/TransactionsHeader'
import {
  intakeFixedTransactionsMachineModel,
  intakeFixedTransactionsService,
} from 'src/machines/intakeFixedTransactionsMachine'
import { intakeMobileTransactionsService } from 'src/machines/intakeMobileTransactionsMachine'
import { FustAmount } from 'src/types/fust'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable id-blacklist */

type CountProps = {
  transactionsHeaderItems: TransactionsHeaderItem[]
  service:
    | typeof intakeMobileTransactionsService
    | typeof intakeFixedTransactionsService
}

const { ASSIGN_FUST_AMOUNT, CONTINUE } = intakeFixedTransactionsMachineModel.events
export const Count: FC<CountProps> = ({ service, transactionsHeaderItems }) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const [canContinue, setCanContinue] = useState<boolean>(true)

  const fustAmount = useSelector(
    service,
    currentState => currentState.context.fustAmount
  )

  const handleRowAdd = (newRow: FustAmount) =>
    send(ASSIGN_FUST_AMOUNT([...fustAmount, newRow]))

  const handleRowRemove = (index: number) =>
    send(ASSIGN_FUST_AMOUNT(fustAmount.filter((_, i) => i !== index)))

  const handleRowEdit = (row: FustAmount, index: number) => {
    const rows = [...fustAmount!]
    rows[index] = row

    send(ASSIGN_FUST_AMOUNT(rows))
  }

  return (
    <FlowView p={0} loading={state.hasTag('loading')}>
      <FlowViewContent>
        <TransactionsHeader items={transactionsHeaderItems} />
        <Stack direction={'column'} pt={2}>
          <RfhTypography variant={'subtitle1'} px={2}>
            {t('count')}
          </RfhTypography>
          <Box pt={2}>
            <CountTable
              rows={fustAmount}
              handleRowAdd={handleRowAdd}
              handleRowRemove={handleRowRemove}
              handleRowEdit={handleRowEdit}
              setCanContinue={setCanContinue}
              columns={['fustCode', 'stapelwagens', 'stapels', 'stuks']}
            />
          </Box>
        </Stack>
      </FlowViewContent>
      <FlowViewActions px={2}>
        <RfhButton
          onClick={() => send(CONTINUE())}
          variant='block--contained'
          disabled={!(state.can('CONTINUE') && canContinue)}
          fullWidth
        >
          {t('button.continue')}
        </RfhButton>
      </FlowViewActions>
    </FlowView>
  )
}
