import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewContent } from 'src/components/FlowView'
import { inventoryManagementMachineModel } from 'src/machines/inventoryManagementMachine'
import { InventoryManagementFlowContext } from 'src/views/InventoryManagementFlow'

const { START_NEW_COUNT, START_PREVIOUSLY_COUNT_FUST_AMOUNT, START_NEW_BULK_COUNT } =
  inventoryManagementMachineModel.events
export const InventoryOperationOptions: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(InventoryManagementFlowContext)
  const send = useActor(service)[1]

  return (
    <>
      <FlowView>
        <FlowViewContent>
          <Stack direction={'column'} gap={2}>
            <RfhTypography variant={'subtitle1'}>
              {t('inventoryManagement.options')}
            </RfhTypography>
            <Button
              variant={'contained'}
              onClick={() => {
                send(START_NEW_COUNT())
              }}
            >
              {t('button.newCount')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                send(START_NEW_BULK_COUNT())
              }}
            >
              {t('button.newBulkCount')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                send(START_PREVIOUSLY_COUNT_FUST_AMOUNT())
              }}
            >
              {t('button.prevCount')}
            </Button>
          </Stack>
        </FlowViewContent>
      </FlowView>
    </>
  )
}
