import { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DataGrid from '@rfh/ui/components/DataGrid/DataGrid'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { DataGridWrapper } from 'src/components/DataGridWrapper'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { SplitOrEditTable } from 'src/components/SplitOrEditTable'
import {
  useDivideTransaction,
  UseDivideTransactionProps,
} from 'src/hooks/useDivideTransaction'
import nlTranslations from 'src/i18n/nl/translations.json'
import { FustAmount } from 'src/types/fust'

export type DivideTransactionViewProps = {
  updatedFustAmount: FustAmount[]
  loading?: boolean
  label?: string
  submissionClickHandler: MouseEventHandler
  handleSplitChangeHandler: UseDivideTransactionProps['handleSplitChangeHandler']
  title?: string
}

export const DivideTransactionView: FC<DivideTransactionViewProps> = ({
  children,
  handleSplitChangeHandler,
  loading,
  updatedFustAmount,
  label = nlTranslations.issuance.transactionRemainder,
  submissionClickHandler,
  title = nlTranslations.issuance.divideTransaction,
}) => {
  const { t } = useTranslation()
  const { remainderFustAmount, handleSplitChange } = useDivideTransaction({
    initialFustAmount: updatedFustAmount,
    updatedFustAmount,
    handleSplitChangeHandler,
  })

  return (
    <FlowView loading={loading} p={0}>
      <FlowViewContent>
        {children}
        <Stack direction={'column'} gap={2}>
          <Box px={2} pt={2}>
            <RfhTypography variant={'subtitle1'}>{title}</RfhTypography>
          </Box>

          <SplitOrEditTable
            fustAmount={updatedFustAmount}
            handleChange={handleSplitChange}
            tableType='split'
          />

          <Box px={2}>
            <RfhTypography variant={'subtitle1'}>
              {t('issuance.remainder')}
            </RfhTypography>
          </Box>
          <DataGridWrapper>
            <DataGrid
              headColor={RfhColors.lightGrey}
              rows={[
                {
                  header: true,
                  values: [
                    t('countTable.headings.code'),
                    t('countTable.headings.stw'),
                    t('countTable.headings.stpl'),
                    t('countTable.headings.stk'),
                  ],
                },
                ...remainderFustAmount.map(
                  ({ fustCode, stapels, stapelwagens, stuks }) => ({
                    header: false,
                    values: [fustCode, stapelwagens, stapels, stuks],
                  })
                ),
              ]}
              label={label}
            />
          </DataGridWrapper>
        </Stack>
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button variant={'contained'} onClick={submissionClickHandler}>
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
