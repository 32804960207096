import format from 'date-fns/format'

import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import DataGrid, { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  TransactionsHeader,
  TransactionsHeaderProps,
} from 'src/components/TransactionsHeader'
import { CustomerNumberDialog } from 'src/components/dialogs/CustomerNumberDialog'
import nlTranslations from 'src/i18n/nl/translations.json'
import {
  intakeFixedTransactionsMachineModel,
  intakeFixedTransactionsService,
} from 'src/machines/intakeFixedTransactionsMachine'
import { FustTransaction } from 'src/types/transaction'

export type TransactionsFixedFormData = {
  transaction?: FustTransaction
  customerNumber?: string
}

const {
  LOAD_TRANSACTIONS,
  CONTINUE_UNANNOUNCED,
  CONTINUE,
  ASSIGN_SELECTED_TRANSACTION,
  ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER,
  CLOSE_CUSTOMER_NUMBER_DIALOG,
} = intakeFixedTransactionsMachineModel.events

const createRowsItems = (
  transactions: FustTransaction[],
  handleSend: typeof intakeFixedTransactionsService.send,
  selectedTransaction?: FustTransaction
) => {
  return transactions.map(transaction => {
    return {
      values: [
        <Radio
          value={transaction.id}
          checked={transaction.id === selectedTransaction?.id}
          onChange={event => {
            const target = transactions.find(
              current => current.id === event.target.value
            )
            handleSend(ASSIGN_SELECTED_TRANSACTION(target as FustTransaction))
          }}
          aria-label={`Selecteer ${transaction.id}`}
          sx={{ padding: 0 }}
          inputProps={{ 'aria-label': `Selecteer ${transaction.id}` }}
        />,
        transaction.id,
        format(new Date(transaction.createdOn), 'dd/MM HH:mm'),
      ],
    }
  })
}
const defaultHeaderRow: Row = {
  values: [
    '',
    nlTranslations.transactions.order,
    nlTranslations.transactions.header.date,
  ],
  header: true,
}
export const Transactions: FC<{ service: typeof intakeFixedTransactionsService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [transactionRows, setTransactionsRows] = useState<Row[]>([
    {
      values: [],
    },
  ])

  const [state, send] = useActor(service)

  const transactions = useSelector(
    service,
    currentState => currentState.context.listOfTransactions
  )

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction
  )

  useEffect(() => {
    send(LOAD_TRANSACTIONS())
  }, [])

  useEffect(() => {
    if (transactions.length) {
      setTransactionsRows([
        defaultHeaderRow,
        ...createRowsItems(transactions, send, selectedTransaction),
      ])
    } else {
      setTransactionsRows([defaultHeaderRow])
    }
  }, [transactions, selectedTransaction])

  return (
    <>
      <CustomerNumberDialog
        isOpen={state.matches('asking_customer_number')}
        setIsOpen={value => {
          if (!value) {
            send(CLOSE_CUSTOMER_NUMBER_DIALOG())
          }
          return value
        }}
        onSubmit={value => {
          send(ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER(value.nummer, value.naam))
          return Promise.resolve()
        }}
      />
      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <TransactionsHeader
            items={[
              {
                label: 'depot',
                value: `${state.context.selectedDepot?.naam}`,
              },
              ...(state.context.selectedAuthority
                ? ([
                    {
                      label: 'transporter',
                      value: `${state.context.scannedFustPassContext?.scannedFustPass?.klant?.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
                    },
                    {
                      label: 'customer',
                      value: `${state.context.selectedAuthority.id} - ${state.context.selectedAuthority.name}`,
                    },
                  ] as TransactionsHeaderProps['items'])
                : ([
                    {
                      label: 'customer',
                      value: `${state.context.scannedFustPassContext?.scannedFustPass?.klant?.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
                    },
                  ] as TransactionsHeaderProps['items'])),
            ]}
          />
          <Stack direction={'column'} pt={2} px={2}>
            <RfhTypography variant='subtitle1' mb={2}>
              {t('transactions.select')}
            </RfhTypography>
            <DataGrid
              headColor={RfhColors.leafGreen}
              label={t('transactions.general')}
              layout={[2, 5, 5]}
              rows={transactionRows}
            />
            {transactions?.length === 0 && (
              <Box
                bgcolor={RfhColors.white}
                border={`1px solid ${RfhColors.lightGrey}`}
                p={1}
              >
                {t('transactions.noTransactions')}
              </Box>
            )}
          </Stack>
        </FlowViewContent>
        <FlowViewActions px={2}>
          <RfhButton
            disabled={!state.can('CONTINUE')}
            onClick={() => send(CONTINUE())}
            fullWidth
            variant='contained'
          >
            {t('button.count')}
          </RfhButton>
          <RfhButton
            onClick={() => send(CONTINUE_UNANNOUNCED())}
            fullWidth
            variant='outlined'
          >
            {t('button.unannounced')}
          </RfhButton>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
