import { AxiosError, AxiosResponse } from 'axios'
import parseIso from 'date-fns/parseISO'
import { assign } from 'xstate'
import { createModel } from 'xstate/lib/model'

import { Depot } from 'src/types/depot'

import {
  loadPreviouslyCountFustAmount,
  submitInventoryFustAmount,
  validateInventoryFustAmount,
} from '../services/inventoryManagementService'
import { FustAmount } from '../types/fust'

export type InventoryManagementMachineContext = {
  selectedDate: Date
  selectedDepot?: Depot
  itemStateFromSelectedDepot?: string
  fustAmount: FustAmount[]
  validatedFustAmount: FustAmount[]
  error?: string
  isBulk: boolean
}
export const inventoryManagementMachineModel = createModel(
  {
    selectedDate: new Date(),
    fustAmount: [],
    validatedFustAmount: [],
    selectedDepot: undefined,
    itemStateFromSelectedDepot: '',
    error: undefined,
    isBulk: false,
  } as InventoryManagementMachineContext,
  {
    events: {
      ASSIGN_SELECTED_DATE: (selectedDate: Date) => ({ selectedDate }),
      ASSIGN_SELECTED_DEPOT: (selectedDepot: Depot) => ({ selectedDepot }),
      ASSIGN_FUST_AMOUNT: (fustAmount: FustAmount[]) => ({ fustAmount }),
      CONTINUE: () => ({}),
      START_NEW_COUNT: () => ({}),
      START_NEW_BULK_COUNT: () => ({}),
      START_PREVIOUSLY_COUNT_FUST_AMOUNT: () => ({}),
      CLOSE_DIALOG: () => ({}),
      REVIEW_INVENTORY_FUST_AMOUNT: () => ({}),
      EDIT_INVENTORY_FUST_AMOUNT: () => ({}),
      SUBMIT_INVENTORY_FUST_AMOUNT: () => ({}),
      RESET_TO_INITIAL_STATE: () => ({}),
      LOAD_PREVIOUSLY_COUNT_FUST_AMOUNT: () => ({}),
      RESET_ERROR: () => ({}),
    },
  }
)

export const InventoryContextStorageKey = 'mfb-inventory-context'

export const inventoryManagementMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEsB2A3MqAuB7ATgJ4CyAhqqTALZbZkDGAFmmAHSmwDWaUA+gA74wvNADMCVUtmS5UAYgCCAZSUBJAOIA5XkoCiAGV0BhACq6AIr3MKziUP1yxk02XZAAPRABYAHKwCMAJxeAGwA7GFeAMyBAKwATP4hADQghIj+YSGscbE+CbFesZEhUQC+ZalomDgEJOSUYDQ4DMyobBzcqHyCwmISUjLyympaOgbGZpbmugAKAPImbg5OLqhunghe8anpCD6BOT4+UT4hAAz+-j7+hYEVVRi0dWQU1LStLOxcPAJCIqhxPhJGs5EZ5poTKpNABVXTLRzOIYbRDxHzxHJXLxhc7nEKhHxhfxeXaomKsaIHELHKLnWLFKJhB4garPIivRrNOikJhfAA2uFIEF+rNqRF4ECkpDkEFkbGquE48qeYvqbyaHx5bTYAqFIpVeHFkuwpAQCvog1kAG1zgBdBGrZFIDyIHyhVixKL+eLbBmxTIktKowLnHKJfy0k7bMK5Zmiw1qzma3ntVi64XdAE1BMSqVyMD4fAEVj8PlSIFUVjxl4Nd4tLX8wUZvjVo1Ss0YXAWtY2+3OlZI1zOzZu7Ke72+sJRf2RUkITJ+MI3aLnQIRyLxcJxg019Vcz6p9DIMAAd312bqvFw-ALltQV-4a1gciUJgUACUTLxNLoAOq8cEYUhB1B3WYcMmifwKSiKIQn8S5tnOMJ4h2IMEC9DFAiw-FrjXWI4PKSoWR3dlaw1esUzYI9T3PNlCAfW81gfJ8XzfT9eAAIRhfQAGkAPmICln7RE1hRedIOg2D4OJeIkJQudN1iVhLhCVSEK9GD-G3C9SL3ZNtVYaiz0zVt6OvRihmYoZn1fD8v1md9dAANVUASlH0ABNXhdAADVUV9oXUfjBJA0TwPnVc-C9LxsLwwoQliBSvCgzccQjbFAiJeJAnibS6I5OtuUo1hYEYXBjL4UgACMCGwSArNkCVkFIAUoDBfR5j0KxVAUDr1FCp1QE2YlqQCIJIkCEJFOxVC9kSMJlJg8ITmCHFfDy1UCvIoqDNK8rfmq2r6uvJjhRa3A2vBSFoThAahyGjIQyU3FvXCVdznRQIFPCD0kjU4kNK9CoiNQXAIDgNxTK2-cG1TTpfl6AEKzvO6wIehB8NYUpChQpCly9elvr8HLNJDGNwhxJkiKhsiYeKxhyAgPlfgLIt8FRsSrjxZSIzgpIvA+zJErQzIolYeIlxOXFJtkpCNoTaH9MbPUTJI+jjVIDnwvpJT8IibKY0mhKUjQn0xcgwkcsiAWYPl3ckwogyjNo1UGPwO8GtQeBhMde6XXneDskuKT+cFsJhbm4JxZirCEjRYkJzt3SHZ2r49oq3hDvwOqIE9przqgLX0aSGCcnHEb4liHKfG+jEELpQIokrqvA6TxNCoPNhICRTN6FwABXHAXYTIv-dk05MQFyuriyUpAzmqaeYFqum-pNc8TbxXHZYUfhprkWfGBsogA */
  inventoryManagementMachineModel.createMachine(
    {
      context: inventoryManagementMachineModel.initialContext,
      tsTypes: {} as import('./inventoryManagementMachine.typegen').Typegen0,
      schema: {
        services: {} as {
          validateInventoryFustAmount: {
            data: AxiosResponse<FustAmount[]>
          }
          submitInventoryFustAmount: {
            data: AxiosResponse<void>
          }
          loadPreviouslyCountFustAmount: {
            data: AxiosResponse<FustAmount[]>
          }
        },
      },
      predictableActionArguments: true,
      id: 'inventoryManagementMachine',
      initial: 'checking_aborted_count',
      states: {
        checking_aborted_count: {
          always: [
            { target: 'showing_aborted_count_dialog', cond: 'isAbortedCount' },
            { target: 'asking_pre_information' },
          ],
        },
        asking_pre_information: {
          on: {
            ASSIGN_SELECTED_DATE: {
              actions: 'assignSelectedDate',
            },
            ASSIGN_SELECTED_DEPOT: {
              actions: 'assignSelectedDepot',
            },
            CONTINUE: {
              cond: 'isPreInformationValid',
              target: 'viewing_inventory_operation_options',
            },
          },
        },
        viewing_inventory_operation_options: {
          on: {
            START_NEW_COUNT: {
              target: 'editing_counting_inventory',
            },
            START_NEW_BULK_COUNT: {
              actions: 'assignIsBulk',
              target: 'editing_counting_inventory',
            },
            START_PREVIOUSLY_COUNT_FUST_AMOUNT: {
              actions: 'assignIsBulk',
              target: 'checking_aborted_count_before_starting_previously_count',
            },
          },
        },
        //@todo may the user allowed to continue where he left off?
        // the data can be very out-dated which can be incorrect if we depend on
        // `/voorraad/laatste/${context?.selectedDepot?.code}` before the flow
        checking_aborted_count_before_starting_previously_count: {
          always: [
            { target: 'showing_aborted_count_dialog', cond: 'isAbortedCount' },
            { target: 'loading_previously_count_fust_amount' },
          ],
        },
        loading_previously_count_fust_amount: {
          tags: ['loading'],
          invoke: {
            src: 'loadPreviouslyCountFustAmount',
            onDone: {
              actions: assign({
                fustAmount: (_, event) => event.data.data,
              }),
              target: 'editing_counting_inventory',
            },
            onError: {
              target: 'viewing_inventory_operation_options',
              actions: 'assignError',
            },
          },
        },
        showing_aborted_count_dialog: {
          on: {
            CLOSE_DIALOG: {
              actions: 'removeContextFromLocalStorage',
              target: 'asking_pre_information',
            },
            CONTINUE: {
              actions: 'assignContextFromLocalStorage',
              target: 'editing_counting_inventory',
            },
          },
        },
        editing_counting_inventory: {
          on: {
            REVIEW_INVENTORY_FUST_AMOUNT: {
              cond: 'isInventoryFustAmountFilled',
              target: 'validating_inventory_fust_amount',
            },
            ASSIGN_FUST_AMOUNT: {
              actions: [
                'assignInventoryFustAmount',
                'addCurrentInventoryContextToLocalStorage',
              ],
            },
          },
        },
        validating_inventory_fust_amount: {
          tags: ['loading'],
          invoke: {
            src: 'validateInventoryFustAmount',
            onDone: {
              actions: assign((context, event) => {
                return {
                  validatedFustAmount: event.data.data,
                }
              }),
              target: 'reviewing_inventory_fust_amount',
            },
            onError: {
              target: 'editing_counting_inventory',
              actions: 'assignError',
            },
          },
        },
        reviewing_inventory_fust_amount: {
          description: 'expecting fustAmount[] data to have correct: boolean or not',
          on: {
            EDIT_INVENTORY_FUST_AMOUNT: {
              target: 'editing_counting_inventory',
            },
            SUBMIT_INVENTORY_FUST_AMOUNT: {
              target: 'submitting_inventory_fust_amount',
            },
          },
        },
        submitting_inventory_fust_amount: {
          tags: ['loading'],
          invoke: {
            src: 'submitInventoryFustAmount',
            onDone: 'showing_success_dialog',
            onError: {
              target: 'reviewing_inventory_fust_amount',
              actions: 'assignError',
            },
          },
        },
        showing_success_dialog: {
          on: {
            RESET_TO_INITIAL_STATE: {
              actions: 'assignInitialContext',
              target: '#inventoryManagementMachine',
            },
          },
        },
      },
      on: {
        RESET_ERROR: {
          actions: 'resetError',
        },
      },
    },
    {
      guards: {
        isInventoryFustAmountFilled: context => {
          return context.fustAmount.length > 0
        },
        isAbortedCount: () => {
          const contextFromLocalStorage: InventoryManagementMachineContext = JSON.parse(
            localStorage.getItem(InventoryContextStorageKey) as string
          )
          return (
            contextFromLocalStorage && contextFromLocalStorage.fustAmount.length > 0
          )
        },
        isPreInformationValid: context => {
          return (
            context.selectedDate !== undefined && context.selectedDepot !== undefined
          )
        },
      },
      actions: {
        assignIsBulk: assign(_ => ({
          isBulk: true,
        })),
        assignError: assign((_context, event) => {
          return {
            error: String((event.data as AxiosError).response?.data),
          }
        }),
        resetError: assign(_ => ({ error: undefined })),
        removeContextFromLocalStorage: () => {
          localStorage.removeItem(InventoryContextStorageKey)
        },
        assignContextFromLocalStorage: inventoryManagementMachineModel.assign(() => {
          const contextFromLocalStorage: InventoryManagementMachineContext = JSON.parse(
            localStorage.getItem(InventoryContextStorageKey) as string
          )

          // date from LS is a string and needs to be parsed to a Date object
          contextFromLocalStorage.selectedDate = parseIso(
            contextFromLocalStorage.selectedDate as unknown as string
          )

          // Set bulk when aborted count has slepen
          contextFromLocalStorage.isBulk = !!contextFromLocalStorage.fustAmount.find(
            fust => fust.slepen > 0
          )

          return {
            ...contextFromLocalStorage,
          }
        }),
        addCurrentInventoryContextToLocalStorage: context => {
          localStorage.setItem(InventoryContextStorageKey, JSON.stringify(context))
        },
        assignInitialContext: inventoryManagementMachineModel.assign(context => {
          localStorage.removeItem(InventoryContextStorageKey)
          return {
            ...inventoryManagementMachineModel.initialContext,
          }
        }),
        assignInventoryFustAmount: inventoryManagementMachineModel.assign(
          (_, event) => {
            return {
              fustAmount: event.fustAmount,
            }
          }
        ),
        assignSelectedDate: inventoryManagementMachineModel.assign(
          (_context, event) => {
            return {
              selectedDate: event.selectedDate,
            }
          }
        ),
        assignSelectedDepot: inventoryManagementMachineModel.assign(
          (_context, event) => {
            return {
              selectedDepot: event.selectedDepot,
              itemStateFromSelectedDepot: event.selectedDepot.defaultItemState,
            }
          }
        ),
      },
      services: {
        validateInventoryFustAmount: async context => {
          return validateInventoryFustAmount(
            String(context?.selectedDepot?.code),
            context.fustAmount
          )
        },
        submitInventoryFustAmount: async context => {
          return submitInventoryFustAmount(
            String(context?.selectedDepot?.code),
            context.selectedDate,
            context.fustAmount
          )
        },
        loadPreviouslyCountFustAmount: async context => {
          return loadPreviouslyCountFustAmount(String(context?.selectedDepot?.code))
        },
      },
    }
  )
