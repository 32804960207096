import { FC, useEffect, useState } from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { CloseIcon } from '@rfh/ui/shared/floriday-icons'

import { FlowDialogContent } from 'src/components/FlowDialog/FlowDialogContent'
import { FlowDialogTitle } from 'src/components/FlowDialog/FlowDialogTitle'
import { SlideUpTransition } from 'src/components/FlowDialog/SlideUpTransition'

export type FlowDialogProps = DialogProps & {
  children?: JSX.Element[] | JSX.Element
  handleClose?: () => void
}

export const FlowDialog: FC<FlowDialogProps> = ({
  children,
  open,
  handleClose,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const flowDialogTitle =
    Array.isArray(children) && children.find(item => item.type === FlowDialogTitle)
  const flowDialogContent =
    Array.isArray(children) && children.find(item => item.type === FlowDialogContent)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Dialog open={isOpen} TransitionComponent={SlideUpTransition} {...rest}>
      <AppBar sx={{ position: 'relative', bgcolor: 'white', boxShadow: 'none' }}>
        <Toolbar>
          <Box flexGrow={1}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='close dialog'
              onClick={() => {
                handleClose && handleClose()
              }}
            >
              <CloseIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Box>
          {flowDialogTitle}
        </Toolbar>
      </AppBar>
      {flowDialogContent}
      {!Array.isArray(children) && children}
    </Dialog>
  )
}
