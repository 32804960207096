import { FC } from 'react'

import { useActor, useSelector } from '@xstate/react'

import { DockTransactionsDefaultViewProps } from 'src/components/IssuanceFlow/dockTransactions/DockTransactionsFlowStart'
import { EditableSelectedTransactionsView } from 'src/components/IssuanceFlow/views/EditableSelectedTransactionsView'
import { issuanceDockTransactionsMachineModel } from 'src/machines/issuanceDockTransactionsMachine'
import { issuanceTransactionsDataGridMachineModel } from 'src/machines/issuanceTransactionsDataGridMachine'

export type EditTransactionEditTransactionProps = DockTransactionsDefaultViewProps
const { ASSIGN_SELECTED_TRANSACTIONS, ASSIGN_FUST_AMOUNT } =
  issuanceTransactionsDataGridMachineModel.events

const { REVIEW_TRANSACTIONS } = issuanceDockTransactionsMachineModel.events

export const EditTransaction: FC<EditTransactionEditTransactionProps> = ({
  service,
  children,
}) => {
  const [state, send] = useActor(service)
  const dataGridService = useSelector(
    service,
    currentState => currentState.context.transactionsDataGridService
  )

  const selectedTransactions = useSelector(
    dataGridService,
    currentState => currentState.context.selectedTransactions
  )
  const fustAmount = useSelector(
    dataGridService,
    currentState => currentState.context.fustAmount
  )

  const dataGridSend = dataGridService.send

  return (
    <EditableSelectedTransactionsView
      loading={state.hasTag('loading')}
      selectedTransactions={selectedTransactions}
      fustAmount={fustAmount}
      updateDataGridHandler={(newFustAmount, newSelectedTransactions) => {
        dataGridSend(ASSIGN_FUST_AMOUNT(newFustAmount))
        dataGridSend(ASSIGN_SELECTED_TRANSACTIONS(newSelectedTransactions))
      }}
      confirmClickHandler={() => {
        send(REVIEW_TRANSACTIONS())
      }}
    >
      {children}
    </EditableSelectedTransactionsView>
  )
}
