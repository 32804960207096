import { AxiosResponse } from 'axios'

import { Card } from 'src/types/card'
import { api } from 'src/utils/api'

export const queryCard = (cardNumber: string): Promise<AxiosResponse<Card>> => {
  const endpoint = `/fustpas/${cardNumber}`

  return api.get(endpoint)
}
