import format from 'date-fns/format'

import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor, useSelector } from '@xstate/react'

import { CountTable, CountTableProps } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import { inventoryManagementMachineModel } from 'src/machines/inventoryManagementMachine'
import { FustAmountKeys } from 'src/types/fust'
import { InventoryManagementFlowContext } from 'src/views/InventoryManagementFlow'

const { REVIEW_INVENTORY_FUST_AMOUNT, ASSIGN_FUST_AMOUNT } =
  inventoryManagementMachineModel.events

export const EditableInventoryCount: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(InventoryManagementFlowContext)
  const [state, send] = useActor(service)
  const [canContinue, setCanContinue] = useState<boolean>(true)

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [state.hasTag('loading')])

  const handleRowAdd: CountTableProps['handleRowAdd'] = row => {
    send(ASSIGN_FUST_AMOUNT([...state.context.fustAmount, row]))
  }
  const handleRowRemove: CountTableProps['handleRowRemove'] = rowIndex => {
    send(ASSIGN_FUST_AMOUNT(state.context.fustAmount.filter((_, i) => i !== rowIndex)))
  }
  const handleRowEdit: CountTableProps['handleRowEdit'] = (row, rowIndex) => {
    send(
      ASSIGN_FUST_AMOUNT(
        state.context.fustAmount.map((fustAantal, i) =>
          i === rowIndex ? row : fustAantal
        )
      )
    )
  }

  const isBulk = useSelector(service, currentState => currentState.context.isBulk)

  const columns: FustAmountKeys[] = isBulk
    ? ['fustCode', 'itemState', 'slepen', 'stapelwagens', 'stuks']
    : ['fustCode', 'itemState', 'stapelwagens', 'stapels', 'stuks']

  return (
    <FlowView p={0} loading={state.hasTag('loading')}>
      <FlowViewContent>
        <TransactionsHeader
          items={[
            {
              label: 'date',
              value: format(new Date(state.context.selectedDate), 'dd-MM-yyyy'),
            },
            {
              label: 'depot',
              value: String(state?.context?.selectedDepot?.naam),
            },
          ]}
        />

        <Box px={2} py={2}>
          <RfhTypography variant={'subtitle1'}>{t('count')}</RfhTypography>
        </Box>
        <RfhTypography variant={'body1'}></RfhTypography>
        <CountTable
          handleRowAdd={handleRowAdd}
          handleRowRemove={handleRowRemove}
          handleRowEdit={handleRowEdit}
          setCanContinue={setCanContinue}
          columns={columns}
          rows={state.context.fustAmount}
          maxStwLength={4}
          itemStateDefault={state.context.itemStateFromSelectedDepot}
        />
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          fullWidth
          disabled={!(state.can('REVIEW_INVENTORY_FUST_AMOUNT') && canContinue)}
          onClick={() => {
            send(REVIEW_INVENTORY_FUST_AMOUNT())
          }}
        >
          {t('button.continue')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
