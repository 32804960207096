import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import DataGrid from '@rfh/ui/components/DataGrid/DataGrid'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { CheckOIcon, EditIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'
import { useActor, useSelector } from '@xstate/react'

import { DataGridWrapper } from 'src/components/DataGridWrapper'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { RoundIconButton } from 'src/components/RoundIconButton'
import { ConfirmFustPassNumberDialog } from 'src/components/dialogs/ConfirmFustPassNumberDialog'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  issuanceStoreTransactionsMachineModel,
  issuanceStoreTransactionsService,
} from 'src/machines/issuanceStoreTransactionsMachine'
import { getGroupByFustCodeTransactionsRows } from 'src/utils'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export type ReviewSelectedTransactionsProps = {
  service: typeof issuanceStoreTransactionsService
}

const {
  HANDLE_TRANSACTIONS,
  CLOSE_FUST_PASS_NUMBER_DIALOG,
  CONTINUE,
  EDIT_TRANSACTIONS,
} = issuanceStoreTransactionsMachineModel.events

export const ReviewSelectedTransactions: FC<ReviewSelectedTransactionsProps> = ({
  service,
  children,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)
  const [mainState, _send] = useActor(mainFlowService)

  const navigate = useNavigate()

  const handleCloseSuccessDialog = () => {
    /*restart all the machines and reset it's state to initialContext */
    mainState.context.scannedFustPassContext = undefined
    navigate('/uitgifte/start')
  }

  const fustPassNumber = useSelector(
    service,
    currentState =>
      currentState.context.scannedFustPassContext?.scannedFustPass?.fustpasNummer
  )

  return (
    <>
      <ConfirmFustPassNumberDialog
        isOpen={state.matches('confirming_with_fust_pass')}
        handleClose={() => {
          send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }}
        handleSubmit={() => {
          send(CONTINUE())
        }}
        fustPassNumberToConfirm={fustPassNumber!}
      />

      <SuccessDialog
        isOpen={Boolean(state.done)}
        handleClose={handleCloseSuccessDialog}
      />
      <FlowView loading={state.hasTag('loading')} p={0}>
        <FlowViewContent px={0}>
          {children}

          <Stack direction={'row'} px={2} alignItems={'center'}>
            <RfhTypography variant={'subtitle1'} flexGrow={1} py={2}>
              {t('issuance.validateOrder')}
            </RfhTypography>
            <Box
              sx={{
                visibility: state.can('EDIT_TRANSACTIONS') ? 'inherit' : 'hidden',
              }}
            >
              <RoundIconButton
                variant='outlined'
                icon={<EditIcon />}
                onClick={() => send(EDIT_TRANSACTIONS())}
                label={t('countTable.editRow')}
              />
            </Box>
            {state.done && (
              <SvgIcon color={'success'}>
                <CheckOIcon />
              </SvgIcon>
            )}
          </Stack>
          <DataGridWrapper>
            <DataGrid
              headColor={RfhColors.leafGreen}
              rows={[
                {
                  header: true,
                  values: [
                    t('countTable.headings.code'),
                    t('countTable.headings.stw'),
                    t('countTable.headings.stpl'),
                    t('countTable.headings.stk'),
                    t('countTable.headings.subTotal'),
                  ],
                },
                ...getGroupByFustCodeTransactionsRows(state.context.fustAmount),
              ]}
              label={'Review Selected Store Transactions'}
            />
          </DataGridWrapper>
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            sx={{ display: state.done ? 'none' : 'inherit' }}
            variant={'contained'}
            onClick={() => {
              send(HANDLE_TRANSACTIONS())
            }}
          >
            {t('button.handle')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
