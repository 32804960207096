import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useActor, useSelector } from '@xstate/react'

import { ReviewMultipleTransactionView } from 'src/components/IssuanceFlow/views/ReviewMultipleTransactionsView'
import {
  issuanceDockTransactionsMachineModel,
  issuanceDockTransactionsService,
} from 'src/machines/issuanceDockTransactionsMachine'

export type ReviewMultipleTransactionProps = {
  service: typeof issuanceDockTransactionsService
}

const { HANDLE_TRANSACTIONS } = issuanceDockTransactionsMachineModel.events

export const ReviewMultipleTransactions: FC<ReviewMultipleTransactionProps> = ({
  service,
  children,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const selectedTransactions = useSelector(
    service,
    currentState =>
      currentState.context.transactionsDataGridService.state.context
        .selectedTransactions
  )

  return (
    <ReviewMultipleTransactionView
      isEditable={false}
      isDividable={false}
      hasTotals={true}
      loading={state.hasTag('loading')}
      selectedTransactions={selectedTransactions}
      title={t('issuance.validateFullCount')}
      submissionClickHandler={() => {
        send(HANDLE_TRANSACTIONS())
      }}
    >
      {children}
    </ReviewMultipleTransactionView>
  )
}
