import { assign } from 'xstate'
import { createModel } from 'xstate/lib/model'

import { Authority } from 'src/types/authority'
import { Depot } from 'src/types/depot'
import { TransactionType } from 'src/types/transaction'
import { WorkArea } from 'src/types/workArea'

import {
  intakeFixedTransactionsMachine,
  intakeFixedTransactionsMachineModel,
} from './intakeFixedTransactionsMachine'
import {
  intakeMobileTransactionsMachine,
  intakeMobileTransactionsMachineModel,
} from './intakeMobileTransactionsMachine'
import { ScanFustPassFlowMachineContext } from './scanFustPassFlowMachine'

export type IntakeFlowContext = {
  selectedTransactionType?: TransactionType.InnameMobiel | TransactionType.InnameVast
  selectedDepot?: Depot
  selectedWorkArea?: WorkArea
  selectedAuthority?: Authority
  scannedFustPassContext?: ScanFustPassFlowMachineContext
}

export const intakeFlowMachineModel = createModel({} as IntakeFlowContext, {
  events: {
    CONTINUE: () => ({}),
    ASSIGN_SELECTED_TRANSACTION_TYPE: (
      transactionType: IntakeFlowContext['selectedTransactionType']
    ) => ({ transactionType }),
    ASSIGN_SELECTED_DEPOT: (depot: Depot) => ({
      depot,
    }),
    ASSIGN_SELECTED_WORKAREA: (workArea: WorkArea) => ({
      workArea,
    }),
    ASSIGN_SELECTED_AUTHORITY: (authority: Authority) => ({
      authority,
    }),
    ASSIGN_SCANNED_FUST_PASS_CONTEXT: (
      scanContext: ScanFustPassFlowMachineContext
    ) => ({ scanContext }),
    CLOSE_FUST_PASS_SCAN_DIALOGS: () => ({}),
  },
})

export const intakeFlowMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEtawK4EMB2BjMAYgDYD2A7gHSawDWy2UA+gA4BOYj9AZiawLaYALshLYqtekx6tGg1jliZcw0bICezMAGJEoZiVjIV2XSAAeiAIwBmAOwBWCgCYADNYCc9j+9sAOS+6+ADQgaohOfhTu0b5eTj4uACyWvgC+qSGoGDj4xOTidAws7JzY0gLGBZKM0owQYPqCWgCCAMqtAJIA4gByjK0AogAyAwDCACoDACKMUwMACgDy46b6hsamFgiWAGzuiRT+TonuLrG21nb2IWEITvaWFJdOvrYXLi6WX75O6ZloWDwhFIlGohSYbA43F4FREYjB1Vq9UaWlGix64w6PQAqgNVgYjHDNohko8Pi57g8nE5rPFrDcSb5fFEYvYdvYHH4fPY-iAsoDciCqkVIaVykI4S12t0+oMRhNpoxxgAlZo9VrNCYddFKgCa8zxSBAa0JomJCC5FDcuycO2svmsiTtOwZCFpLgoJ0CXzcLh2uydvP5OWB+QRIpK0P4EtEOiNJo2Rq29icrssLnsHuigTtNlpvh2QYBIbylAAFjgIEREehYIIWNRYIxYLgcFLOr1+qM1T1FQRsa1xox5m1Wow0RiBgANFbxgmJ0BbUlWj6UyzU2nuemhRC+dws71vNy2Fz7NIZPnFoGligV7BVmt1htoZut7CooaLQaMfuD4ejrs1VmDpmk-LpWnxdYiSTEkvhXCl7CpGk6TTWwdgPU5EMsLwzhpItsmvIUwAAN0wIgsGEIo5AUJRjCbQQNG0SDTRMGDtnuWwKEzT4PESDNohpV0dgpQ5EgddcnTZTxbHSC9sBIep4CNYNCPyZAqzAZiF3MElUx3BBEniA8mV8FwTx2V5LHwgVQ1BCQIyhMoYRjMQy1QQQtOgxdEFsfYrWsG07QdJ1rBdfT7kebjPjEvZ3FzGSLxUwUw3siFIyc6NKnDKReFkeRsEUZQ4XUTRPLNNivlpJ5OQzU891sRJrn0y4Di9fZhN8Rr-SsxKr2SuzwWKRzxSy1Kaly5ESA8ucoPK7ztneT1GopfxEga6lXUdawnk3dkvksWx00LXqCP64U0uG5ztITLydItdl-MC+1HWdNMdk455Ot8vMTgS-5Tts29K2rIouFretmEbV8cDK1j5sM11qUcbjXHif0CxsaySyI0jyIlKj8sKuiSs0maWPNBx92tHZbWekKwtudNtuzTwIg5dlDOsLHVPLYHqiSjg614DhqIK2i4SUvR51urY0McN5ElCxqHSpSxXXcIykhCp0KVZrmTpsm87wfIoBbqZB2GUPKaKK0RJeNaW5ruhH9N+g8Tw5BqPB2Y7-sNoVjZBpgzYgEhcBoa2xdtgrYfNF3Gb9LiPjtJwUlCn1fcvAHS1jirgn0g73aZAK9jW0LZNSIA */
  intakeFlowMachineModel.createMachine(
    {
      context: intakeFlowMachineModel.initialContext,
      tsTypes: {} as import('./intakeFlowMachine.typegen').Typegen0,
      predictableActionArguments: true,
      id: 'intakeFlow',
      initial: 'handle_transaction_type',
      states: {
        handle_transaction_type: {
          always: [
            {
              cond: 'isIntakeFixedSelected',
              target: 'asking_for_depot',
            },
            {
              cond: 'isIntakeMobileSelected',
              target: 'asking_for_work_area',
            },
          ],
        },
        asking_for_depot: {
          on: {
            ASSIGN_SELECTED_DEPOT: {
              actions: 'assignSelectedDepot',
              target: 'handling_fust_pass_scan',
            },
          },
        },
        asking_for_work_area: {
          on: {
            ASSIGN_SELECTED_WORKAREA: {
              actions: 'assignSelectedWorkArea',
            },
          },
        },
        handling_fust_pass_scan: {
          description: 'using <ScanFustPassFlowDialog /> to validate the number',
          on: {
            ASSIGN_SCANNED_FUST_PASS_CONTEXT: {
              actions: 'assignScannedFustPassContext',
              target: 'evaluating_authorities',
            },
            CLOSE_FUST_PASS_SCAN_DIALOGS: [
              {
                target: 'handle_transaction_type',
              },
            ],
          },
        },
        evaluating_authorities: {
          always: [
            {
              cond: 'hasMultipleAuthorities',
              target: 'handling_authorities',
            },
            {
              cond: 'hasOneAuthority',
              actions: assign(context => ({
                selectedAuthority: context.scannedFustPassContext!.authorityList![0],
              })),
              target: 'handling_intake_fixed_transactions',
            },
            {
              cond: 'hasAdditionalCustomerNumber',
              target: 'handling_intake_fixed_transactions',
            },
          ],
        },
        handling_authorities: {
          on: {
            ASSIGN_SELECTED_AUTHORITY: {
              actions: 'assignSelectedAuthority',
            },
          },
        },
        handling_intake_fixed_transactions: {
          invoke: {
            src: intakeFixedTransactionsMachine,
            id: 'intakeFixedTransactionsMachine',
            data: {
              ...intakeFixedTransactionsMachineModel.initialContext,
              scannedFustPassContext: (context: IntakeFlowContext) =>
                context.scannedFustPassContext,
              selectedDepot: (context: IntakeFlowContext) => context.selectedDepot,
              selectedAuthority: (context: IntakeFlowContext) =>
                context.selectedAuthority,
            },
            onDone: {
              target: 'handling_fust_pass_scan',
              actions: 'assignInitialFixedContext',
            },
          },
        },
        handling_intake_mobile_transactions: {
          invoke: {
            src: intakeMobileTransactionsMachine,
            id: 'intakeMobileTransactionsMachine',
            data: {
              ...intakeMobileTransactionsMachineModel.initialContext,
              selectedWorkArea: (context: IntakeFlowContext) =>
                context.selectedWorkArea,
            },
          },
        },
      },
      on: {
        ASSIGN_SELECTED_TRANSACTION_TYPE: {
          actions: 'assignSelectedTransactionType',
          target: 'handle_transaction_type',
        },
        CONTINUE: [
          {
            cond: 'isIntakeMobileRequiredInfoSelected',
            target: 'handling_intake_mobile_transactions',
          },
          {
            cond: 'isIntakeFixedRequiredInfoSelected',
            target: 'handling_intake_fixed_transactions',
          },
        ],
      },
    },
    {
      guards: {
        hasAdditionalCustomerNumber: context =>
          !!context.scannedFustPassContext?.additionalCustomerNumber,
        isIntakeFixedRequiredInfoSelected: context =>
          context?.selectedTransactionType === TransactionType.InnameVast &&
          !!context.selectedDepot &&
          !!context.scannedFustPassContext &&
          (!!context.selectedAuthority ||
            !!context.scannedFustPassContext.additionalCustomerNumber),
        isIntakeMobileRequiredInfoSelected: context => {
          return (
            context?.selectedTransactionType === TransactionType.InnameMobiel &&
            !!context.selectedWorkArea
          )
        },
        isIntakeFixedSelected: context => {
          return context?.selectedTransactionType === TransactionType.InnameVast
        },
        isIntakeMobileSelected: context => {
          return context?.selectedTransactionType === TransactionType.InnameMobiel
        },
        hasMultipleAuthorities: context => {
          return (
            !!context.scannedFustPassContext?.authorityList?.length &&
            context.scannedFustPassContext?.authorityList?.length > 1
          )
        },
        hasOneAuthority: context => {
          return context.scannedFustPassContext?.authorityList?.length === 1
        },
      },
      actions: {
        assignSelectedDepot: assign((_context, event) => {
          return {
            selectedDepot: event.depot,
          }
        }),
        assignSelectedWorkArea: assign((_context, event) => {
          return {
            selectedWorkArea: event.workArea,
          }
        }),
        assignSelectedTransactionType: assign((_context, event) => {
          return {
            selectedTransactionType: event.transactionType,
          }
        }),
        assignScannedFustPassContext: assign((_context, event) => {
          return {
            scannedFustPassContext: event.scanContext,
          }
        }),
        assignSelectedAuthority: assign((_context, event) => {
          return {
            selectedAuthority: event.authority,
          }
        }),
        assignInitialFixedContext: assign(_context => {
          return {
            selectedAuthority: undefined,
            scannedFustPassContext: undefined,
          }
        }),
      },
    }
  )
