import { AxiosPromise } from 'axios'

import { Depot } from 'src/types/depot'
import { FustAmount } from 'src/types/fust'
import { api } from 'src/utils/api'

export const validateRebookItemState = (
  depot: Depot,
  rebookAmount: FustAmount[]
): AxiosPromise<FustAmount[]> =>
  api.post(`/omboek/${depot.code}/controleren`, rebookAmount)

export const submitRebookItemState = (
  depot: Depot,
  rebookAmount: FustAmount[]
): AxiosPromise<void> => api.post(`/omboek/${depot.code}/verwerken`, rebookAmount)
