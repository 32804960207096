import { FC, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import { useActor, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { DepartureLocation } from 'src/components/IssuanceFlow/directTransactions/DepartureLocation'
import { DivideTransaction } from 'src/components/IssuanceFlow/directTransactions/DivideTransaction'
import { EditTransaction } from 'src/components/IssuanceFlow/directTransactions/EditTransaction'
import { ListOfDirectTransactions } from 'src/components/IssuanceFlow/directTransactions/ListOfDirectTransactions'
import { ReviewMultipleTransaction } from 'src/components/IssuanceFlow/directTransactions/ReviewMultipleTransactions'
import { ReviewSingleTransaction } from 'src/components/IssuanceFlow/directTransactions/ReviewSingleTransaction'
import { ReviewTransactionSingleCustomer } from 'src/components/IssuanceFlow/directTransactions/ReviewTransactionSingleCustomer'
import { Layout } from 'src/components/Layouts'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'
import { UserType } from 'src/types/customer'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

const { RESET_ERROR } = issuanceDirectTransactionsMachineModel.events

export const DirectTransactionsFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)

  const childTransactionsService = useMemo(() => {
    return mainFlowService.state
      ? (mainFlowService.state.children
          .issuanceDirectTransactionsFlow as typeof issuanceDirectTransactionsService)
      : issuanceDirectTransactionsService
  }, [mainFlowService])

  const [state, send] = useActor(childTransactionsService)

  const depotName = useSelector(
    childTransactionsService,
    currentState =>
      `${currentState?.context?.selectedDepartureLocation?.naam} - ${currentState?.context.selectedDepartureLocation?.vestiging}`
  )

  const customerName = useSelector(childTransactionsService, currentState =>
    currentState?.context?.selectedTransaction
      ? `${currentState?.context?.selectedTransaction?.klantnummer} - ${currentState?.context?.selectedTransaction?.klantNaam}`
      : `${currentState?.context?.selectedTransactions[0]?.klantnummer} - ${currentState?.context?.selectedTransactions[0]?.klantNaam}`
  )

  const singleCustomerTransactions = useSelector(
    childTransactionsService,
    currentState =>
      currentState?.context?.selectedTransactions.filter(transaction => {
        if (
          currentState?.context.scannedFustPassContext?.scannedFustPass?.klant.type ===
          UserType.Transporter
        ) {
          return (
            transaction.vervoerderNummer ===
            currentState?.context.scannedFustPassContext?.scannedFustPass?.klant.nummer
          )
        } else {
          return (
            transaction.klantnummer ===
            currentState?.context.scannedFustPassContext?.scannedFustPass?.klant.nummer
          )
        }
      })
  )

  const navigate = useNavigate()

  useEffect(() => {
    if (state.matches('completed_transactions')) {
      childTransactionsService.start()
      mainFlowService.stop()
      mainFlowService.start()
      navigate('/uitgifte/start')
    }
  })

  const viewingListOfTransactionsParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return (
        matches('viewing_list_of_transactions') ||
        matches('loading_transactions') ||
        matches('viewing_transaction_details') ||
        matches('updating_transaction_status')
      )
    }
  )

  const viewingDivideTransactionView = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('dividing_transaction') ||
      matches('divide_transaction') ||
      matches('late_divide_transaction')
  )

  const viewingEditTransactionView = useSelector(
    childTransactionsService,
    ({ matches }) => matches('editing_transaction') || matches('edit_transaction')
  )

  const selectDepartureLocationParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return matches('select_departure_location') || matches('handling_fust_pass_scan')
    }
  )

  const checkTransactionsParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return (
        matches('reviewing_transactions_single_customer') ||
        matches('handling_transactions_submission') ||
        matches('handling_late_divided_transactions_submission') ||
        matches('confirming_with_fust_pass') ||
        matches('showing_success_dialog')
      )
    }
  )
  const error = useSelector(
    childTransactionsService,
    currentState => currentState.context.error
  )

  return (
    <Layout title={t('issuance.direct')}>
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />
      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        <>
          {viewingListOfTransactionsParentState && (
            <ListOfDirectTransactions service={childTransactionsService} />
          )}
          {state.matches('reviewing_transactions_single') && (
            <ReviewSingleTransaction
              service={childTransactionsService}
              customerName={customerName}
            />
          )}
          {state.matches('reviewing_transactions_multiple') && (
            <ReviewMultipleTransaction
              service={childTransactionsService}
              numberOfCustomers={state.context.numberOfCustomers}
            />
          )}
          {selectDepartureLocationParentState && (
            <DepartureLocation service={childTransactionsService} />
          )}
          {viewingDivideTransactionView && (
            <DivideTransaction
              service={childTransactionsService}
              customerName={customerName}
            />
          )}
          {viewingEditTransactionView && (
            <EditTransaction
              service={childTransactionsService}
              customerName={customerName}
            />
          )}
          {checkTransactionsParentState && (
            <ReviewTransactionSingleCustomer
              service={childTransactionsService}
              customerName={customerName}
              depotName={depotName}
              transactions={singleCustomerTransactions}
            />
          )}
        </>
      </Box>
    </Layout>
  )
}
