export type Customer = {
  naam: string
  nummer: number
  type: UserType
  isGeactiveerd: boolean
}

export enum UserType {
  Customer = 0,
  Transporter = 1,
  Employee = 2,
}
