import { AxiosResponse } from 'axios'

import {
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@rfh/ui/components/Inputs/TextField'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { BaseDialog } from 'src/components/dialogs/BaseDialog'
import { useCustomer } from 'src/hooks/useCustomer'
import { Customer } from 'src/types/customer'

export type CustomerNumberDialogProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSubmit: (value: Customer) => Promise<void>
  masterFustPass?: boolean
}

export const CustomerNumberDialog: FC<CustomerNumberDialogProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  masterFustPass,
}) => {
  const { t } = useTranslation()
  const [customerNumber, setCustomerNumber] = useState<string>('')

  const { refetch, isError, isLoading } = useCustomer(Number(customerNumber), {
    enabled: false,
  })

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  useEffect(() => {
    setErrorMessage(isError ? t('errorMessage.noCustomer') : undefined)
  }, [isError])

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setCustomerNumber(event.target.value)
    setErrorMessage(undefined)
  }

  const handleSubmit = async () => {
    const fetchResult = await refetch()
    const isActivated = ((fetchResult.data as AxiosResponse)?.data as Customer)
      ?.isGeactiveerd
    if (!isActivated) {
      setErrorMessage(t('errorMessage.notActivatedCard'))
    }
    if (!fetchResult.isError && isActivated) {
      await onSubmit((fetchResult.data as AxiosResponse)?.data as Customer)
    }
  }

  return (
    <BaseDialog isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleSubmit}>
      {masterFustPass ? (
        <>
          <RfhTypography variant={'subheader'} pt={2}>
            {t('dialog.masterFustpassScan')}
          </RfhTypography>
          <RfhTypography variant={'body1'} pb={0}>
            {t('dialog.customerNumber')}
          </RfhTypography>
        </>
      ) : (
        <RfhTypography variant={'subheader'}>
          {t('dialog.customerNumber')}
        </RfhTypography>
      )}
      <TextField
        autoComplete={'off'}
        error={!!errorMessage}
        helperText={errorMessage}
        autoFocus
        value={customerNumber}
        onChange={handleChange}
        fullWidth
        inputProps={{
          'aria-label': 'klantnummer',
        }}
      />
      <Button
        variant='block--contained'
        color='primary'
        fullWidth
        type={'submit'}
        isLoading={isLoading}
      >
        {t('button.confirm')}
      </Button>
    </BaseDialog>
  )
}
