import { FC } from 'react'

import Box from '@mui/material/Box'

export const DataGridWrapper: FC = ({ children }) => (
  <Box
    sx={{
      '& .MuiGrid-root .MuiGrid-item[role="cell"]:last-child': {
        fontWeight: 700,
      },
      '& .MuiGrid-root .MuiGrid-item[role="cell"]:first-child': {
        paddingLeft: theme => theme.spacing(1),
      },
      '& .MuiGrid-root .MuiGrid-item[role="columnheader"]:first-child': {
        paddingLeft: theme => theme.spacing(1),
      },
    }}
  >
    {children}
  </Box>
)
