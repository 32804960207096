import { FC, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { useActor, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { EditableSelectedTransactions } from 'src/components/IssuanceFlow/storeTransactions/EditableSelectedTransactions'
import { ListOfStoreTransactions } from 'src/components/IssuanceFlow/storeTransactions/ListOfStoreTransactions'
import { ReviewSelectedTransactions } from 'src/components/IssuanceFlow/storeTransactions/ReviewSelectedTransactions'
import { Layout } from 'src/components/Layouts'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import {
  issuanceStoreTransactionsMachineModel,
  issuanceStoreTransactionsService,
} from 'src/machines/issuanceStoreTransactionsMachine'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

const { LOAD_TRANSACTIONS_LIST, ASSIGN_SCANNED_FUST_PASS_CONTEXT, RESET_ERROR } =
  issuanceStoreTransactionsMachineModel.events

export const StoreTransactionsFlowStart: FC = () => {
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)

  const childTransactionsService = useMemo(() => {
    return mainFlowService.state
      ? (mainFlowService.state.children
          .issuanceStoreTransactionsFlow as typeof issuanceStoreTransactionsService)
      : issuanceStoreTransactionsService
  }, [mainFlowService])

  const depotName = useSelector(
    mainFlowService,
    currentState =>
      `${currentState?.context?.selectedDepot?.code} - ${currentState?.context?.selectedDepot?.vestiging}`
  )
  const transporter = useSelector(
    mainFlowService,
    currentState =>
      `${currentState?.context?.scannedFustPassContext?.scannedFustPass?.klant.nummer} - ${currentState?.context?.scannedFustPassContext?.scannedFustPass?.klant.naam}`
  )

  const unannouncedCustomer = useSelector(childTransactionsService, currentState =>
    currentState?.context?.unannouncedCustomerNumber
      ? `${currentState?.context?.unannouncedCustomerNumber} - ${currentState?.context?.unannouncedCustomerName}`
      : currentState?.context?.selectedTransactions?.length > 0
      ? `${currentState?.context?.selectedTransactions?.[0].klantnummer} - ${currentState?.context?.selectedTransactions?.[0].klantNaam}`
      : ''
  )

  const scannedFustPassContext = useSelector(
    mainFlowService,
    currentState => currentState?.context?.scannedFustPassContext
  )
  const { t } = useTranslation()
  const [, send] = useActor(childTransactionsService)

  useEffect(() => {
    send(ASSIGN_SCANNED_FUST_PASS_CONTEXT(scannedFustPassContext))
    send(LOAD_TRANSACTIONS_LIST())
  }, [])

  const viewingListOfTransactionsParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return (
        matches('viewing_list_of_transactions') ||
        matches('loading_transactions') ||
        matches('loading_transaction_item') ||
        matches('asking_customer_number') ||
        matches('viewing_transaction_details') ||
        matches('updating_transaction_status')
      )
    }
  )

  const reviewingTransactionsParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return (
        matches('reviewing_transactions') ||
        matches('confirming_with_fust_pass') ||
        matches('completed_transactions') ||
        matches('handling_transactions_submission') ||
        matches('handling_unannounced_transactions_submission')
      )
    }
  )

  const editingTransactionsParentState = useSelector(
    childTransactionsService,
    ({ matches }) => {
      return (
        matches('editing_transactions') ||
        matches('validating_unannounced_transaction') ||
        matches('validating_transaction')
      )
    }
  )

  const error = useSelector(
    childTransactionsService,
    currentState => currentState.context.error
  )

  return (
    <Layout title={t('issuance.store')}>
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />

      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        <>
          {viewingListOfTransactionsParentState && (
            <ListOfStoreTransactions service={childTransactionsService}>
              <TransactionsHeader
                items={[
                  { label: 'depot', value: depotName },
                  { label: 'transporter', value: transporter },
                ]}
              />
            </ListOfStoreTransactions>
          )}
          {editingTransactionsParentState && (
            <EditableSelectedTransactions service={childTransactionsService}>
              <TransactionsHeader
                items={[
                  { label: 'depot', value: depotName },
                  { label: 'transporter', value: transporter },
                  { label: 'customer', value: unannouncedCustomer },
                ]}
              />
            </EditableSelectedTransactions>
          )}
          {reviewingTransactionsParentState && (
            <ReviewSelectedTransactions service={childTransactionsService}>
              <TransactionsHeader
                items={[
                  { label: 'depot', value: depotName },
                  { label: 'transporter', value: transporter },
                  { label: 'customer', value: unannouncedCustomer },
                ]}
              />
            </ReviewSelectedTransactions>
          )}
        </>
      </Box>
    </Layout>
  )
}
