import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { IssuanceType } from 'src/types/issuance'
import { TransactionType } from 'src/types/transaction'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export const TransactionTypeForm: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(IssuanceCreateFlowContext)
  const [state, send] = useActor(service)
  return (
    <FormControl>
      <RfhTypography variant={'subheader'}>{t('issuance.general')}</RfhTypography>
      <RadioGroup
        row
        value={
          !state.context.selectedTransactionType
            ? null
            : state.context.selectedTransactionType
        }
        onChange={event => {
          send({
            type: 'ASSIGN_SELECTED_TRANSACTION_TYPE',
            transactionType: Number(event.target.value),
          })
        }}
      >
        <FormControlLabel
          value={TransactionType.UitgifteWinkel}
          control={<Radio />}
          label={IssuanceType.winkel}
        />
        <FormControlLabel
          value={TransactionType.UitgifteDirect}
          control={<Radio />}
          label={IssuanceType.direct}
        />
        <FormControlLabel
          value={TransactionType.UitgifteDock}
          control={<Radio />}
          label={IssuanceType.dock}
        />
      </RadioGroup>
    </FormControl>
  )
}
