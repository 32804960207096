import { AxiosResponse, AxiosError } from 'axios'
import { v4 as uuid } from 'uuid'
import { assign, interpret } from 'xstate'
import { createModel } from 'xstate/lib/model'

import { FustAmount } from 'src/types/fust'
import {
  TransactionStatus,
  TransactionType,
  TransportTransaction,
} from 'src/types/transaction'
import { getApiErrorMessageOrFallback } from 'src/utils/api'

import {
  createValidatedTransportTransaction,
  handleTransportTransaction,
  queryTransportTransactions,
  updateTransportTransactionStatus,
} from '../services/transactionService'

export type InterDepotTransportMachineContext = {
  selectedDepartureLocation?: string
  fustAmount: FustAmount[]
  selectedArrivalLocation?: string
  warningMessage?: string
  isTransactionConfirmed: boolean
  uuid: string
  id: string
  error?: string
}
export const interDepotTransportMachineModel = createModel(
  {
    selectedDepartureLocation: undefined,
    fustAmount: [],
    selectedArrivalLocation: undefined,
    isTransactionConfirmed: false,
    uuid: '',
    id: '',
    error: undefined,
  } as InterDepotTransportMachineContext,
  {
    events: {
      SELECT_DEPARTURE_LOCATION: (location: string) => ({ location }),
      SELECT_ARRIVING_LOCATION: (arrivingLocation: string) => ({ arrivingLocation }),
      CONTINUE: () => ({}),
      CANCEL: () => ({}),
      ASSIGN_TRANSACTION: (fustAmount: FustAmount[]) => ({ fustAmount }),
      EDIT_TRANSACTION: () => ({}),
      SUBMIT_TRANSACTION: () => ({}),
      RESET_ERROR: () => ({}),
    },
  }
)

export const interDepotTransportMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEsB2AXMAnAImADgPboAqWAhqrEVugLLkDGAFmmAHTmwDWaUA+hALlaAVyxh+AG0KNy6ZIVQBiAMoBRADLqAwiX6aA8joCCJAJKGAcolBFYyBUtsgAHogCMABg8BOdl4ATADMAKyBvj4ALABsAOxxADQgAJ6IgaFx7FG+vgAccUF5XuFRocEAvhXJaJi4BMRklNSEtAwsbJw8fILCYhLSsvKKKjrWFlYAquou9o4jLu4IHhkx7CFlETF5+V4xoclpCMF+7Lm+CYFRUWExMb5RVTUY2HhEpBRUNPRMrKgcjEIAFs5qgBOhPrAmE4VCZVKpzABxKz8EgAJRMVlUJj0lhsSBAcxhi08MQ8eXWHjisTyUTy8ROHkOiHKHmy50C+wSvmCESeIFqrwaH2a33afwBwNB4Mh0JGyjGVgm01mhAcxIJS3JcQpV0C1K8OQ8ZPpzIQoR5Z1yeR2vLJwR2-MF9XeTS+rR+HX+7AAbuQpMgIMMwfwIc05UplBAlBw0D7CNxYy8XY1IWLfp0-QGgwoQ2GqBHUAg40MYQBtLwAXVV6oWms8BV1UX1UUNvmN5JiZo8wSy5x29zuvk5Xkq1QFybeqdFHvFmf9geDMvDjBhymwWFa7HwUnkADNWkD2M6pyL3W0M96s4vc8uC6uRsXUPG5OWqzX5s568sbV52MVwlCEo4juHszR2K1ciA4JgguCIPEeccT2FN0WgvL0OFgZhCAAdx6HCRFQHoIGQf1CCgBVxnMKYZgJIk61AJZ9T7LY8hOYIvDyDxjSSVJEGKfxjX2WJfBiXkfECJ1JxQtNZ0vTDsLwkMCKwIiQxIsiKNMKwdC0D8NUY9IEjOVj2M47j4jNID-ByC42OCZsvFE+kpLqU9UPTDD2Cw3CehELBkCzQZXxGQRSJkLSqJo-SGLcRB7lCdgdXbYIYh8UIMoyM04lE9ZbMCQ1QiiOJQjyVyhVdWT0IlbzFL8rAAqCmQQqUMLNIVTFdM0GKv0MhAEqSnYezSjwMsAs0YkCCl+ypK5OItDxypTM80M9GqfKUgR-MC-1guGVqNIitQtF0fQjFMCxrB61ASQQa4KT2DIuLYi5Qm4g4+IQOICvZfIuPgvIQkkpDpMqmdqs6CQfWQMBNtDWUH0jdQcHMfR0UxbFcSuui1U-G7vxWUI1g2cJnN2fZsrpX68jentRMCHslvcqq1shsBodhnp8yhRGVFUSYACE6FR1EMSxHFLvxOxcYMuLllWdYHNJ7ZyY+o5aSian9iJm5mxiJmZPB1nvVgUQACMgUcW94ZXNdo29EtE2PUHp3PY3MPNy30Gt7nCyfF99tQCtqxx2terl7UmxbNsO1NT7AYpeyCpCC4qTeg2wbducTc9q2uYRtcNy3Hd90PZ23MNrP5O83Pvfz23HxLFqg-fUO8du4bEtyAq7gchCbmy3LbPbW04gQomqnHVBCCEeACWQzPVuzjguF4dS+nQcRJGawPro7nw2X2MSRyCfY9jNBybOtPx4g2WlQgz12l+rwEQVxvMC9i+jw61E12HKBIXhOJRB7NSPIE0QjUxKqJV68RH4rU8jVa8OZ673llt-fGfUGY-UmpkIBY8OI9iiN2Jy1NijciCAzXw8CPJyS8htfChFiLhXInvb8zZB5-hAdrEqgQCpBGoSDCui9EGdAYSGbaTVSyhUOqwtusslggLZDTHktJvDhDYoEcCKxqYcW2KlYqi0hEVSfqI70UMYZw19rzNhfVvAcXYFSYojY4i9gdOBDISUci0h1DqWm+tjHLVoRDHOFs84fwbuHDBHc0oUhKjlYoPgHI5V4kcFJ-4YJXHiA8G4D9AnMyNsvWqvkQym0YIwOAsA2oRVsXLbBf5cGFC8AQpJxD458IyXaHUKx7E0JZsvWpWpwGfXJAEIB4yJnjJuJPCoQA */
  interDepotTransportMachineModel.createMachine(
    {
      context: interDepotTransportMachineModel.initialContext,
      tsTypes: {} as import('./interDepotTransportMachine.typegen').Typegen0,
      schema: {
        services: {} as {
          submitTransaction: { data: void }
          validateTransaction: {
            data: AxiosResponse<TransportTransaction>
          }
          updateStatus: {
            data: AxiosResponse<void>
          }
          getTransactions: {
            data: AxiosResponse<TransportTransaction[]>
          }
        },
      },
      predictableActionArguments: true,
      id: 'interDepotTransportMachine',
      initial: 'getting_aborted_transports',
      states: {
        getting_aborted_transports: {
          invoke: {
            src: 'getTransactions',
            onDone: {
              actions: assign((_, event) => ({
                id: event.data?.data[0]?.id || '',
                fustAmount: event.data?.data[0]?.fustAantallen || [],
                selectedArrivalLocation: undefined,
                selectedDepartureLocation: event.data?.data[0]?.vanLocatie || undefined,
              })),
              target: 'checking_aborted_transports',
            },
            onError: [
              {
                actions: 'assignError',
              },
            ],
          },
        },
        checking_aborted_transports: {
          always: [
            {
              target: 'showing_aborted_transaction_dialog',
              cond: 'isAbortedTransport',
            },
            { target: 'asking_departure_location' },
          ],
        },
        showing_aborted_transaction_dialog: {
          on: {
            CONTINUE: {
              target: 'asking_arrival_location',
            },
          },
        },
        asking_departure_location: {
          on: {
            SELECT_DEPARTURE_LOCATION: {
              actions: 'assignDepartureLocation',
            },
            CONTINUE: {
              actions: 'assignUUID',
              cond: 'hasSelectedDepartureLocation',
              target: 'composing_transaction',
            },
          },
        },
        composing_transaction: {
          on: {
            ASSIGN_TRANSACTION: {
              actions: 'assignTransaction',
            },
            CONTINUE: [
              {
                cond: 'hasFustAmountAndIsNotValidated',
                target: 'validating_transaction',
              },
              {
                cond: 'hasFustAmountAndIsValidated',
                target: 'reviewing_transaction',
              },
            ],
          },
        },
        validating_transaction: {
          tags: ['loading'],
          invoke: {
            src: 'validateTransaction',
            onDone: [
              {
                target: 'updating_status',
                actions: assign((_context, event) => {
                  return {
                    id: event.data.data.id,
                  }
                }),
              },
            ],
            onError: [
              {
                cond: (_, event) => event.data.response?.data[0]?.message,
                actions: assign((_context, event) => {
                  return {
                    warningMessage: event.data.response.data[0].message,
                  }
                }),
                target: 'showing_warning_dialog',
              },
              {
                actions: 'assignError',
                target: 'composing_transaction',
              },
            ],
          },
        },
        updating_status: {
          tags: ['loading'],
          invoke: {
            src: 'updateStatus',
            onDone: [
              {
                cond: context => !context.selectedArrivalLocation,
                target: 'asking_arrival_location',
              },
              {
                cond: context => !!context.selectedArrivalLocation,
                target: 'reviewing_transaction',
              },
            ],
            onError: [
              {
                target: 'composing_transaction',
              },
            ],
          },
        },
        showing_warning_dialog: {
          on: {
            CONTINUE: {
              target: 'validating_transaction',
              actions: 'assignIsTransactionConfirmed',
            },
            CANCEL: {
              target: 'composing_transaction',
            },
          },
        },
        asking_arrival_location: {
          on: {
            SELECT_ARRIVING_LOCATION: {
              actions: 'assignArrivalLocation',
            },
            CONTINUE: {
              cond: 'hasSelectedArrivalLocation',
              target: 'reviewing_transaction',
            },
          },
        },
        reviewing_transaction: {
          on: {
            EDIT_TRANSACTION: {
              target: 'composing_transaction',
            },
            SUBMIT_TRANSACTION: {
              target: 'submitting_transaction',
            },
          },
        },
        submitting_transaction: {
          invoke: {
            src: 'submitTransaction',
            onDone: [
              {
                target: 'showing_success_dialog',
              },
            ],
            onError: [
              {
                target: 'reviewing_transaction',
                actions: 'assignError',
              },
            ],
          },
        },
        showing_success_dialog: {
          type: 'final',
        },
      },
      on: {
        RESET_ERROR: {
          actions: 'resetError',
        },
      },
    },
    {
      guards: {
        isAbortedTransport: context => !!context.fustAmount.length,
        hasSelectedDepartureLocation: context => !!context.selectedDepartureLocation,
        hasFustAmountAndIsNotValidated: context =>
          context.fustAmount.length > 0 && !context.id,
        hasFustAmountAndIsValidated: context =>
          context.fustAmount.length > 0 && !!context.id,

        hasSelectedArrivalLocation: context => {
          if (context.selectedArrivalLocation) {
            return context.selectedArrivalLocation !== context.selectedDepartureLocation
          } else {
            return false
          }
        },
      },
      actions: {
        assignError: assign((_context, event) => ({
          error: getApiErrorMessageOrFallback(event.data as AxiosError),
        })),
        resetError: assign(_ => ({ error: undefined })),
        assignIsTransactionConfirmed: assign(_context => ({
          isTransactionConfirmed: true,
        })),
        assignUUID: assign(_context => ({
          uuid: uuid(),
        })),
        assignDepartureLocation: assign((_context, event) => ({
          selectedDepartureLocation: event.location,
        })),
        assignTransaction: assign((_context, event) => ({
          fustAmount: event.fustAmount,
        })),
        assignArrivalLocation: assign((context, event) => ({
          selectedArrivalLocation: event.arrivingLocation,
          error:
            context.selectedDepartureLocation === event.arrivingLocation
              ? 'Aankomstlocatie mag niet hetzelfde zijn als vertreklocatie.'
              : undefined,
        })),
      },
      services: {
        submitTransaction: async context => {
          return handleTransportTransaction({
            vanLocatie: context.selectedDepartureLocation,
            naarLocatie: context.selectedArrivalLocation,
            bevestigItemState: context.isTransactionConfirmed,
            id: context.id,
            transactieType: TransactionType.Transport,
            datum: new Date().toISOString(),
            fustAantallen: context.fustAmount,
            status: TransactionStatus.Nieuw,
            subStatus: TransactionStatus.Nieuw,
            createdOn: new Date().toISOString(),
          })
        },
        validateTransaction: async context => {
          return createValidatedTransportTransaction(context.uuid, {
            vanLocatie: context.selectedDepartureLocation,
            naarLocatie: localStorage.getItem('mfb-selected-branch') || 'Aalsmeer',
            transactieType: TransactionType.Transport,
            datum: new Date().toISOString(),
            fustAantallen: context.fustAmount,
            status: TransactionStatus.Nieuw,
            bevestigItemState: context.isTransactionConfirmed,
          })
        },
        updateStatus: async context => {
          return updateTransportTransactionStatus(context.id, {
            NewStatus: TransactionStatus['In behandeling'],
          })
        },
        getTransactions: async () => {
          return queryTransportTransactions()
        },
      },
    }
  )

export const interDepotTransportService = interpret(interDepotTransportMachine)
