import { ChangeEvent, FC } from 'react'

import Box from '@mui/material/Box'
import TextField from '@rfh/ui/components/Inputs/TextField'

import { FustAmount, FustAmountColumnLabel, FustAmountKeys } from 'src/types/fust'

import { BodyRowContainer, HeadRowContainer, GridItem } from './CountTable'

export type handleChange = (
  code: FustAmountKeys,
  amount: FustAmount
) => (event: ChangeEvent<HTMLInputElement>) => void

const columns = 4

export type TableType = 'split' | 'edit'

export const SplitOrEditTable: FC<{
  fustAmount: FustAmount[]
  tableType: TableType
  handleChange: (
    code: FustAmountKeys,
    amount: FustAmount
  ) => (event: ChangeEvent<HTMLInputElement>) => void
}> = ({ fustAmount, tableType, handleChange }) => (
  <Box>
    <HeadRowContainer columns={columns}>
      <GridItem xs={1}>{FustAmountColumnLabel.fustCode}</GridItem>
      <GridItem xs={1}>{FustAmountColumnLabel.stapelwagens}</GridItem>
      <GridItem xs={1}>{FustAmountColumnLabel.stapels}</GridItem>
      <GridItem xs={1}>{FustAmountColumnLabel.stuks}</GridItem>
    </HeadRowContainer>
    {fustAmount.map(amount => (
      <BodyRowContainer columns={columns} key={amount.fustCode}>
        <GridItem xs={1}>
          <TextField
            autoComplete={'off'}
            fullWidth
            value={amount.fustCode}
            disabled={tableType === 'split'}
            onChange={handleChange('fustCode', amount)}
            inputProps={{
              'aria-label': 'code',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 3,
            }}
          />
        </GridItem>
        <GridItem xs={1}>
          <TextField
            autoComplete={'off'}
            fullWidth
            value={amount.stapelwagens}
            onChange={handleChange('stapelwagens', amount)}
            inputProps={{
              'aria-label': 'stw',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 2,
            }}
          />
        </GridItem>
        <GridItem xs={1}>
          <TextField
            autoComplete={'off'}
            fullWidth
            value={amount.stapels}
            onChange={handleChange('stapels', amount)}
            inputProps={{
              'aria-label': 'stpl',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 2,
            }}
          />
        </GridItem>
        <GridItem xs={1}>
          <TextField
            autoComplete={'off'}
            fullWidth
            value={amount.stuks}
            onChange={handleChange('stuks', amount)}
            inputProps={{
              'aria-label': 'stk',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 4,
            }}
          />
        </GridItem>
      </BodyRowContainer>
    ))}
  </Box>
)
