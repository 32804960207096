import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { CountTable, CountTableProps } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  issuanceStoreTransactionsMachineModel,
  issuanceStoreTransactionsService,
} from 'src/machines/issuanceStoreTransactionsMachine'
import { FustAmount } from 'src/types/fust'

export type EditSelectedTransactionProps = {
  service: typeof issuanceStoreTransactionsService
}
const { REVIEW_TRANSACTIONS, ASSIGN_FUST_AMOUNT, ASSIGN_SELECTED_TRANSACTIONS } =
  issuanceStoreTransactionsMachineModel.events

export const EditableSelectedTransactions: FC<EditSelectedTransactionProps> = ({
  service,
  children,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const [canContinue, setCanContinue] = useState<boolean>(true)
  const updateSelectedTransactionsFustAmount = (fustAmount: FustAmount[]) => {
    const updatedSelectedTransactions = state.context.selectedTransactions.map(
      transaction => {
        return {
          ...transaction,
          fustAantallen: [...fustAmount],
        }
      }
    )
    send(ASSIGN_SELECTED_TRANSACTIONS(updatedSelectedTransactions))
  }

  const handleRowAdd: CountTableProps['handleRowAdd'] = countRowInput => {
    const newFustAmount = [...state.context.fustAmount, countRowInput]
    send(ASSIGN_FUST_AMOUNT(newFustAmount))
    updateSelectedTransactionsFustAmount(newFustAmount)
  }

  const handleRowRemove: CountTableProps['handleRowRemove'] = (oldRowIndex: number) => {
    const newFustAmount = state.context.fustAmount.filter(
      (_, index) => index !== oldRowIndex
    )
    send(ASSIGN_FUST_AMOUNT(newFustAmount))
    updateSelectedTransactionsFustAmount(newFustAmount)
  }

  const handleRowEdit: CountTableProps['handleRowEdit'] = (
    countRowInput,
    editedIndex: number
  ) => {
    const newFustAmount = state.context.fustAmount.map((fustAmount, index) => {
      if (index === editedIndex) {
        return countRowInput
      }
      return fustAmount
    })

    send(ASSIGN_FUST_AMOUNT(newFustAmount))
    updateSelectedTransactionsFustAmount(newFustAmount)
  }

  return (
    <FlowView p={0} loading={state.hasTag('loading')}>
      <FlowViewContent>
        {children}
        <RfhTypography sx={{ my: 2, px: 2 }} variant={'subtitle1'}>
          {t('transactions.edit')}
        </RfhTypography>
        <CountTable
          rows={state.context.fustAmount}
          handleRowAdd={handleRowAdd}
          handleRowRemove={handleRowRemove}
          handleRowEdit={handleRowEdit}
          setCanContinue={setCanContinue}
          columns={['fustCode', 'stapelwagens', 'stapels', 'stuks']}
        />
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          disabled={!(state.can('REVIEW_TRANSACTIONS') && canContinue)}
          onClick={() => send(REVIEW_TRANSACTIONS())}
        >
          {t('button.confirm')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
