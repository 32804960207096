/* istanbul ignore file */
import { FC, useEffect } from 'react'

import Box from '@mui/material/Box'
import { useMachine } from '@xstate/react'

import {
  scanFustPassFlowMachine,
  ScanFustPassFlowMachineContext,
  scanFustPassFlowMachineModel,
} from 'src/machines/scanFustPassFlowMachine'
import { FustTransaction } from 'src/types/transaction'

import { CustomerNumberDialog } from './CustomerNumberDialog'
import { FustPassNumberDialog } from './FustPassNumberDialog'

export type ScanFustPassFlowDialogProps = {
  onCompleted?: (context: ScanFustPassFlowMachineContext) => void
  startProcess?: boolean
  handleClose?: () => void
  hasAuthorityApi?: boolean
  selectedTransactions?: FustTransaction[]
  hasMasterpasOverride?: boolean
}

export const ScanFustPassFlowDialogs: FC<ScanFustPassFlowDialogProps> = ({
  onCompleted,
  startProcess,
  handleClose,
  hasAuthorityApi,
  selectedTransactions,
  hasMasterpasOverride,
}) => {
  const [state, send] = useMachine(scanFustPassFlowMachine)
  const {
    START_SCAN_PROCESS,
    CLOSE_DIALOG,
    SUBMIT_VALIDATED_FUST_PASS,
    SUBMIT_VALIDATED_MASTERPAS_CUSTOMER,
  } = scanFustPassFlowMachineModel.events

  useEffect(() => {
    if (startProcess) {
      send(START_SCAN_PROCESS())
      return
    }
  }, [startProcess])

  useEffect(() => {
    if (state.matches('completed')) {
      onCompleted && onCompleted(state.context)
      return
    }
  }, [state])

  return (
    <Box>
      <FustPassNumberDialog
        isOpen={state.matches('asking_for_fust_pass_number')}
        setIsOpen={value => {
          if (!value) {
            send(CLOSE_DIALOG())
            handleClose && handleClose()
          }
          return value
        }}
        onCardIsValid={(card, { isCustomer }, authorities) => {
          send(SUBMIT_VALIDATED_FUST_PASS(card, authorities))
        }}
        hasAuthorityApi={hasAuthorityApi}
        selectedTransactions={selectedTransactions}
        hasMasterpasOverride={hasMasterpasOverride}
      />
      <CustomerNumberDialog
        isOpen={state.matches('asking_for_masterpas_customer_number')}
        setIsOpen={value => {
          if (!value) {
            send(CLOSE_DIALOG())
            handleClose && handleClose()
          }
          return value
        }}
        onSubmit={value => {
          send(SUBMIT_VALIDATED_MASTERPAS_CUSTOMER(value))
          return Promise.resolve()
        }}
        masterFustPass={state.matches('asking_for_masterpas_customer_number')}
      />
    </Box>
  )
}
