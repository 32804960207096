import { interpret } from 'xstate'

import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useInterpret, useSelector, useActor } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { EditableInventoryCount } from 'src/components/InventoryManagementFlow/EditableInventoryCount'
import { InventoryOperationOptions } from 'src/components/InventoryManagementFlow/InventoryOperationOptions'
import { PreInformation } from 'src/components/InventoryManagementFlow/PreInformation'
import { ReviewInventoryCount } from 'src/components/InventoryManagementFlow/ReviewInventoryCount'
import { Layout } from 'src/components/Layouts'
import { AbortedTransactionDialog } from 'src/components/dialogs/AbortedTransactionDialog'
import {
  inventoryManagementMachine,
  inventoryManagementMachineModel,
} from 'src/machines/inventoryManagementMachine'

//@todo enable this to from specific point of flow
// import { editingCountFustAmountState } from '../machines/persistedStates/inventoryManagement'

export const inventoryManagementService = interpret(inventoryManagementMachine)
export const InventoryManagementFlowContext = createContext<{
  service: typeof inventoryManagementService
}>({
  service: inventoryManagementService,
})

export type IssuanceFlowProps = {
  service?: typeof inventoryManagementService
}

const { CLOSE_DIALOG, CONTINUE, RESET_ERROR } = inventoryManagementMachineModel.events

export const InventoryManagementFlow: FC<IssuanceFlowProps> = ({ service }) => {
  const { t } = useTranslation()
  const defaultService = useInterpret(inventoryManagementMachine, {
    devTools: process.env.REACT_APP_XSTATE_INSPECT !== undefined,
    //@todo enable this to from specific point of flow
    // state: editingCountFustAmountState,
  })
  const actualService = service || defaultService

  const [, send] = useActor(actualService)

  const checkingAbortedCountState = useSelector(actualService, currentState => {
    return (
      currentState.matches('checking_aborted_count') ||
      currentState.matches('showing_aborted_count_dialog')
    )
  })

  const askingPreInformationParentState = useSelector(actualService, currentState => {
    return currentState.matches('asking_pre_information')
  })

  const viewingInventoryOperationOptionsParentState = useSelector(
    actualService,
    currentState => {
      return currentState.matches('viewing_inventory_operation_options')
    }
  )

  const editingInventoryCountingParentState = useSelector(
    actualService,
    currentState => {
      return (
        currentState.matches('editing_counting_inventory') ||
        currentState.matches('loading_previously_count_fust_amount')
      )
    }
  )

  const reviewingInventoryFustAmountParentState = useSelector(
    actualService,
    currentState => {
      return (
        currentState.matches('reviewing_inventory_fust_amount') ||
        currentState.matches('validating_inventory_fust_amount') ||
        currentState.matches('submitting_inventory_fust_amount') ||
        currentState.matches('showing_success_dialog')
      )
    }
  )

  const error = useSelector(actualService, currentState => currentState.context.error)
  const isBulk = useSelector(actualService, currentState => currentState.context.isBulk)

  return (
    <InventoryManagementFlowContext.Provider value={{ service: actualService }}>
      <Layout
        title={
          !isBulk ? t('inventoryManagement.general') : t('inventoryManagement.bulk')
        }
      >
        <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />

        {checkingAbortedCountState && (
          <AbortedTransactionDialog
            title={t('dialog.abortedInventory')}
            isOpen={checkingAbortedCountState}
            setIsOpen={value => {
              /* istanbul ignore next */ if (!value) {
                actualService.send(CLOSE_DIALOG())
              }
              /* istanbul ignore next */ return value
            }}
            onSubmit={() => {
              actualService.send(CONTINUE())
            }}
          />
        )}
        {askingPreInformationParentState && <PreInformation />}
        {viewingInventoryOperationOptionsParentState && <InventoryOperationOptions />}
        {editingInventoryCountingParentState && <EditableInventoryCount />}
        {reviewingInventoryFustAmountParentState && <ReviewInventoryCount />}
      </Layout>
    </InventoryManagementFlowContext.Provider>
  )
}
