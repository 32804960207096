import { FC, MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { CountTable, CountTableProps } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { FustAmount } from 'src/types/fust'
import { FustTransaction } from 'src/types/transaction'

export type EditSelectedTransactionProps = {
  selectedTransactions: FustTransaction[]
  fustAmount: FustAmount[]
  updateDataGridHandler: (
    newFustAmount: FustAmount[],
    newSelectedTransactions: FustTransaction[]
  ) => void
  confirmClickHandler: MouseEventHandler
  loading?: boolean
}

export const EditableSelectedTransactionsView: FC<EditSelectedTransactionProps> = ({
  selectedTransactions,
  fustAmount,
  updateDataGridHandler,
  confirmClickHandler,
  loading,
  children,
}) => {
  const { t } = useTranslation()
  const [canContinue, setCanContinue] = useState<boolean>(true)

  const updateSelectedTransactionsFustAmount = (currentFustAmount: FustAmount[]) => {
    const updatedSelectedTransactions = selectedTransactions.map(transaction => {
      return {
        ...transaction,
        fustAantallen: [...currentFustAmount],
      }
    })
    updateDataGridHandler &&
      updateDataGridHandler(currentFustAmount, updatedSelectedTransactions)
  }

  const handleRowAdd: CountTableProps['handleRowAdd'] = row => {
    const newFustAmount = [...fustAmount, row]
    updateSelectedTransactionsFustAmount(newFustAmount)
  }

  const handleRowRemove: CountTableProps['handleRowRemove'] = (oldRowIndex: number) => {
    const newFustAmount = fustAmount.filter((_, index) => index !== oldRowIndex)
    updateSelectedTransactionsFustAmount(newFustAmount)
  }

  const handleRowEdit: CountTableProps['handleRowEdit'] = (
    countRowInput,
    editedIndex: number
  ) => {
    const newFustAmount = fustAmount.map((currentFustAmount, index) => {
      if (index === editedIndex) {
        return countRowInput
      }
      return currentFustAmount
    })
    updateSelectedTransactionsFustAmount(newFustAmount)
  }
  const getCountTableRows = (): CountTableProps['rows'] => {
    return fustAmount
  }

  return (
    <FlowView px={0} py={0} loading={loading}>
      <FlowViewContent>
        {children}
        <RfhTypography sx={{ m: 2 }} variant={'subtitle1'}>
          {t('transactions.edit')}
        </RfhTypography>
        <CountTable
          rows={getCountTableRows()}
          handleRowAdd={handleRowAdd}
          handleRowRemove={handleRowRemove}
          handleRowEdit={handleRowEdit}
          setCanContinue={setCanContinue}
          columns={['fustCode', 'stapelwagens', 'stapels', 'stuks']}
        />
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          disabled={!(fustAmount.length && canContinue)}
          onClick={confirmClickHandler}
        >
          {t('button.confirm')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
