import {
  updateTransactionLocation,
  submitValidatedTransactionFustTotal,
  updateTransactionStatus,
  createUnannouncedValidatedTransaction,
} from 'src/services/transactionService'
import { FustAmount } from 'src/types/fust'
import { FustTransaction, TransactionType } from 'src/types/transaction'

export const submitTransaction = async (
  transaction: FustTransaction,
  fustPassNumber: string,
  validatedCountRows: FustAmount[],
  selectedDepotCode?: string
): Promise<void> => {
  const validatedTransaction = {
    ...transaction,
    fustAantallen: validatedCountRows,
    locatie: selectedDepotCode || '',
  }

  if (validatedTransaction.locatie) {
    await updateTransactionLocation(validatedTransaction)
  }

  await submitValidatedTransactionFustTotal(
    validatedTransaction.klantnummer,
    validatedTransaction
  )

  const status =
    validatedTransaction.transactieType === TransactionType.InnameVast
      ? { NewStatus: 31 }
      : { NewStatus: 30, SubStatus: 30 }

  await updateTransactionStatus(transaction.id, {
    FustpasNummer: fustPassNumber,
    ...status,
  })
}

export const submitUnannouncedTransaction = async (
  customerNumber: string,
  transactionType: TransactionType,
  validatedRows: FustAmount[],
  fustPassNumber: string,
  location: string,
  vervoerderNummer: number
): Promise<FustTransaction> => {
  const { data: transaction } = await createUnannouncedValidatedTransaction({
    datum: new Date().toISOString(),
    id: null,
    klantNaam: '',
    klantnummer: customerNumber,
    locatie: location,
    status: 0,
    transactieType: transactionType,
    vervoerderNummer,
    fustAantallen: validatedRows,
  })

  const status =
    transactionType === TransactionType.InnameVast
      ? { NewStatus: 31 }
      : { NewStatus: 30, SubStatus: 30 }

  await updateTransactionStatus(transaction.id, {
    FustpasNummer: fustPassNumber,
    ...status,
  })

  return transaction
}
