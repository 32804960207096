import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useActor, useSelector } from '@xstate/react'

import { ReviewMultipleTransactions } from 'src/components/IssuanceFlow/dockTransactions/ReviewMultipleTransactions'
import { ReviewSingleTransaction } from 'src/components/IssuanceFlow/dockTransactions/ReviewSingleTransaction'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import { ConfirmFustPassNumberDialog } from 'src/components/dialogs/ConfirmFustPassNumberDialog'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  issuanceDockTransactionsMachineModel,
  issuanceDockTransactionsService,
} from 'src/machines/issuanceDockTransactionsMachine'
import { issuanceFlowMachineModel } from 'src/machines/issuanceFlowMachine'
import { FustTransaction, TransactionType } from 'src/types/transaction'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

const { CLOSE_FUST_PASS_NUMBER_DIALOG, CONTINUE } =
  issuanceDockTransactionsMachineModel.events

const { ASSIGN_SELECTED_TRANSACTION_TYPE } = issuanceFlowMachineModel.events

export type ReviewTransactionProps = {
  service: typeof issuanceDockTransactionsService
  selectedTransactions: FustTransaction[]
  scannedCustomerNumber?: number
  scannedCustomerName?: string
  customerNumberFromList?: number
  customerNameFromList?: string
  numberOfCustomers?: number
}
export const ReviewTransactions: FC<ReviewTransactionProps> = ({
  selectedTransactions,
  customerNameFromList,
  numberOfCustomers,
  customerNumberFromList,
  scannedCustomerName,
  scannedCustomerNumber,
  service,
}) => {
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)
  const [state, send] = useActor(service)
  const navigate = useNavigate()

  const handleCloseSuccessDialog = () => {
    service.start()
    mainFlowService.stop()
    mainFlowService.start()
    mainFlowService.send(ASSIGN_SELECTED_TRANSACTION_TYPE(TransactionType.UitgifteDock))
    navigate('/uitgifte/start')
  }

  const fustPassNumber = useSelector(
    service,
    currentState =>
      currentState.context.scannedFustPassContext?.scannedFustPass?.fustpasNummer
  )

  return (
    <>
      <ConfirmFustPassNumberDialog
        isOpen={state.matches('confirming_with_fust_pass')}
        handleClose={
          /* istanbul ignore next */ () => send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }
        handleSubmit={/* istanbul ignore next */ () => send(CONTINUE())}
        fustPassNumberToConfirm={fustPassNumber!}
      />
      <SuccessDialog
        isOpen={Boolean(state.done)}
        handleClose={handleCloseSuccessDialog}
      />
      {selectedTransactions.length === 1 ? (
        <ReviewSingleTransaction service={service}>
          <TransactionsHeader
            items={[
              {
                label: 'transporter',
                value: `${scannedCustomerNumber} - ${scannedCustomerName}`,
              },
              {
                label: 'customers',
                value: `${customerNumberFromList} - ${customerNameFromList}`,
              },
            ]}
          />
        </ReviewSingleTransaction>
      ) : (
        <ReviewMultipleTransactions service={service}>
          <TransactionsHeader
            items={[
              {
                label: 'transporter',
                value: `${scannedCustomerNumber} - ${scannedCustomerName}`,
              },
              { label: 'customers', value: numberOfCustomers },
            ]}
          />
        </ReviewMultipleTransactions>
      )}
    </>
  )
}
