import Select from '@mui/material/Select'
import styled from '@mui/material/styles/styled'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

export const StyledSelect = styled(Select)({
  paddingLeft: '8px',
  backgroundColor: RfhColors.white,
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #CCCCCC !important',
  },
})
