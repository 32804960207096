import noop from 'lodash/noop'

import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Branch } from 'src/types/branch'

export const BranchContext = createContext<{
  branch: Branch | undefined
  setBranch: Dispatch<SetStateAction<Branch>>
}>({ branch: undefined, setBranch: noop })

export const BranchContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [branch, setBranch] = useState<Branch>(
    () => (localStorage.getItem('mfb-selected-branch') as Branch) || undefined
  )

  useEffect(() => {
    if (branch) {
      localStorage.setItem('mfb-selected-branch', branch)
    }
  }, [branch])

  const value = useMemo(
    () => ({
      branch,
      setBranch,
    }),
    [branch, setBranch]
  )

  return <BranchContext.Provider value={value}>{children}</BranchContext.Provider>
}

export const useBranch = (): {
  branch: Branch | undefined
  setBranch: Dispatch<SetStateAction<Branch>>
} => {
  return useContext(BranchContext)
}
