import { FC } from 'react'

import styled from '@mui/material/styles/styled'

const RoundButton = styled('button')<{
  variant: 'outlined' | 'contained'
  disabled: boolean
  height?: string
  width?: string
  borderRadius?: string
  svgSize?: string
}>(
  ({
    theme: {
      rfhColors: { white, hydroGreen, dutchOrange, lightGrey },
    },
    variant,
    height = '30px',
    width = '30px',
    borderRadius = '15px',
    svgSize = '16px',
  }) => ({
    background: variant === 'contained' ? dutchOrange : 'none',
    borderRadius,
    height,
    width,
    color: variant === 'contained' ? white : dutchOrange,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: variant === 'contained' ? 'none' : `1px solid ${dutchOrange}`,
    flexShrink: 0,

    '&:disabled': {
      backgroundColor: variant === 'contained' ? lightGrey : 'none',
      color: variant === 'contained' ? white : lightGrey,
      borderColor: variant === 'contained' ? 'none' : lightGrey,
    },

    '&:hover:not(:disabled)': {
      backgroundColor: variant === 'contained' ? hydroGreen : 'none',
      color: variant === 'contained' ? white : hydroGreen,
      borderColor: variant === 'contained' ? 'none' : hydroGreen,
    },

    svg: {
      width: svgSize,
      height: svgSize,
    },
  })
)

export const RoundIconButton: FC<{
  variant?: 'outlined' | 'contained'
  icon: JSX.Element
  disabled?: boolean
  onClick?: () => void
  label: string
  type?: 'button' | 'submit'
  height?: string
  width?: string
  borderRadius?: string
  svgSize?: string
}> = ({
  variant = 'contained',
  disabled = false,
  icon,
  onClick,
  label,
  type = 'button',
  height,
  width,
  borderRadius,
  svgSize,
}) => (
  <RoundButton
    type={type}
    aria-label={label}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    height={height}
    width={width}
    borderRadius={borderRadius}
    svgSize={svgSize}
  >
    {icon}
  </RoundButton>
)
