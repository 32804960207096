import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import {
  rebookItemStateMachineModel,
  rebookItemStateService,
} from 'src/machines/rebookItemStateMachine'
import { Depot } from 'src/types/depot'

const { SELECT_LOCATION } = rebookItemStateMachineModel.events

export const Location: FC<{ service: typeof rebookItemStateService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  return (
    <FlowView>
      <FlowViewContent>
        <RfhTypography variant='subtitle1' mb={1}>
          {t('inputs.scanOrSearchLocation')}
        </RfhTypography>
        <DepotsAutocomplete
          value={state?.context?.selectedLocation}
          onChange={(_event, value) => {
            send(SELECT_LOCATION(value as Depot))
          }}
        />
      </FlowViewContent>
      <FlowViewActions>
        <Button
          disabled={!state.can('CONTINUE')}
          variant={'contained'}
          onClick={() => send({ type: 'CONTINUE' })}
        >
          {t('button.continue')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
