import { useQuery, UseQueryResult } from 'react-query'

import { queryWorkAreas } from 'src/services/workAreaService'
import { Branch } from 'src/types/branch'
import { WorkArea } from 'src/types/workArea'

const getWorkAreas = async () => {
  const branch =
    (localStorage.getItem('mfb-selected-branch') as Branch) || Branch.Aalsmeer
  const { data } = await queryWorkAreas(branch)
  return data
}

export default function useWorgetWorkAreas(): UseQueryResult<WorkArea[]> {
  return useQuery('workAreas', getWorkAreas)
}
