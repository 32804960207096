import format from 'date-fns/format'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DataGrid from '@rfh/ui/components/DataGrid/DataGrid'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import {
  FlowDialog,
  FlowDialogContent,
  FlowDialogProps,
  FlowDialogTitle,
} from 'src/components/FlowDialog'
import { FlowView, FlowViewContent } from 'src/components/FlowView'
import { FustAmount, FustAmountColumnLabel } from 'src/types/fust'
import { FustTransaction, TransactionStatus } from 'src/types/transaction'

export type IssuanceTransactionDetailsDialogProps = FlowDialogProps & {
  transaction: FustTransaction
}
export const IssuanceTransactionDetailDialog: FC<
  IssuanceTransactionDetailsDialogProps
> = ({ transaction, ...rest }) => {
  const { t } = useTranslation()
  const generalDataGridLayout = [4, 8]
  const fustAmountDataGridLayout = [3, 3, 3, 3]

  const generalInformationRows: Row[] = [
    {
      values: [],
    },
    {
      values: [t('countTable.headings.status'), TransactionStatus[transaction.status]],
    },
    {
      values: [
        t('transactions.header.customer'),
        `${transaction.klantnummer} - ${transaction.klantNaam}`,
      ],
    },
    {
      values: [t('location'), transaction.klantLocatie],
    },
    {
      values: [t('countTable.headings.transId'), transaction.id],
    },
    {
      values: [
        t('countTable.headings.createdOn'),
        format(new Date(transaction.createdOn), 'dd/MM HH:mm'),
      ],
    },
    {
      values: [t('countTable.headings.transNumb'), transaction.vervoerderNummer],
    },
    {
      values: [t('countTable.headings.notes'), transaction.externeNotitie],
    },
  ]

  const fustAmounts: Row[] = transaction.fustAantallen.map((fust: FustAmount) => ({
    values: [fust.fustCode, fust.stapelwagens, fust.stapels, fust.stuks],
  }))

  const fustHeaders: Row[] = [
    {
      header: true,
      values: [
        FustAmountColumnLabel.fustCode,
        FustAmountColumnLabel.stapelwagens,
        FustAmountColumnLabel.stapels,
        FustAmountColumnLabel.stuks,
      ],
    },
  ]

  return (
    <FlowDialog fullScreen {...rest}>
      <FlowDialogTitle>{t('dialog.transactionDetails')}</FlowDialogTitle>
      <FlowDialogContent>
        <FlowView>
          <FlowViewContent>
            <Stack direction={'column'} gap={2}>
              <RfhTypography variant={'subtitle1'}>
                {t('dialog.transactionDetails')}
              </RfhTypography>
              <DataGrid
                label='TransactionDetails DataGrid'
                layout={generalDataGridLayout}
                rows={generalInformationRows}
              />
              <Box
                sx={{
                  '& .MuiGrid-root.MuiGrid-item[role=cell]': {
                    backgroundColor: RfhColors.white,
                  },
                }}
              >
                <DataGrid
                  headColor={RfhColors.leafGreen}
                  label={t('transactions.generalLowCase')}
                  layout={fustAmountDataGridLayout}
                  rows={fustHeaders.concat(fustAmounts)}
                />
              </Box>
            </Stack>
          </FlowViewContent>
        </FlowView>
      </FlowDialogContent>
    </FlowDialog>
  )
}
