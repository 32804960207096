import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { Layout } from 'src/components/Layouts'
import { useBranch } from 'src/hooks/useBranch'
import nlTranslations from 'src/i18n/nl/translations.json'

export const flows = [
  {
    label: nlTranslations.intake.general,
    route: '/inname',
  },
  {
    label: nlTranslations.issuance.general,
    route: '/uitgifte',
  },
  {
    label: nlTranslations.inventoryManagement.general,
    route: '/voorraadtelling',
  },
  {
    label: nlTranslations.rebook.general,
    route: '/omboeken',
  },
  {
    label: nlTranslations.interDepotTransport.flowLabel,
    route: '/idt',
  },
]

export const FlowSelection: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { branch } = useBranch()

  useEffect(() => {
    if (!branch) {
      navigate('/vestiging')
    }
  }, [])

  const layoutTitle =
    branch === undefined ? `${t('appName')}` : `${t('appBar.location')} ${branch}`

  return (
    <Layout title={t('appName')}>
      <Box px={2}>
        <RfhTypography variant='h5' mb={1.5} mt={'10px'}>
          {layoutTitle}
        </RfhTypography>

        {flows.map(flow => (
          <Box key={flow.label}>
            <Button variant='text--link-icon' onClick={() => navigate(flow.route)}>
              {flow.label}
            </Button>
          </Box>
        ))}
      </Box>
    </Layout>
  )
}

export { FlowSelection as default }
