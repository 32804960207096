import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { CountTable, CountTableProps } from 'src/components/CountTable'
import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { TransportWarningDialog } from 'src/components/dialogs/TransportWarningDialog'
import {
  interDepotTransportMachineModel,
  interDepotTransportService,
} from 'src/machines/interDepotTransportMachine'

import { TransactionsHeader } from '../TransactionsHeader'

const { ASSIGN_TRANSACTION, CONTINUE, CANCEL } = interDepotTransportMachineModel.events

export const Composing: FC<{ service: typeof interDepotTransportService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const [canContinue, setCanContinue] = useState<boolean>(true)

  const handleRowAdd: CountTableProps['handleRowAdd'] = row => {
    send(ASSIGN_TRANSACTION([...state.context.fustAmount, row]))
  }
  const handleRowRemove: CountTableProps['handleRowRemove'] = rowIndex => {
    send(ASSIGN_TRANSACTION(state.context.fustAmount.filter((_, i) => i !== rowIndex)))
  }
  const handleRowEdit: CountTableProps['handleRowEdit'] = (row, rowIndex) => {
    send(
      ASSIGN_TRANSACTION(
        state.context.fustAmount.map((fustAantal, i) =>
          i === rowIndex ? row : fustAantal
        )
      )
    )
  }

  return (
    <>
      <TransportWarningDialog
        isOpen={state.matches('showing_warning_dialog')}
        onSubmit={() => {
          send(CONTINUE())
        }}
        onCancel={() => {
          send(CANCEL())
        }}
        message={state.context.warningMessage || ''}
      />

      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <TransactionsHeader
            items={[
              {
                label: 'departureLocation',
                value: state.context.selectedDepartureLocation,
              },
            ]}
          />
          <RfhTypography variant='subtitle1' p={2}>
            {t('interDepotTransport.composing')}
          </RfhTypography>
          <CountTable
            rows={state.context.fustAmount}
            columns={['fustCode', 'itemState', 'stapelwagens', 'stapels', 'stuks']}
            handleRowAdd={handleRowAdd}
            handleRowEdit={handleRowEdit}
            handleRowRemove={handleRowRemove}
            setCanContinue={setCanContinue}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            disabled={!(state.can('CONTINUE') && canContinue)}
            variant={'contained'}
            onClick={() => send(CONTINUE())}
          >
            {t('button.confirm')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
