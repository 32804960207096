import { AxiosResponse } from 'axios'

import { Depot } from 'src/types/depot'
import { api } from 'src/utils/api'

export const queryDepots = (vestiging: string): Promise<AxiosResponse<Depot[]>> => {
  const endpoint = `/vestiging/${vestiging}/depots`

  return api.get(endpoint)
}
