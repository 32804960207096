import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { BaseDialog } from 'src/components/dialogs/BaseDialog'
import abortedScanIllustration from 'src/illustrations/RFH_illustratie_niet-opgeslagen.svg'

export interface TransportWarningDialogProps {
  isOpen: boolean
  message: string
  onSubmit: () => void
  onCancel: () => void
}

export const TransportWarningDialog: FC<TransportWarningDialogProps> = ({
  isOpen,
  message,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  return (
    <BaseDialog isOpen={isOpen} setIsOpen={onCancel} onSubmit={onSubmit}>
      <Stack
        gap={2.5}
        direction={'column'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <img
          src={abortedScanIllustration}
          alt='Aborted scan illustration'
          width={152}
        />
        <RfhTypography variant={'text-micro-bold'}>
          {t('dialog.attention')}
        </RfhTypography>
        <RfhTypography variant={'body1'}>{message}</RfhTypography>
        <RfhTypography variant={'body1'}>{t('dialog.cancelInput')}</RfhTypography>
        <Button fullWidth variant={'contained'} color={'primary'} onClick={onSubmit}>
          {t('button.continue')}
        </Button>
        <Button fullWidth variant={'outlined'} color={'primary'} onClick={onCancel}>
          {t('button.cancel')}
        </Button>
      </Stack>
    </BaseDialog>
  )
}
