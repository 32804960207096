import { render } from 'react-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'

const renderRoot = () => render(<App />, document.getElementById('root'))

if (process.env.NODE_ENV === 'development') {
  const loadXstateInspect = async () => {
    /*
     * create .env.local in your root:
     * to turn uncomment REACT_APP_XSTATE_INSPECT
     * to turn off #REACT_APP_XSTATE_INSPECT
     *
     * do not forget to restart "npm start" after changing env value
     * */
    if (process.env.REACT_APP_XSTATE_INSPECT !== undefined) {
      const { inspect } = await import('@xstate/inspect')
      inspect({
        iframe: false,
      })
    }
  }
  const loadServer = async () => {
    const { makeServer } = await import('./mirage/server')
    makeServer({ environment: 'development' })
    renderRoot()
  }
  loadServer()
  loadXstateInspect()
} else {
  Sentry.init({
    dsn: 'https://9c5ff410ae284d30a2b22360a9fc8088@o902103.ingest.sentry.io/6643671',
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
  renderRoot()
}
