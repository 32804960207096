import { FC } from 'react'

import Box, { BoxProps } from '@mui/material/Box'
import LoadingPage from '@rfh/ui/components/LoadingPage/LoadingPage'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { FlowViewActions } from 'src/components/FlowView/FlowViewActions'
import { FlowViewContent } from 'src/components/FlowView/FlowViewContent'
import nlTranslations from 'src/i18n/nl/translations.json'

export type FlowViewProps = BoxProps & {
  children: JSX.Element[] | JSX.Element
  loading?: boolean
  noResult?: boolean
  loadingContent?: JSX.Element
  noResultContent?: JSX.Element
}

export const FlowView: FC<FlowViewProps> = ({
  children,
  loading,
  loadingContent = <LoadingPage label={nlTranslations.loadingContent} />,
  noResultContent = (
    <RfhTypography variant={'title-display-h4-bold'}>
      {nlTranslations.errorMessage.noResults}
    </RfhTypography>
  ),
  noResult,
  ...rest
}) => {
  const actualViewContent = noResult ? (
    noResultContent
  ) : (
    <>
      {Array.isArray(children) ? (
        <>
          {children.find(item => item.type === FlowViewContent)}
          {children.find(item => item.type === FlowViewActions)}
        </>
      ) : (
        children
      )}
    </>
  )

  return (
    <Box
      px={2}
      pt={2}
      flex={1}
      minHeight={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      {...rest}
    >
      {loading ? loadingContent : actualViewContent}
    </Box>
  )
}
