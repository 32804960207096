import { FC } from 'react'

import Box, { BoxProps } from '@mui/material/Box'

export const FlowViewContent: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box flexGrow={1} {...rest}>
      {children}
    </Box>
  )
}
