import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { useActor, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { Count } from 'src/components/IntakeFlow/Count'
import { ValidatedCount } from 'src/components/IntakeFlow/ValidatedCount'
import { ReturnLocation } from 'src/components/IntakeFlow/intakeMobile/ReturnLocation'
import { Transactions } from 'src/components/IntakeFlow/intakeMobile/Transactions'
import { Layout } from 'src/components/Layouts'
import { TransactionsHeaderItem } from 'src/components/TransactionsHeader'
import {
  intakeMobileTransactionsMachineModel,
  intakeMobileTransactionsService,
} from 'src/machines/intakeMobileTransactionsMachine'
import { IntakeCreateFlowContext } from 'src/views/IntakeFlow'

const { RESET_ERROR } = intakeMobileTransactionsMachineModel.events

export const MobileTransactionsFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service: mainFlowService } = useContext(IntakeCreateFlowContext)

  const childTransactionsService = mainFlowService.state
    ? (mainFlowService.state.children
        .intakeMobileTransactionsMachine as typeof intakeMobileTransactionsService)
    : intakeMobileTransactionsService

  const [state, send] = useActor(childTransactionsService)

  const viewingListOfTransactions = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('viewing_list_of_transactions') ||
      matches('viewing_transaction_details') ||
      matches('loading_transactions') ||
      matches('updating_status') ||
      matches('resetting_status') ||
      matches('handling_fust_pass_scan') ||
      matches('handling_unannounced_fust_pass_scan') ||
      matches('asking_customer_number')
  )

  const confirmingWithFustPassState = useSelector(
    childTransactionsService,
    ({ matches }) => matches('confirming_with_fust_pass')
  )

  const viewingCount = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('count_transaction') ||
      matches('validating_unannounced_transaction') ||
      matches('validating_transaction')
  )

  const viewingRetourLocation = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('return_location') ||
      matches('showing_success_dialog') ||
      matches('handeling_location_update')
  )

  const viewingReviewTransaction = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('reviewing_transaction') ||
      matches('confirming_with_fust_pass') ||
      matches('handling_transactions_submission') ||
      matches('handling_unannounced_transactions_submission')
  )

  const error = useSelector(
    childTransactionsService,
    currentState => currentState.context.error
  )

  const transactionsMobileHeaderItems: TransactionsHeaderItem[] = [
    {
      label: 'workArea',
      value: state.context.selectedWorkArea?.naam,
    },
    {
      label: 'transporter',
      value: `${state.context.scannedFustPassContext?.scannedFustPass?.klant.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
    },
    state.context.scannedFustPassContext?.additionalCustomerNumber
      ? {
          label: 'customer',
          value: `${state.context.scannedFustPassContext.additionalCustomerNumber} - ${state.context.scannedFustPassContext.additionalCustomerName}`,
        }
      : {
          label: 'customer',
          value: state.context.selectedTransaction
            ? `${state.context.selectedTransaction.klantnummer} - ${state.context.selectedTransaction.klantNaam}`
            : `${state.context.scannedFustPassContext?.scannedFustPass?.klant.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
        },
  ]

  return (
    <Layout title={t('intake.mobile')}>
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />

      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        {viewingListOfTransactions && (
          <Transactions service={childTransactionsService} />
        )}
        {viewingCount && (
          <Count
            service={childTransactionsService}
            transactionsHeaderItems={transactionsMobileHeaderItems}
          />
        )}
        {viewingReviewTransaction && (
          <ValidatedCount
            service={childTransactionsService}
            confirmingWithFustPassState={confirmingWithFustPassState}
            transactionsHeaderItems={transactionsMobileHeaderItems}
          />
        )}
        {viewingRetourLocation && <ReturnLocation service={childTransactionsService} />}
      </Box>
    </Layout>
  )
}
