import { FC, forwardRef, ReactElement, Ref } from 'react'

import Slide, { SlideProps } from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

export const SlideUpTransition: FC<SlideProps> = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement
    },
    ref: Ref<unknown>
  ) => <Slide direction={'up'} ref={ref} {...props} timeout={380} />
)
