import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@rfh/ui/components/Inputs/TextField'

import useWorkAreas from 'src/hooks/useWorkAreas'
import { WorkArea } from 'src/types/workArea'

export type ChooseDepotAutocompleteProps = Omit<
  AutocompleteProps<WorkArea, boolean, boolean, boolean>,
  'options' | 'renderInput' | 'onLoadedData' | 'loading' | 'loadingText'
> & {
  onLoadedData?: (workAreas: WorkArea[]) => void
}

export const WorkAreasAutocomplete: FC<ChooseDepotAutocompleteProps> = ({
  value,
  onChange,
  onLoadedData,
  ...rest
}) => {
  const { t } = useTranslation()
  const { status, data } = useWorkAreas()

  useEffect(() => {
    if (data?.length) {
      onLoadedData && onLoadedData(data)
    }
  }, [data])

  return (
    <Autocomplete
      options={data?.length ? data : []}
      getOptionLabel={option => option.naam}
      value={value}
      loading={status === 'loading'}
      loadingText={t('loading')}
      onChange={onChange}
      autoSelect
      renderInput={params => (
        <TextField placeholder={t('inputs.workArea')} {...params} autoFocus fullWidth />
      )}
      {...rest}
    />
  )
}
