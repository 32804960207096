import { produce } from 'immer'

import { useMemo } from 'react'

import { handleChange } from 'src/components/SplitOrEditTable'
import { FustAmount } from 'src/types/fust'
import { calculateRemainderFustAmount } from 'src/utils'

export type UseDivideTransactionProps = {
  handleSplitChangeHandler: (fustAmount: FustAmount[]) => void
  initialFustAmount: FustAmount[]
  updatedFustAmount: FustAmount[]
}

export type UseDivideTransactionReturn = {
  handleSplitChange: handleChange
  remainderFustAmount: FustAmount[]
}
export const useDivideTransaction = ({
  handleSplitChangeHandler,
  initialFustAmount,
  updatedFustAmount,
}: UseDivideTransactionProps): UseDivideTransactionReturn => {
  const handleSplitChange: handleChange = (key, amount) => event => {
    const fustAmount = produce(updatedFustAmount, draft => {
      const index = draft.findIndex(divAmount => divAmount.fustCode === amount.fustCode)

      /* istanbul ignore next */ if (index !== -1) {
        // eslint-disable-next-line
        // @ts-ignore
        draft[index][key] = Number(event.target.value)
      }
    })

    handleSplitChangeHandler(fustAmount)
  }

  const memoizedInitialFustAmount = useMemo(() => initialFustAmount, [])

  const remainderFustAmount = calculateRemainderFustAmount(
    memoizedInitialFustAmount,
    updatedFustAmount
  )
  return {
    handleSplitChange,
    remainderFustAmount,
  }
}
