import { FC } from 'react'

import { useActor, useSelector } from '@xstate/react'

import { DockTransactionsDefaultViewProps } from 'src/components/IssuanceFlow/dockTransactions/DockTransactionsFlowStart'
import { ReviewSingleTransactionView } from 'src/components/IssuanceFlow/views/ReviewSingleTransactionView'
import { issuanceDockTransactionsMachineModel } from 'src/machines/issuanceDockTransactionsMachine'

export type ReviewSingleTransactionProps = DockTransactionsDefaultViewProps

const {
  SPLIT_TRANSACTION,
  EDIT_TRANSACTION,
  HANDLE_TRANSACTIONS,
  ASSIGN_UPDATED_FUST_AMOUNT,
} = issuanceDockTransactionsMachineModel.events

export const ReviewSingleTransaction: FC<ReviewSingleTransactionProps> = ({
  service,
  children,
}) => {
  const [state, send] = useActor(service)

  const dataGridService = useSelector(
    service,
    currentState => currentState.context.transactionsDataGridService
  )

  const selectedTransactions = useSelector(
    dataGridService,
    currentState => currentState.context.selectedTransactions
  )

  const isDividable = useSelector(service, ({ context: { transactionMode } }) => {
    return !transactionMode || transactionMode === 'split'
  })
  const isEditable = useSelector(service, ({ context: { transactionMode } }) => {
    return !transactionMode || transactionMode === 'edit'
  })

  return (
    <ReviewSingleTransactionView
      loading={state.hasTag('loading')}
      isDividable={isDividable}
      isEditable={isEditable}
      hasTotals={true}
      onEditTransactionHandler={currentTransaction => {
        send(EDIT_TRANSACTION(currentTransaction))
      }}
      onSplitTransactionHandler={currentTransaction => {
        send(SPLIT_TRANSACTION(currentTransaction))
        send(ASSIGN_UPDATED_FUST_AMOUNT(currentTransaction.fustAantallen))
      }}
      selectedTransactions={selectedTransactions}
      submissionClickHandler={() => {
        send(HANDLE_TRANSACTIONS())
      }}
    >
      {children}
    </ReviewSingleTransactionView>
  )
}
