import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useActor, useSelector } from '@xstate/react'

import {
  MultiSelectableDataGrid,
  MultiSelectableDataGridProps,
} from 'src/components/MultiSelectableDataGrid'
import { IssuanceTransactionDetailDialog } from 'src/components/dialogs/IssuanceTransactionDetailDialog'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'
import {
  issuanceStoreTransactionsMachineModel,
  issuanceStoreTransactionsService,
} from 'src/machines/issuanceStoreTransactionsMachine'
import {
  FustTransaction,
  TransactionStatus,
  TransactionType,
} from 'src/types/transaction'
import { getDataGridTransactionsRows } from 'src/utils/transactionsDataGrid'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export type IssuanceTransactionsDataGridProps = {
  service:
    | typeof issuanceDirectTransactionsService
    | typeof issuanceStoreTransactionsService
  transactionType: TransactionType
  layout: number[]
  columns: string[]
  isDetailDialogOpen: boolean
}
export const IssuanceTransactionsDataGrid: FC<IssuanceTransactionsDataGridProps> = ({
  service,
  transactionType,
  layout,
  columns,
  isDetailDialogOpen,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)
  const [mainFlowState] = useActor(mainFlowService)

  const fustPassNumber =
    mainFlowState?.context?.scannedFustPassContext?.scannedFustPass?.fustpasNummer

  const updating = useSelector(service, ({ matches }) =>
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    matches('updating_transaction_status')
  )

  const {
    ASSIGN_SELECTED_TRANSACTIONS,
    ASSIGN_SELECTED_TRANSACTION,
    CLOSE_TRANSACTION_DETAIL_VIEW,
    ASSIGN_FUST_AMOUNT,
    UPDATE_TRANSACTION_STATUS,
  } =
    transactionType === TransactionType.UitgifteWinkel
      ? /* istanbul ignore next */ issuanceStoreTransactionsMachineModel.events
      : issuanceDirectTransactionsMachineModel.events

  const onTransactionItemChecked: MultiSelectableDataGridProps['onItemChecked'] = (
    selectedRows,
    currentRow,
    selected
  ) => {
    const selectedTransactions = state.context.listOfTransactions.filter(
      (transaction: FustTransaction) =>
        selectedRows.find(row => row.id === transaction.id)
    )
    send(ASSIGN_SELECTED_TRANSACTIONS(selectedTransactions))
    send(
      ASSIGN_FUST_AMOUNT(
        selectedTransactions.flatMap(transaction => transaction.fustAantallen)
      )
    )
    send(
      UPDATE_TRANSACTION_STATUS(String(currentRow.id), {
        FustpasNummer: fustPassNumber ? String(fustPassNumber) : null,
        NewStatus: selected
          ? TransactionStatus['In behandeling']
          : TransactionStatus.Nieuw,
      })
    )
  }

  const rows = getDataGridTransactionsRows({
    listOfTransactionsState: state.context.listOfTransactions,
    onDetailClickHandler: transaction => {
      send(ASSIGN_SELECTED_TRANSACTION(transaction))
    },
    transactionType,
  })

  return (
    <>
      {state.context.selectedTransaction && (
        <IssuanceTransactionDetailDialog
          transaction={state.context.selectedTransaction}
          open={isDetailDialogOpen}
          handleClose={() => {
            send(CLOSE_TRANSACTION_DETAIL_VIEW())
          }}
          onClose={() => {
            send(CLOSE_TRANSACTION_DETAIL_VIEW())
          }}
        />
      )}
      <MultiSelectableDataGrid
        updating={updating}
        onItemChecked={onTransactionItemChecked}
        rows={rows}
        layout={layout}
        label={t('issuance.storeTransactions')}
        defaultHeaderRow={{
          values: columns,
          header: true,
        }}
        listOfTransactions={state.context.listOfTransactions}
      />
    </>
  )
}
