import { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  FustAmountDataGrid,
  FustAmountDataGridProps,
} from 'src/components/IssuanceFlow/FustAmountDataGrid'
import { FustAmount } from 'src/types/fust'
import { FustTransaction } from 'src/types/transaction'

export type ReviewMultipleTransactionViewProps = {
  selectedTransactions: FustTransaction[]
  loading?: boolean
  submissionClickHandler: MouseEventHandler
  title: string
} & Partial<
  Pick<
    FustAmountDataGridProps,
    | 'isEditable'
    | 'isDividable'
    | 'onSplitTransactionHandler'
    | 'onEditTransactionHandler'
    | 'hasTotals'
  >
>

export const ReviewMultipleTransactionView: FC<ReviewMultipleTransactionViewProps> = ({
  onSplitTransactionHandler,
  onEditTransactionHandler,
  selectedTransactions,
  loading,
  submissionClickHandler,
  title,
  hasTotals,
  isDividable,
  isEditable,
  children,
}) => {
  const { t } = useTranslation()
  const cumulativeFustAmount: FustAmount[] = ([] as FustAmount[]).concat(
    ...selectedTransactions.map(
      (transaction: FustTransaction) => transaction.fustAantallen
    )
  )

  return (
    <FlowView loading={loading} p={0}>
      <FlowViewContent sx={{ height: 'calc(100vh - 180px)', overflowY: 'scroll' }}>
        {children}
        <FustAmountDataGrid
          fustAmount={cumulativeFustAmount}
          title={title}
          isDividable={false}
          hasTotals={hasTotals}
        />
        {selectedTransactions.map(transaction => (
          <FustAmountDataGrid
            hasTotals={hasTotals}
            isEditable={isEditable}
            isDividable={isDividable}
            key={transaction.id}
            onSplitTransactionHandler={onSplitTransactionHandler}
            onEditTransactionHandler={onEditTransactionHandler}
            transaction={transaction}
            fustAmount={transaction.fustAantallen}
            title={`${transaction.klantnummer} - ${transaction.klantNaam}`}
          />
        ))}
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button variant={'contained'} onClick={submissionClickHandler}>
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
