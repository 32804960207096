import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { queryCustomer } from 'src/services/customerService'

export const useCustomer = (
  customerNumber: number,
  options: UseQueryOptions
): UseQueryResult => {
  return useQuery({
    queryFn: () => queryCustomer(customerNumber),
    queryKey: ['customerInformation'],
    ...options,
  })
}
