import { AxiosResponse } from 'axios'

import { Authority } from 'src/types/authority'
import { api } from 'src/utils/api'

export const queryAuthorities = (
  customerNumber: number
): Promise<AxiosResponse<Authority[]>> => {
  const endpoint = `/ketenverband/${customerNumber}`

  return api.get(endpoint)
}
