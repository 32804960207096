import { CustomDetector } from 'i18next-browser-languagedetector'

export const customLanguageDetector: CustomDetector = {
  name: 'customLanguageDetector',
  lookup: _options => {
    const start = 0
    const end = 2
    return navigator.language.substring(start, end)
  },
  cacheUserLanguage: (lng, _options) => localStorage.setItem('i18nextLng', lng),
}
