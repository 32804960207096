import { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useActor, useSelector } from '@xstate/react'

import { PreInformation } from 'src/components/IntakeFlow/PreInformation'
import { Layout } from 'src/components/Layouts'
import { ScanFustPassFlowDialogs } from 'src/components/dialogs/ScanFustPassFlowDialogs'
import { intakeFlowMachineModel } from 'src/machines/intakeFlowMachine'
import { TransactionType } from 'src/types/transaction'
import { IntakeCreateFlowContext } from 'src/views/IntakeFlow'

const { ASSIGN_SCANNED_FUST_PASS_CONTEXT, CLOSE_FUST_PASS_SCAN_DIALOGS } =
  intakeFlowMachineModel.events

export const IntakeFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(IntakeCreateFlowContext)

  const [state, send] = useActor(service)

  const navigate = useNavigate()
  const handlingFustPassScanState = useSelector(service, currentState =>
    currentState.matches('handling_fust_pass_scan')
  )
  const intakeFixedTransactionState = useSelector(service, currentState =>
    currentState.matches('handling_intake_fixed_transactions')
  )

  const intakeMobileTransactionState = useSelector(service, currentState =>
    currentState.matches('handling_intake_mobile_transactions')
  )

  useEffect(() => {
    if (intakeFixedTransactionState) {
      navigate('/inname/vast')
    } else if (intakeMobileTransactionState) {
      navigate('/inname/mobiel')
    }
  }, [service.state])

  return (
    <Layout title={t('intake.general')}>
      <ScanFustPassFlowDialogs
        onCompleted={scanContext => {
          service.send(ASSIGN_SCANNED_FUST_PASS_CONTEXT(scanContext))
        }}
        startProcess={handlingFustPassScanState}
        handleClose={() => {
          send(CLOSE_FUST_PASS_SCAN_DIALOGS())
        }}
        hasAuthorityApi={
          state.context.selectedTransactionType === TransactionType.InnameVast
        }
      />
      <PreInformation />
    </Layout>
  )
}
