import { useQuery, UseQueryResult } from 'react-query'

import { queryDepots } from 'src/services/depotService'
import { Branch } from 'src/types/branch'
import { Depot } from 'src/types/depot'

const getDepots = async () => {
  const branch =
    (localStorage.getItem('mfb-selected-branch') as Branch) || Branch.Aalsmeer
  const { data } = await queryDepots(branch)
  return data
}

export default function useDepots(): UseQueryResult<Depot[]> {
  return useQuery('depots', getDepots)
}
