import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import EditIcon from '@rfh/ui/shared/floriday-icons/icons/EditIcon'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { RoundIconButton } from 'src/components/RoundIconButton'
import {
  TransactionsHeader,
  TransactionsHeaderItem,
} from 'src/components/TransactionsHeader'
import { ValidatedCountTable } from 'src/components/ValidatedCountTable'
import { ConfirmFustPassNumberDialog } from 'src/components/dialogs/ConfirmFustPassNumberDialog'
import { intakeFixedTransactionsService } from 'src/machines/intakeFixedTransactionsMachine'
import {
  intakeMobileTransactionsMachineModel,
  intakeMobileTransactionsService,
} from 'src/machines/intakeMobileTransactionsMachine'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

type ValidatedCountProps = {
  transactionsHeaderItems: TransactionsHeaderItem[]
  service:
    | typeof intakeMobileTransactionsService
    | typeof intakeFixedTransactionsService
  confirmingWithFustPassState: boolean
}

const { EDIT_TRANSACTION, CONTINUE, CLOSE_FUST_PASS_NUMBER_DIALOG } =
  intakeMobileTransactionsMachineModel.events

export const ValidatedCount: FC<ValidatedCountProps> = ({
  service,
  confirmingWithFustPassState,
  transactionsHeaderItems,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const fustAmount = useSelector(
    service,
    currentState => currentState.context.fustAmount
  )

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction
  )

  const fustPassNumber = useSelector(
    service,
    currentState =>
      currentState.context.scannedFustPassContext?.scannedFustPass?.fustpasNummer
  )
  const sortedFustAmount = [...fustAmount].sort((a, b) => a.fustCode - b.fustCode)
  return (
    <>
      <ConfirmFustPassNumberDialog
        isOpen={confirmingWithFustPassState}
        handleClose={
          /* istanbul ignore next */ () => send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }
        handleSubmit={/* istanbul ignore next */ () => send(CONTINUE())}
        fustPassNumberToConfirm={fustPassNumber!}
      />
      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <TransactionsHeader items={transactionsHeaderItems} />

          <Box
            p={2}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <RfhTypography variant={'subheader'}>
              {t('intake.validateCount')}
            </RfhTypography>
            <RoundIconButton
              variant='outlined'
              icon={<EditIcon />}
              label={t('button.edit')}
              onClick={() => send(EDIT_TRANSACTION())}
            />
          </Box>
          <ValidatedCountTable
            columns={['fustCode', 'stapelwagens', 'stapels', 'stuks', 'totaal']}
            rows={sortedFustAmount}
            isAnnouncedTransaction={!!selectedTransaction}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <RfhButton
            onClick={() => send(CONTINUE())}
            variant='block--contained'
            fullWidth
          >
            {t('button.handle')}
          </RfhButton>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
