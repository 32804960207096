const pipelineValues = {
  api: '#{ApiHost}#',
  tenant: '#{ADTenantId}#',
  clientId: '#{ADClientId}#',
}

const localValues = {
  api: 'https://localhost:3000/api',
  tenant: '7e58a9c5-ca4b-4ac7-b347-243188106279',
  clientId: '24a684b2-f573-4ebc-83f6-20e86942bc40',
}

type KeyType = keyof typeof pipelineValues

Object.keys(pipelineValues).forEach(key => {
  if (pipelineValues[key as KeyType].startsWith('#')) {
    pipelineValues[key as KeyType] = localValues[key as KeyType]
  }
})

export type AppConfig = {
  api: string
  adalConfig: {
    tenant: string
    clientId: string
    postLogoutRedirectUri: string
    redirectUri: string
    cacheLocation: string
    domain: string
    extraQueryParameter: string
    navigateToLoginRequestUrl: boolean
  }
}

export const Config: AppConfig = {
  api: pipelineValues.api,
  adalConfig: {
    tenant: pipelineValues.tenant,
    clientId: pipelineValues.clientId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    cacheLocation: 'sessionStorage',
    domain: 'royalfloraholland.com',
    extraQueryParameter: 'domain_hint=royalfloraholland.com',
    navigateToLoginRequestUrl: false,
  },
}
