import { AxiosResponse } from 'axios'

import { WorkArea } from 'src/types/workArea'
import { api } from 'src/utils/api'

export const queryWorkAreas = (
  vestiging: string
): Promise<AxiosResponse<WorkArea[]>> => {
  const endpoint = `/vestiging/${vestiging}/werkgebieden`

  return api.get(endpoint)
}
