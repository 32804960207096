import { useState } from 'react'

import { queryAuthorities } from 'src/services/authorityService'
import { queryCard } from 'src/services/cardService'
import { Authority } from 'src/types/authority'
import { Card } from 'src/types/card'
import { UserType } from 'src/types/customer'

export type useCardReturntype = {
  loading: boolean
  card: Card | undefined
  apiError: boolean
  authorities: Authority[]
  getCard: (cardNumber: string, authorityApi: boolean) => Promise<void>
}

export const useCard = (): useCardReturntype => {
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<boolean>(false)
  const [card, setCard] = useState<Card>()
  const [authorities, setAuthorities] = useState<Authority[]>([])

  const getCard = async (cardNumber: string, authorityApi: boolean) => {
    setLoading(true)
    try {
      const { data: cardResponseData } = await queryCard(cardNumber)

      const customerNumber = cardResponseData.klant.nummer
      const customerType = cardResponseData.klant.type

      if (authorityApi && customerType !== UserType.Employee) {
        const { data: authorityResponseData } = await queryAuthorities(customerNumber)
        setAuthorities([
          {
            id: customerNumber,
            name: cardResponseData.klant.naam,
            role: String(customerType),
          },
          ...authorityResponseData,
        ])
      }

      setCard(cardResponseData)
    } catch (error) {
      setApiError(true)
    } finally {
      setLoading(false)
    }
  }

  return { loading, apiError, card, authorities, getCard }
}
