import { interpret } from 'xstate'

import { createContext, FC } from 'react'
import { Outlet } from 'react-router-dom'

import { useInterpret } from '@xstate/react'

import { intakeFlowMachine } from 'src/machines/intakeFlowMachine'

export const intakeFlowService = interpret(intakeFlowMachine)

export const IntakeCreateFlowContext = createContext<{
  service: typeof intakeFlowService
}>({
  service: intakeFlowService,
})

export type IssuanceFlowProps = {
  service?: typeof intakeFlowService
}

export const IntakeFlow: FC<IssuanceFlowProps> = ({ service }) => {
  const defaultIntakeFlowService = useInterpret(intakeFlowMachine, {
    devTools: process.env.REACT_APP_XSTATE_INSPECT !== undefined,
  })

  return (
    <IntakeCreateFlowContext.Provider
      value={{ service: service || defaultIntakeFlowService }}
    >
      <Outlet />
    </IntakeCreateFlowContext.Provider>
  )
}
