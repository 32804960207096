import { ChangeEventHandler, Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@rfh/ui/components/Inputs/TextField'
import LoadingIcon from '@rfh/ui/components/LoadingPage/LoadingIcon'

import { OrangeScanIcon } from 'src/components/common.styles'
import useDepots from 'src/hooks/useDepots'
import nlTranslations from 'src/i18n/nl/translations.json'
import { Depot } from 'src/types/depot'

export type DepotTextFieldProps = {
  value: string
  error: boolean
  placeholder?: string
  setError: Dispatch<SetStateAction<boolean>>
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const DepotTextField: FC<DepotTextFieldProps> = ({
  value,
  error,
  placeholder = nlTranslations.inputs.scanDepot,
  setError,
  onChange,
}) => {
  const { t } = useTranslation()
  const { status, data } = useDepots()
  const [inputValue, setInputValue] = useState<string>(value)
  const validDepot = t('inputs.validDepot')

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setError(false)
    setInputValue(event.target.value)
    if (data!.find((depot: Depot) => depot.code === event.target.value)) {
      onChange(event)
    } else {
      setError(true)
    }
  }

  return (
    <TextField
      autoComplete={'off'}
      error={error}
      helperText={error ? validDepot : null}
      onChange={handleChange}
      prefixTextOrIcon={status === 'loading' ? <LoadingIcon /> : <OrangeScanIcon />}
      autoFocus
      fullWidth
      placeholder={placeholder}
      value={inputValue}
      disabled={status === 'loading'}
    />
  )
}
