import { AxiosPromise } from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { Branch } from 'src/types/branch'
import { FustAmount } from 'src/types/fust'
import { FustCard } from 'src/types/fustCard'
import {
  createUnannouncedValidatedTransactionParams,
  FustTransaction,
  TransactionStatus,
  TransactionType,
  UnannouncedTransactionParams,
  TransportTransaction,
} from 'src/types/transaction'
import { WorkArea } from 'src/types/workArea'
import { api } from 'src/utils/api'

import { Card } from '../types/card'
import { Customer, UserType } from '../types/customer'

export type QueryFixedTransactionsParams = {
  Klantnummer: number | null
  Vestiging: string
  TransactieType: TransactionType
  Status: TransactionStatus[]
  VervoerdersNummer: number | null
}

export interface QueryMobileTransactionsParams {
  Klantnummer?: any
  VervoerdersNummer?: any
  TransactieType: number
  Vestiging: string
  Status: number[]
  WerkGebied?: any
}

export type QueryIssuanceStoreTransactionsParams = QueryMobileTransactionsParams
export type QueryIssuanceDirectTransactionsParams = QueryMobileTransactionsParams

export interface QueryTransactionItemParams {
  TransactieID: string
  Status: number[]
}

// TODO combine services in this file
export const queryFixedTransactions = (
  params: QueryFixedTransactionsParams
): AxiosPromise<FustTransaction[]> => api.post('/klantTransactie', params)

export const validateTransaction = (
  transactionId: string,
  customerId: number,
  body: FustTransaction
): AxiosPromise<FustAmount[]> =>
  api.post(`/klantTransactie/controleer/id/${transactionId}/${customerId}`, body)

export const validateUnannouncedTransaction = (
  customerId: number,
  body: UnannouncedTransactionParams
): AxiosPromise<FustAmount[]> => {
  const location = localStorage.getItem('mfb-selected-branch')
  return api.post(`/klantTransactie/controleer/${location}/${customerId}`, body)
}

export const queryMobileTransactions = (
  params: QueryMobileTransactionsParams
): AxiosPromise<FustTransaction[]> => {
  const workArea =
    localStorage.getItem('mfb-selected-branch') === params.WerkGebied
      ? null
      : params.WerkGebied
  return api.post('/klantTransactie', { ...params, WerkGebied: workArea })
}

export const createCustomerTransaction = (
  params: FustTransaction // TODO Check exact input
): AxiosPromise<FustTransaction> => api.post('/klantTransactie/aanmaken', params)

export const submitValidatedTransactionFustTotal = (
  customerNumber: number,
  params: FustTransaction
): AxiosPromise<void> => {
  return api.post(`/klantTransactie/wijzig/fustaantal/${customerNumber}`, params)
}

export const submitTransportTransactieFustTotal = (
  params: TransportTransaction
): AxiosPromise<void> => {
  return api.post('/transportTransactie/wijzig/fustaantal', params)
}

export const updateTransactionStatus = (
  txId: string,
  params: FustCard
): AxiosPromise<void> => {
  return api.post(`/klantTransactie/wijzig/status/${txId}`, params)
}

export const updateTransportTransactionStatus = (
  txId: string,
  params: FustCard
): AxiosPromise<void> => {
  return api.post(`/transportTransactie/wijzig/status/${txId}`, params)
}

export const createUnannouncedValidatedTransaction = (
  params: createUnannouncedValidatedTransactionParams
): AxiosPromise<FustTransaction> => {
  const id = uuidv4()
  return api.post(`/klantTransactie/aanmaken/${id}`, params)
}

export const updateTransactionLocation = (params: FustTransaction): AxiosPromise =>
  api.post('/klantTransactie/wijzig/locatie', params)

export const updateTransportTransactionLocation = (
  params: TransportTransaction
): AxiosPromise => api.post('/transportTransactie/wijzig/locatie', params)

export const queryIssuanceStoreTransactions = (
  customer: Customer
): AxiosPromise<FustTransaction[]> => {
  const customerNumberKey =
    customer.type === UserType.Transporter ? 'VervoerdersNummer' : 'Klantnummer'

  const params = {
    Klantnummer: null,
    VervoerdersNummer: null,
    TransactieType: TransactionType.UitgifteWinkel,
    Vestiging: localStorage.getItem('mfb-selected-branch'),
    Status: [TransactionStatus.Nieuw, TransactionStatus['In behandeling']],
    ...{ [customerNumberKey]: customer.nummer },
  }

  return api.post('/klantTransactie', params)
}

export type QueryTransactionsParam = {
  Klantnummer: number | null
  VervoerdersNummer: number | null
  TransactieType: TransactionType
  Vestiging: string
  Status: TransactionStatus[]
}

export const queryTransactions = (
  transactionType: TransactionType,
  params: Partial<QueryTransactionsParam> = {}
): AxiosPromise<FustTransaction[]> => {
  return api.post('/klantTransactie', {
    Klantnummer: null,
    VervoerdersNummer: null,
    TransactieType: transactionType,
    Vestiging: localStorage.getItem('mfb-selected-branch'),
    Status: [TransactionStatus.Nieuw, TransactionStatus['In behandeling']],
    ...params,
  })
}

export const queryIssuanceDirectTransactions = (
  workArea?: WorkArea
): AxiosPromise<FustTransaction[]> => {
  const params: QueryIssuanceDirectTransactionsParams = {
    Klantnummer: null,
    VervoerdersNummer: null,
    TransactieType: TransactionType.UitgifteDirect,
    Vestiging: localStorage.getItem('mfb-selected-branch') || Branch.Aalsmeer,
    WerkGebied:
      localStorage.getItem('mfb-selected-branch') === workArea?.naam
        ? null
        : workArea?.naam,
    Status: [TransactionStatus.Nieuw, TransactionStatus['In behandeling']],
  }
  return api.post('/klantTransactie', params)
}

export const queryTransactionItem = (
  params?: QueryTransactionItemParams
): AxiosPromise<[FustTransaction]> => {
  const overrideParams = {
    TransactieID: '',
    Status: [TransactionStatus.Nieuw],
    ...params,
  }
  return api.post('/klantTransactie', overrideParams)
}

export const batchHandleTransactions = (
  transactions: FustTransaction[],
  fustPassNumber: Card['fustpasNummer'],
  customerNumber: Customer['nummer'],
  transactionType: TransactionType,
  selectedDepotCode?: string
): Promise<void[]> => {
  const transactionsStatusBatch = transactions.map(async transaction => {
    await submitValidatedTransactionFustTotal(customerNumber, transaction)

    if (transactionType === TransactionType.UitgifteWinkel) {
      transaction.locatie = selectedDepotCode!
      await updateTransactionLocation(transaction)
    }

    await updateTransactionStatus(transaction.id, {
      FustpasNummer: fustPassNumber,
      NewStatus: TransactionStatus.Bevestigd,
    })
  })

  return Promise.all(transactionsStatusBatch)
}

export const batchHandleDirectTransactions = (
  transactions: FustTransaction[],
  fustPassNumber: Card['fustpasNummer'],
  customerNumber: Customer['nummer'],
  isTransactionEdited: boolean,
  selectedDepotCode?: string
): Promise<void[]> => {
  const submitTransactions = transactions.map(async transaction => {
    transaction.locatie = selectedDepotCode!
    await updateTransactionLocation(transaction)

    if (isTransactionEdited) {
      await submitValidatedTransactionFustTotal(customerNumber, transaction)
    }

    await updateTransactionStatus(transaction.id, {
      FustpasNummer: fustPassNumber,
      NewStatus: TransactionStatus.Bevestigd,
    })
  })

  return Promise.all(submitTransactions)
}

export const divideTransaction = (
  transactionId: FustTransaction['id'],
  fustAmount: FustTransaction['fustAantallen']
): AxiosPromise<void> =>
  api.post(`/klantTransactie/splits/${transactionId}`, fustAmount)

export const lateDivideTransaction = (
  transaction: FustTransaction,
  fustPassNumber: Card['fustpasNummer'],
  location: FustTransaction['locatie']
): AxiosPromise<void> =>
  api.post(`/klantTransactie/laatsplits/${transaction.id}`, {
    locatie: location,
    Fustaantals: transaction.fustAantallen,
    statusUpdate: {
      fustpasNummer: fustPassNumber,
      newStatus: TransactionStatus.Bevestigd,
    },
  })

export const createValidatedTransportTransaction = (
  uuid: string,
  params: TransportTransaction
): AxiosPromise<TransportTransaction> => {
  return api.post(`/transportTransactie/aanmaken/${uuid}`, params)
}

export const handleTransportTransaction = async (
  transaction: TransportTransaction
): Promise<void> => {
  await updateTransportTransactionLocation(transaction)

  await submitTransportTransactieFustTotal(transaction)

  await updateTransportTransactionStatus(transaction.id!, {
    NewStatus: TransactionStatus.Verwerkt,
  })
}

export const queryTransportTransactions = (): AxiosPromise<TransportTransaction[]> =>
  api.post('/transportTransactie', {
    naarLocatie: localStorage.getItem('mfb-selected-branch') || 'Aalsmeer',
    transactionType: TransactionType.Transport,
    status: [TransactionStatus['In behandeling']],
  })
