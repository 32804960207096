import { nl } from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next } from 'react-i18next'

import { customLanguageDetector } from './customLanguageDetector'
import nlTranslations from './nl'

export const defaultNamespace = 'translations'
export type AppNamespace = Record<'translations', any>

export const resources = {
  nl: {
    ...nlTranslations,
    dateFns: nl,
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customLanguageDetector)
i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    lng: 'nl-NL',
    load: 'languageOnly',
    keySeparator: '.',
    ns: [defaultNamespace] as (keyof AppNamespace)[],
    resources,
    detection: {
      caches: ['localStorage', 'customLanguageDetector'],
      order: ['localStorage', 'customLanguageDetector'],
    },
  })

export default i18n
