import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { IssuanceTransactionsDataGrid } from 'src/components/IssuanceFlow/IssuanceTransactionsDataGrid'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'
import { TransactionType } from 'src/types/transaction'

export type ListOfDirectTransactionsProps = {
  service: typeof issuanceDirectTransactionsService
}
export const ListOfDirectTransactions: FC<ListOfDirectTransactionsProps> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const { HANDLE_SELECTED_TRANSACTIONS, LOAD_TRANSACTIONS_LIST } =
    issuanceDirectTransactionsMachineModel.events

  useEffect(() => {
    send(LOAD_TRANSACTIONS_LIST())
  }, [])

  return (
    <FlowView p={0} loading={state.hasTag('loading')}>
      <FlowViewContent>
        <Stack direction={'column'} gap={2} pt={2} px={2}>
          <RfhTypography variant={'text-micro-bold'}>
            {t('transactions.selectMultiple')}
          </RfhTypography>
          <IssuanceTransactionsDataGrid
            service={service}
            transactionType={TransactionType.UitgifteDirect}
            layout={[1, 4, 4, 1.8, 1]}
            columns={[
              '',
              t('transactions.header.customer'),
              t('location'),
              t('countTable.headings.stw'),
              '',
            ]}
            isDetailDialogOpen={state.matches('viewing_transaction_details')}
          />
        </Stack>
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          onClick={() => send(HANDLE_SELECTED_TRANSACTIONS())}
          disabled={!state.can('HANDLE_SELECTED_TRANSACTIONS')}
        >
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
