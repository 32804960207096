/* istanbul ignore file */
import { runWithAdal } from 'react-adal'

import { authContext, DO_NOT_LOGIN } from './configs/adalConfig'

runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require('./indexApp.tsx')
  },
  DO_NOT_LOGIN
)
