import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { DepotTextField } from 'src/components/inputs/DepotTextField'
import {
  interDepotTransportMachineModel,
  interDepotTransportService,
} from 'src/machines/interDepotTransportMachine'

const { SELECT_DEPARTURE_LOCATION } = interDepotTransportMachineModel.events

export const DepartureLocation: FC<{ service: typeof interDepotTransportService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const [error, setError] = useState<boolean>(false)

  return (
    <FlowView>
      <FlowViewContent>
        <RfhTypography variant='subtitle1' mb={1}>
          {t('interDepotTransport.departureLocation')}
        </RfhTypography>
        <DepotTextField
          onChange={event => {
            send(SELECT_DEPARTURE_LOCATION(event.target.value))
          }}
          value={state?.context?.selectedDepartureLocation || ''}
          error={error}
          setError={setError}
        />
      </FlowViewContent>
      <FlowViewActions>
        <Button
          disabled={!state.can('CONTINUE') || error}
          variant={'contained'}
          onClick={() => send({ type: 'CONTINUE' })}
        >
          {t('button.continue')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
