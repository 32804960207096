import { FustAmount } from './fust'

// TODO merge different types
export interface CustomerTransaction extends Transaction {
  Locatie: string
  Klantnummer?: number
  KlantNaam: string
  KlantLocatie: string
}

export type Transaction = {
  Id: string
  TransactieType: TransactionType
  Datum: string
  VervoerderNaam: string
  VervoerderNummer?: number
  FustAantallen: FustAmount[]
  Status: TransactionStatus
  SubStatus: number
  ExterneNotitie: string
  CreatedOn: string
  UserName: string
}

export type FustTransaction = {
  locatie: string
  klantnummer: number
  klantNaam: string
  klantLocatie: string
  id: string
  transactieType: TransactionType
  datum: string
  vervoerderNaam: string
  vervoerderNummer: number
  fustAantallen: FustAmount[]
  status: TransactionStatus
  subStatus: TransactionStatus
  externeNotitie: string
  createdOn: string
}

export enum TransactionType {
  UitgifteDock = 1,
  UitgifteDirect = 2,
  UitgifteWinkel = 3,
  InnameMobiel = 4,
  InnameVast = 5,
  Transport = 9,
}

export enum TransactionStatus {
  Nieuw = 0,
  'In behandeling' = 30,
  Bevestigd = 31,
  Verwerkt = 35,
}

export type createUnannouncedValidatedTransactionParams = {
  id: null
  vervoerderNummer: number | string | null
  klantNaam: string
  datum: string
  klantnummer: string
  locatie: string
  status: TransactionStatus
  transactieType: TransactionType
  fustAantallen: FustAmount[]
}

export type UnannouncedTransactionParams = {
  klantNaam: null
  klantnummer: null
  locatie: null
  status: null
  transactieID: null
  transactieType: TransactionType
  vervoerderNummer: number | null
  datum: null
  fustAantallen: FustAmount[]
}

export type UnannouncedValidatedTransaction = {
  CreatedOn: string
  Datum: string
  FustAantallen: FustAmount[]
  Id: string
  KlantNaam: string
  Klantnummer: number
  Locatie: null
  Status: TransactionStatus
  SubStatus: number
  TransactieType: TransactionType
}

export type TransportTransaction = {
  vanLocatie?: string
  naarLocatie?: string
  bevestigItemState?: boolean
  id?: string | null
  transactieType: TransactionType
  datum: string
  fustAantallen: FustAmount[]
  status: TransactionStatus
  subStatus?: TransactionStatus
  createdOn?: string
}
