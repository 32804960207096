import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useActor } from '@xstate/react'

import { ReviewMultipleTransactionView } from 'src/components/IssuanceFlow/views/ReviewMultipleTransactionsView'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'

export type ReviewMultipleTransactionProps = {
  service: typeof issuanceDirectTransactionsService
  numberOfCustomers?: number
}

const { HANDLE_TRANSACTION, ASSIGN_SELECTED_TRANSACTION } =
  issuanceDirectTransactionsMachineModel.events

export const ReviewMultipleTransaction: FC<ReviewMultipleTransactionProps> = ({
  service,
  numberOfCustomers,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const selectedTransactions = state.context.selectedTransactions

  return (
    <ReviewMultipleTransactionView
      loading={state.hasTag('loading')}
      selectedTransactions={selectedTransactions}
      onSplitTransactionHandler={currentTransaction => {
        send(ASSIGN_SELECTED_TRANSACTION(currentTransaction))
      }}
      title={t('total')}
      submissionClickHandler={() => {
        send(HANDLE_TRANSACTION())
      }}
    >
      <TransactionsHeader items={[{ label: 'customers', value: numberOfCustomers }]} />
    </ReviewMultipleTransactionView>
  )
}
