import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { BranchCard } from 'src/components/BranchCard'
import { Layout } from 'src/components/Layouts'
import { useBranch } from 'src/hooks/useBranch'
import { Branch } from 'src/types/branch'

export const BranchSelection: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setBranch } = useBranch()

  const handleLocationSelection = (branchName: Branch) => {
    setBranch(branchName)
    navigate('/')
  }

  return (
    <Layout title={t('appName')}>
      <Box px={2}>
        <RfhTypography mt={1.5} mb={'10px'} variant={'subheader'}>
          {t('branchSelection')}
        </RfhTypography>
        {Object.values(Branch).map(branchName => (
          <BranchCard
            key={branchName}
            title={branchName}
            onClick={() => handleLocationSelection(branchName)}
          />
        ))}
      </Box>
    </Layout>
  )
}
