import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CallSplitIcon from '@mui/icons-material/CallSplit'
import Stack from '@mui/material/Stack'
import DataGrid from '@rfh/ui/components/DataGrid/DataGrid'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { EditIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { DataGridWrapper } from 'src/components/DataGridWrapper'
import { RoundIconButton } from 'src/components/RoundIconButton'
import { FustAmount } from 'src/types/fust'
import { FustTransaction } from 'src/types/transaction'
import { getGroupByFustCodeTransactionsRows } from 'src/utils'

export type FustAmountDataGridProps = {
  title: string
  transaction?: FustTransaction
  fustAmount: FustAmount[]
  isDividable?: boolean
  isEditable?: boolean
  hasTotals?: boolean
  onSplitTransactionHandler?: (currentTransaction: FustTransaction) => void
  onEditTransactionHandler?: (currentTransaction: FustTransaction) => void
}

export const FustAmountDataGrid: FC<FustAmountDataGridProps> = ({
  title,
  transaction,
  fustAmount,
  hasTotals = false,
  isDividable = true,
  isEditable = false,
  onSplitTransactionHandler,
  onEditTransactionHandler,
}) => {
  const { t } = useTranslation()
  const headings = hasTotals
    ? [
        t('countTable.headings.code'),
        t('countTable.headings.stw'),
        t('countTable.headings.stpl'),
        t('countTable.headings.stk'),
        t('countTable.headings.subTotal'),
      ]
    : [
        t('countTable.headings.code'),
        t('countTable.headings.stw'),
        t('countTable.headings.stpl'),
        t('countTable.headings.stk'),
      ]
  return (
    <Stack direction={'column'} gap={2}>
      <Stack direction={'row'} alignItems={'center'} pt={2} px={2}>
        <RfhTypography variant={'subtitle1'} flexGrow={1}>
          {title}
        </RfhTypography>
        <Stack direction={'row'} gap={1}>
          {isDividable && transaction && (
            <RoundIconButton
              variant='outlined'
              icon={<CallSplitIcon />}
              onClick={() => {
                onSplitTransactionHandler && onSplitTransactionHandler(transaction)
              }}
              label={t('countTable.divideRow')}
            />
          )}
          {isEditable && transaction && (
            <RoundIconButton
              variant='outlined'
              icon={<EditIcon />}
              onClick={() =>
                onEditTransactionHandler && onEditTransactionHandler(transaction)
              }
              label={t('countTable.editRow')}
            />
          )}
        </Stack>
      </Stack>
      <DataGridWrapper>
        <DataGrid
          headColor={RfhColors.leafGreen}
          rows={[
            {
              header: true,
              values: headings,
            },
            ...getGroupByFustCodeTransactionsRows(fustAmount, hasTotals),
          ]}
          label={'Review Selected Direct Transactions'} // TODO label niet generiek
        />
      </DataGridWrapper>
    </Stack>
  )
}
