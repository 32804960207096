import { FC } from 'react'

import Alert from '@mui/material/Alert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Snackbar from '@mui/material/Snackbar'

import { ErrorIcon } from 'src/icons/ErrorIcon'

type ErrorNotificationProps = { error?: string; onClose: () => void }

export const ErrorNotification: FC<ErrorNotificationProps> = ({ error, onClose }) => {
  if (!error) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Snackbar
        sx={{
          '&.MuiSnackbar-anchorOriginTopCenter': {
            '@media (max-width:599px)': {
              left: theme => theme.spacing(2),
              right: theme => theme.spacing(2),
              top: theme => theme.spacing(3),
            },
          },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        autoHideDuration={3000}
      >
        <Alert
          icon={<ErrorIcon />}
          elevation={6}
          variant='filled'
          severity={'error'}
          onClose={onClose}
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </ClickAwayListener>
  )
}
