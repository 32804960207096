import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Stack from '@mui/material/Stack'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { ScanFustPassFlowDialogs } from 'src/components/dialogs/ScanFustPassFlowDialogs'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'
import { Depot } from 'src/types/depot'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export type DepartureLocationProps = {
  service: typeof issuanceDirectTransactionsService
}

export const DepartureLocation: FC<DepartureLocationProps> = ({ service }) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const {
    HANDLE_DEPARTURE_LOCATION,
    CLOSE_FUST_PASS_NUMBER_DIALOG,
    ASSIGN_SCANNED_FUST_PASS_CONTEXT,
    ASSIGN_DEPARTURE_LOCATION,
  } = issuanceDirectTransactionsMachineModel.events

  return (
    <>
      <ScanFustPassFlowDialogs
        onCompleted={scanContext => {
          send(ASSIGN_SCANNED_FUST_PASS_CONTEXT(scanContext))
        }}
        startProcess={state.matches('handling_fust_pass_scan')}
        handleClose={() => {
          send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }}
        selectedTransactions={state.context.selectedTransactions}
        hasMasterpasOverride={true}
      />
      <FlowView>
        <FlowViewContent>
          <Stack direction={'column'} gap={2}>
            <RfhTypography variant={'subtitle1'}>
              {t('inputs.scanOrSearchLocations')}
            </RfhTypography>
            <DepotsAutocomplete
              value={state?.context?.selectedDepartureLocation}
              onChange={(_event, value) => {
                send(ASSIGN_DEPARTURE_LOCATION(value as Depot))
              }}
              placeholder={t('inputs.selectDepartureLocation')}
            />
          </Stack>
        </FlowViewContent>
        <FlowViewActions>
          <RfhButton
            variant={'contained'}
            fullWidth
            disabled={!state.can('HANDLE_DEPARTURE_LOCATION')}
            onClick={() => send(HANDLE_DEPARTURE_LOCATION())}
          >
            {t('button.departure')}
          </RfhButton>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
