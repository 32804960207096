import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@rfh/ui/components/Inputs/TextField'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { CloseOutline } from '@rfh/ui/shared/icons'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { IssuanceTransactionsDataGrid } from 'src/components/IssuanceFlow/IssuanceTransactionsDataGrid'
import { OrangeScanIcon } from 'src/components/common.styles'
import { CustomerNumberDialog } from 'src/components/dialogs/CustomerNumberDialog'
import {
  issuanceStoreTransactionsMachineModel,
  issuanceStoreTransactionsService,
} from 'src/machines/issuanceStoreTransactionsMachine'
import { TransactionType } from 'src/types/transaction'

export type ListOfStoreTransactionsProps = {
  service: typeof issuanceStoreTransactionsService
}
export const ListOfStoreTransactions: FC<ListOfStoreTransactionsProps> = ({
  service,
  children,
}) => {
  const { t } = useTranslation()
  const receiptNumberRef = useRef<HTMLInputElement>()

  const [state, send] = useActor(service)

  const {
    SUBMIT_VALIDATED_RECEIPT_NUMBER,
    UNANNOUNCED,
    CLOSE_CUSTOMER_NUMBER_DIALOG,
    HANDLE_SELECTED_TRANSACTIONS,
    UNASSIGN_GIVEN_RECEIPT_NUMBER,
    LOAD_TRANSACTIONS_LIST,
    ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER,
  } = issuanceStoreTransactionsMachineModel.events

  const resetReceiptNumber = () => {
    const input = receiptNumberRef?.current as HTMLInputElement
    input.value = ''
    send(UNASSIGN_GIVEN_RECEIPT_NUMBER())
    send(LOAD_TRANSACTIONS_LIST())
  }

  return (
    <>
      <CustomerNumberDialog
        onSubmit={async value => {
          send(ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER(value.nummer, value.naam))
          return Promise.resolve()
        }}
        isOpen={state.matches('asking_customer_number')}
        setIsOpen={value => {
          /* istanbul ignore next */
          if (!value) {
            send(CLOSE_CUSTOMER_NUMBER_DIALOG())
          }
          return value
        }}
      />

      <FlowView loading={state.hasTag('loading')} p={0}>
        <FlowViewContent>
          {children}
          <Stack direction={'column'} gap={2} p={2}>
            <RfhTypography variant={'text-micro-bold'}>
              {t('issuance.receiptNumber')}
            </RfhTypography>
            <form onSubmit={e => e.preventDefault()}>
              <Stack direction={'row'} gap={2}>
                <TextField
                  autoComplete={'off'}
                  type={'number'}
                  defaultValue={state?.context?.givenReceiptNumber}
                  prefixTextOrIcon={<OrangeScanIcon />}
                  suffixTextOrIcon={
                    <CloseOutline
                      aria-label='clear value'
                      role='button'
                      onClick={resetReceiptNumber}
                    />
                  }
                  autoFocus
                  inputRef={receiptNumberRef}
                  inputProps={{
                    'aria-label': 'receipt number',
                  }}
                />
                <Button
                  type={'submit'}
                  variant={'contained'}
                  onClick={() => {
                    send(
                      SUBMIT_VALIDATED_RECEIPT_NUMBER(
                        String(receiptNumberRef?.current?.value)
                      )
                    )
                  }}
                >
                  {t('button.confirm')}
                </Button>
              </Stack>
            </form>

            <IssuanceTransactionsDataGrid
              service={service}
              transactionType={TransactionType.UitgifteWinkel}
              layout={[1, 7, 2.8, 1]}
              columns={[
                '',
                t('transactions.header.customer'),
                t('countTable.headings.stw'),
                '',
              ]}
              isDetailDialogOpen={state.matches('viewing_transaction_details')}
            />
          </Stack>
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            variant={'contained'}
            onClick={() => send(HANDLE_SELECTED_TRANSACTIONS())}
            disabled={!state.can('HANDLE_SELECTED_TRANSACTIONS')}
          >
            {t('button.handle')}
          </Button>
          <Button
            variant={'contained'}
            onClick={() =>
              send(
                UNANNOUNCED(
                  state.context.scannedFustPassContext?.scannedFustPass?.klant
                    .nummer as number,
                  state.context.scannedFustPassContext?.scannedFustPass?.klant
                    .naam as string
                )
              )
            }
            disabled={state.can('HANDLE_SELECTED_TRANSACTIONS')}
          >
            {t('button.unannounced')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
