import { CountRowInput } from 'src/components/CountTable'
import { FustAmount, ItemStatus } from 'src/types/fust'

export const transformCountRowInputTypesToFustAmountTypes = (
  countRowIntput: CountRowInput
): FustAmount => {
  const fustAmount: FustAmount = {
    fustCode: Number(countRowIntput.fustCode),
    stapelwagens: Number(countRowIntput.stapelwagens),
    slepen: Number(countRowIntput.slepen),
    totaal: Number(countRowIntput.totaal),
    stapels: Number(countRowIntput.stapels),
    stuks: Number(countRowIntput.stuks),
    itemState: countRowIntput.itemState as ItemStatus,
  }

  if (Object.keys(countRowIntput).includes('itemStateNew')) {
    fustAmount.itemStateNew = countRowIntput.itemStateNew as ItemStatus
  }

  return fustAmount
}
