import { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSelector } from '@xstate/react'

import { PreInformation } from 'src/components/IssuanceFlow/PreInformation'
import { Layout } from 'src/components/Layouts'
import { ScanFustPassFlowDialogs } from 'src/components/dialogs/ScanFustPassFlowDialogs'
import { issuanceFlowMachineModel } from 'src/machines/issuanceFlowMachine'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export const IssuanceFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(IssuanceCreateFlowContext)
  const navigate = useNavigate()
  const askingPreInformationIdleState = useSelector(service, ({ matches }) => {
    return matches('handling_fust_pass_scan') || matches('asking_pre_information')
  })
  const handlingFustPassScanState = useSelector(service, currentState =>
    currentState.matches('handling_fust_pass_scan')
  )
  const issuanceStoreTransactionsState = useSelector(service, currentState =>
    currentState.matches('handling_issuance_store_transactions')
  )

  const issuanceDirectTransactionsState = useSelector(service, currentState =>
    currentState.matches('handling_issuance_direct_transactions')
  )
  const issuanceDockTransactionsState = useSelector(service, currentState =>
    currentState.matches('handling_issuance_dock_transactions')
  )
  const { CLOSE_FUST_PASS_SCAN_DIALOGS, ASSIGN_SCANNED_FUST_PASS_CONTEXT } =
    issuanceFlowMachineModel.events

  useEffect(() => {
    if (issuanceStoreTransactionsState) {
      navigate('/uitgifte/winkel')
    } else if (issuanceDirectTransactionsState) {
      navigate('/uitgifte/direct')
    } else if (issuanceDockTransactionsState) {
      navigate('/uitgifte/dock')
    }
  }, [service.state])

  switch (true) {
    case askingPreInformationIdleState:
      return (
        <Layout title={t('issuance.general')}>
          <ScanFustPassFlowDialogs
            onCompleted={scanContext => {
              service.send(ASSIGN_SCANNED_FUST_PASS_CONTEXT(scanContext))
            }}
            startProcess={handlingFustPassScanState}
            handleClose={() => {
              service.send(CLOSE_FUST_PASS_SCAN_DIALOGS())
            }}
          />
          <PreInformation />
        </Layout>
      )
    default:
      return null
  }
}
