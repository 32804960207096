import { FC, SetStateAction } from 'react'
import { FormEventHandler } from 'react'
import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import CloseIcon from '@rfh/ui/shared/icons/CloseOutline'

export type BaseDialogProps = {
  isOpen: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  onSubmit: () => void
}

export const BaseDialog: FC<BaseDialogProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  children,
}) => {
  const { t } = useTranslation()
  const handleClose = () => {
    setIsOpen!(false)
  }

  const handleSubmit: FormEventHandler = event => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <Dialog
      fullWidth
      sx={{ '.MuiPaper-root': { width: 'calc(100% - 32px)', margin: 2 } }}
      open={isOpen}
    >
      <DialogContent>
        {setIsOpen && (
          <Box
            role='button'
            aria-label={t('dialog.closeNotification')}
            position={'absolute'}
            right={12}
            top={12}
            onClick={handleClose}
          >
            <CloseIcon sx={{ width: '20px', height: '20px' }} />
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <Stack direction={'column'} gap={2}>
            {children}
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}
