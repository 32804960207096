/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC } from 'react'

import { useActor, useSelector } from '@xstate/react'

import { DockTransactionsDefaultViewProps } from 'src/components/IssuanceFlow/dockTransactions/DockTransactionsFlowStart'
import { DivideTransactionView } from 'src/components/IssuanceFlow/views/DivideTransactionView'
import { issuanceDockTransactionsMachineModel } from 'src/machines/issuanceDockTransactionsMachine'
import { issuanceTransactionsDataGridMachineModel } from 'src/machines/issuanceTransactionsDataGridMachine'
import { FustTransaction } from 'src/types/transaction'

const {
  ASSIGN_UPDATED_FUST_AMOUNT,
  VALIDATE_TRANSACTION,
  ASSIGN_SELECTED_TRANSACTION,
} = issuanceDockTransactionsMachineModel.events

const { ASSIGN_SELECTED_TRANSACTIONS } = issuanceTransactionsDataGridMachineModel.events

export type DivideTransactionProps = DockTransactionsDefaultViewProps

export const DivideTransaction: FC<DivideTransactionProps> = ({
  service,
  children,
}) => {
  const [state, send] = useActor(service)

  const gridService = useSelector(
    service,
    currentState => currentState.context.transactionsDataGridService
  )

  const gridSend = gridService.send

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction!
  )
  const updatedFustAmount = useSelector(
    service,
    currentState => currentState.context.updatedFustAmount!
  )

  return (
    <DivideTransactionView
      loading={state.hasTag('loading')}
      updatedFustAmount={updatedFustAmount}
      submissionClickHandler={() => {
        send(VALIDATE_TRANSACTION())
      }}
      handleSplitChangeHandler={fustAmount => {
        const updatedSelectedTransaction: FustTransaction = {
          ...selectedTransaction,
          fustAantallen: [...fustAmount],
        }
        send(ASSIGN_SELECTED_TRANSACTION(updatedSelectedTransaction))
        send(ASSIGN_UPDATED_FUST_AMOUNT(fustAmount))
        gridSend(ASSIGN_SELECTED_TRANSACTIONS([updatedSelectedTransaction]))
      }}
    >
      {children}
    </DivideTransactionView>
  )
}
