import { interpret } from 'xstate'

import { createContext, FC } from 'react'
import { Outlet } from 'react-router-dom'

import { useInterpret } from '@xstate/react'

import { issuanceFlowMachine } from 'src/machines/issuanceFlowMachine'

// @todo let the flow start at the given state check src/machines/persistedStates
// import { listOfTransactionsState } from 'src/machines/persistedStates/issuanceStore'

export const issuanceFlowService = interpret(issuanceFlowMachine)

export const IssuanceCreateFlowContext = createContext<{
  service: typeof issuanceFlowService
}>({
  service: issuanceFlowService,
})

export type IssuanceFlowProps = {
  service?: typeof issuanceFlowService
}

export const IssuanceFlow: FC<IssuanceFlowProps> = ({ service }) => {
  const defaultIssuanceFlowService = useInterpret(issuanceFlowMachine, {
    devTools: process.env.REACT_APP_XSTATE_INSPECT !== undefined,
    // @todo let the flow start at the given state check src/machines/persistedStates
    // state: listOfTransactionsState,
  })

  return (
    <IssuanceCreateFlowContext.Provider
      value={{ service: service || defaultIssuanceFlowService }}
    >
      <Outlet />
    </IssuanceCreateFlowContext.Provider>
  )
}
