import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { adalGetToken } from 'react-adal'

import { authContext, DO_NOT_LOGIN } from 'src/configs/adalConfig'
import { Config } from 'src/configs/config'

const axiosClient = axios.create({
  baseURL: Config.api,
})

export const requestHandler = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig | void> => {
  try {
    const accessToken = !DO_NOT_LOGIN
      ? await adalGetToken(authContext, Config.adalConfig.clientId)
      : undefined

    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    }

    return config
  } catch {
    return window.location.reload()
  }
}

axiosClient.interceptors.request.use(requestHandler)

export { axiosClient as api }

export const getApiErrorMessageOrFallback = (error: AxiosError): string =>
  typeof error.response?.data === 'string' && error.response?.data.length > 0
    ? error.response?.data
    : 'Er is iets mis gegaan.'
