import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@rfh/ui/components/Inputs/TextField'

import { OrangeScanIcon } from 'src/components/common.styles'
import useDepots from 'src/hooks/useDepots'
import nlTranslations from 'src/i18n/nl/translations.json'
import { Depot } from 'src/types/depot'

export type ChooseDepotAutocompleteProps = Omit<
  AutocompleteProps<Depot, boolean, boolean, boolean>,
  'options' | 'renderInput' | 'onLoadedData' | 'loading' | 'loadingText'
> & {
  onLoadedData?: (depots: Depot[]) => void
  placeholder?: string
}

export const DepotsAutocomplete: FC<ChooseDepotAutocompleteProps> = ({
  value,
  onChange,
  onLoadedData,
  placeholder = nlTranslations.inputs.scanOrSearchDepot,
  ...rest
}) => {
  const { t } = useTranslation()
  const { status, data } = useDepots()

  useEffect(() => {
    if (data?.length) {
      onLoadedData && onLoadedData(data)
    }
  }, [data])

  return (
    <Autocomplete
      options={data?.length ? data : []}
      getOptionLabel={option => option.code}
      value={value}
      loading={status === 'loading'}
      loadingText={t('loading')}
      onChange={onChange}
      autoSelect
      renderInput={params => (
        <TextField
          {...params}
          prefixTextOrIcon={<OrangeScanIcon />}
          autoFocus
          fullWidth
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  )
}
