import { ChangeEventHandler, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@rfh/ui/components/Inputs/TextField'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { OrangeScanIcon } from 'src/components/common.styles'
import { BaseDialog } from 'src/components/dialogs/BaseDialog'

type ConfirmFustPassNumberDialogProps = {
  isOpen: boolean
  fustPassNumberToConfirm: string
  handleClose: () => void
  handleSubmit: () => void
}

export const ConfirmFustPassNumberDialog: FC<ConfirmFustPassNumberDialogProps> = ({
  isOpen,
  handleClose,
  fustPassNumberToConfirm,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [fustPassNumber, setFustPassNumber] = useState<string>()

  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    setErrorMessage(undefined)
    setFustPassNumber(event.target.value)
  }
  const notFoundErrorMessage = t('errorMessage.incorrectFustpass')

  const onSubmit = () => {
    if (fustPassNumberToConfirm === fustPassNumber?.replace(/\s/g, '')) {
      handleSubmit()
    } else {
      setErrorMessage(notFoundErrorMessage)
    }
  }

  return (
    <BaseDialog isOpen={isOpen} setIsOpen={() => handleClose()} onSubmit={onSubmit}>
      <RfhTypography variant={'subheader'}>{t('dialog.fustpassScan')}</RfhTypography>

      <TextField
        autoComplete={'off'}
        error={!!errorMessage}
        helperText={errorMessage}
        prefixTextOrIcon={<OrangeScanIcon />}
        autoFocus
        value={fustPassNumber}
        onChange={onChange}
        fullWidth
        inputProps={{
          'aria-label': 'fustpasnummer',
          inputMode: 'numeric',
          pattern: '[0-9\\s]*',
          maxLength: 17,
        }}
      />
      <Button variant='block--contained' color='primary' fullWidth type={'submit'}>
        {t('button.confirm')}
      </Button>
    </BaseDialog>
  )
}
