import { createModel } from 'xstate/lib/model'

import { issuanceDockTransactionsMachineModel } from 'src/machines/issuanceDockTransactionsMachine'
import { Branch } from 'src/types/branch'
import { Depot } from 'src/types/depot'
import { TransactionType } from 'src/types/transaction'
import { WorkArea } from 'src/types/workArea'

import { getSpawnedIssuanceTransactionsDataGridMachine } from '../utils'
import {
  issuanceDirectTransactionsMachine,
  issuanceDirectTransactionsMachineModel,
} from './issuanceDirectTransactionsMachine'
import { issuanceDockTransactionsMachine } from './issuanceDockTransactionsMachine'
import {
  issuanceStoreTransactionsMachine,
  issuanceStoreTransactionsMachineModel,
} from './issuanceStoreTransactionsMachine'
import { ScanFustPassFlowMachineContext } from './scanFustPassFlowMachine'

export type IssuanceFlowContext = {
  selectedTransactionType?:
    | TransactionType.UitgifteDirect
    | TransactionType.UitgifteDock
    | TransactionType.UitgifteWinkel
  selectedDepot?: Depot
  selectedWorkArea?: WorkArea
  scannedFustPassContext?: ScanFustPassFlowMachineContext
}

export const issuanceFlowMachineModel = createModel({} as IssuanceFlowContext, {
  events: {
    CONTINUE: () => ({}),
    ASSIGN_SELECTED_TRANSACTION_TYPE: (
      transactionType: IssuanceFlowContext['selectedTransactionType']
    ) => ({
      transactionType,
    }),
    ASSIGN_SELECTED_DEPOT: (depot: Depot) => ({
      depot,
    }),
    ASSIGN_SELECTED_WORK_AREA: (workArea: WorkArea) => ({
      workArea,
    }),
    ASSIGN_SCANNED_FUST_PASS_CONTEXT: (
      scanContext: ScanFustPassFlowMachineContext
    ) => ({ scanContext }),
    CLOSE_FUST_PASS_SCAN_DIALOGS: () => ({}),
  },
})

export const issuanceFlowMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEtawK4EMB2BjMAYgDYD2A7gHSawDWy2UA+gA4BOYj9AZiawLaYALshLYqtekx6tGg1jliZcw0bICezMAGJEoZiVjIV2XSAAeiAIwBmAOwBWCgCYADNYCc9j+9sAOS+6+ADQgaohOfhTu0b5eTj4uACyWvgC+qSGoGDj4xOTidAws7JzY0gLGBZKM0owQYPqCWgCCAMqtAJIA4gByjK0AogAyAwDCACoDACKMUwMACgDy46b6hsamFgiWAGzuiRT+TonuLrG21nb2IWEITvaWFJdOvrYXLi6WX75O6ZloWDwhFIlGohSYbA43F4FREYjB1Vq9UaWlGix64w6PQAqgNVgYjHDNohko8Pi57g8nE5rPFrDcSb5fFEYvYdvYHH4fPY-iAsoDciCqkVIaVykI4S12t0+oMRhNpoxxgAlZo9VrNCYddFKgCa8zxSBAa0JomJCC5FDcuycO2svmsiTtOwZCFpLgoJ0CXzcLh2uydvP5OWB+QRIpK0P4EtEOiNJo2Rq29icrssLnsHuigTtNlpvh2QYBIbylAAFjgIEREehYIIWNRYIxYLgcFLOr1+qM1T1FQRsa1xox5m1Wow0RiBgANFbxgmJ0BbUlWj6UyzU2nuemhRC+dws71vNy2Fz7NIZPnFoGligV7BVmt1htoZut7CooaLQaMfuD4ejrs1VmDpmk-LpWnxdYiSTEkvhXCl7CpGk6TTWwdgPU5EMsLwzhpItsmvIUwAAN0wIgsGEIo5AUJRjCbQQNG0SDTRMGDtnuWwKEzT4PESDNohpV0dgpQ5EgddcnTZTxbHSC9sBIep4CNYNCPyZAqzAZiF3MElUx3BBEniA8mV8FwTx2V5LHwgVQ1BCQIyhMoYRjMQy1QQQtOgxdEFsfYrWsG07QdJ1rBdfT7kebjPjEvZ3FzGSLxUwUw3siFIyc6NKnDKReFkeRsEUZQ4XUTRPLNNivlpJ5OQzU891sRJrn0y4Di9fZhN8Rr-SsxKr2SuzwWKRzxSy1Kaly5ESA8ucoPK7ztneT1GopfxEga6lXUdawnk3dkvksWx00LXqCP64U0uG5ztITLydItdl-MC+1HWdNMdk455Ot8vMTgS-5Tts29K2rIouFretmEbV8cDK1j5sM11qUcbjXHif0CxsaySyI0jyIlKj8sKuiSs0maWPNBx92tHZbWekKwtudNtuzTwIg5dlDOsLHVPLYHqiSjg614DhqIK2i4SUvR51urY0McN5ElCxqHSpSxXXcIykhCp0KVZrmTpsm87wfIoBbqZB2GUPKaKK0RJeNaW5ruhH9N+g8Tw5BqPB2Y7-sNoVjZBpgzYgEhcBoa2xdtgrYfNF3Gb9LiPjtJwUlCn1fcvAHS1jirgn0g73aZAK9jW0LZNSIA */
  issuanceFlowMachineModel.createMachine(
    {
      context: issuanceFlowMachineModel.initialContext,
      tsTypes: {} as import('./issuanceFlowMachine.typegen').Typegen0,
      schema: {
        services: {} as {
          loadIssuanceTransactionsList: {
            data: string
          }
        },
      },
      predictableActionArguments: true,
      id: 'issuanceFlow',
      initial: 'asking_pre_information',
      states: {
        idle: {},
        asking_pre_information: {
          initial: 'asking_for_transaction_type',
          states: {
            hist: {
              history: 'shallow',
              type: 'history',
            },
            asking_for_transaction_type: {
              always: [
                {
                  cond: 'isUitgifteWinkelSelected',
                  target: 'asking_for_depot',
                },
                {
                  cond: 'isUitgifteDirectSelected',
                  target: 'asking_for_work_area',
                },
                {
                  cond: 'isUitgifteDockSelected',
                  target: '#issuanceFlow.handling_fust_pass_scan',
                },
              ],
            },
            asking_for_work_area: {
              on: {
                ASSIGN_SELECTED_WORK_AREA: {
                  actions: 'assignSelectedWorkArea',
                },
                CONTINUE: {
                  cond: 'isUitgifteDirectRequiredInfoSelected',
                  target: '#issuanceFlow.handling_issuance_direct_transactions',
                },
              },
            },
            asking_for_depot: {
              on: {
                ASSIGN_SELECTED_DEPOT: {
                  actions: 'assignSelectedDepot',
                },
                CONTINUE: {
                  cond: 'isUitgifteWinkelRequiredInfoSelected',
                  target: '#issuanceFlow.handling_fust_pass_scan',
                },
              },
            },
          },
          always: {
            cond: context =>
              context.scannedFustPassContext !== undefined &&
              context.selectedDepot !== undefined &&
              context.selectedTransactionType === TransactionType.UitgifteWinkel,
            target: 'handling_issuance_store_transactions',
          },
          on: {
            ASSIGN_SELECTED_TRANSACTION_TYPE: {
              actions: 'assignSelectedTransactionType',
              target: '.asking_for_transaction_type',
            },
          },
        },
        handling_fust_pass_scan: {
          description: 'using <ScanFustPassFlowDialog /> to validate the number',
          on: {
            ASSIGN_SCANNED_FUST_PASS_CONTEXT: {
              actions: 'assignScannedFustPassContext',
              target: 'evaluating_transactions_type',
            },
            CLOSE_FUST_PASS_SCAN_DIALOGS: [
              {
                actions: issuanceFlowMachineModel.assign(() => {
                  return {
                    selectedTransactionType: undefined,
                  }
                }),
                cond: 'isUitgifteDockSelected',
                target: '#issuanceFlow.asking_pre_information.hist',
              },
              {
                target: '#issuanceFlow.asking_pre_information.hist',
              },
            ],
          },
        },
        evaluating_transactions_type: {
          always: [
            {
              cond: 'isUitgifteWinkelRequiredInfoSelected',
              target: 'handling_issuance_store_transactions',
            },
            {
              cond: 'isUitgifteDockSelected',
              target: 'handling_issuance_dock_transactions',
            },
          ],
        },
        handling_issuance_store_transactions: {
          invoke: {
            src: issuanceStoreTransactionsMachine,
            id: 'issuanceStoreTransactionsFlow',
            data: {
              ...issuanceStoreTransactionsMachineModel.initialContext,
              selectedDepot: (context: IssuanceFlowContext) => context.selectedDepot,
            },
            onDone: {
              target: '#issuanceFlow.handling_fust_pass_scan',
            },
          },
        },
        handling_issuance_direct_transactions: {
          invoke: {
            src: issuanceDirectTransactionsMachine,
            id: 'issuanceDirectTransactionsFlow',
            data: {
              ...issuanceDirectTransactionsMachineModel.initialContext,
              selectedWorkArea: (context: IssuanceFlowContext) =>
                context.selectedWorkArea,
            },
          },
        },
        handling_issuance_dock_transactions: {
          invoke: {
            src: issuanceDockTransactionsMachine,
            id: 'issuanceDockTransactionsFlow',
            data: {
              ...issuanceDockTransactionsMachineModel.initialContext,
              scannedFustPassContext: (context: IssuanceFlowContext) =>
                context.scannedFustPassContext,
              transactionsDataGridService: (context: IssuanceFlowContext) =>
                getSpawnedIssuanceTransactionsDataGridMachine(
                  String(
                    context.scannedFustPassContext?.scannedFustPass?.fustpasNummer
                  ),
                  TransactionType.UitgifteDock
                ),
            },
          },
        },
      },
    },
    {
      guards: {
        isUitgifteWinkelRequiredInfoSelected: context =>
          context.selectedDepot !== undefined &&
          context.selectedTransactionType === TransactionType.UitgifteWinkel,
        isUitgifteWinkelSelected: context => {
          return context?.selectedTransactionType === TransactionType.UitgifteWinkel
        },
        isUitgifteDirectSelected: context => {
          return context?.selectedTransactionType === TransactionType.UitgifteDirect
        },
        isUitgifteDirectRequiredInfoSelected: context => {
          if (localStorage.getItem('mfb-selected-branch') === Branch.Aalsmeer) {
            return (
              context.selectedWorkArea !== undefined &&
              context.selectedTransactionType === TransactionType.UitgifteDirect
            )
          } else {
            return context.selectedTransactionType === TransactionType.UitgifteDirect
          }
        },
        isUitgifteDockSelected: context => {
          return context?.selectedTransactionType === TransactionType.UitgifteDock
        },
      },
      actions: {
        assignSelectedWorkArea: issuanceFlowMachineModel.assign((_context, event) => {
          return {
            selectedWorkArea: event.workArea,
          }
        }),
        assignSelectedDepot: issuanceFlowMachineModel.assign((_context, event) => {
          return {
            selectedDepot: event.depot,
          }
        }),
        assignSelectedTransactionType: issuanceFlowMachineModel.assign(
          (_context, event) => {
            return {
              selectedWorkArea: undefined,
              selectedDepot: undefined,
              selectedTransactionType: event.transactionType,
            }
          }
        ),
        assignScannedFustPassContext: issuanceFlowMachineModel.assign(
          (_context, event) => {
            return {
              scannedFustPassContext: event.scanContext,
            }
          }
        ),
      },
    }
  )
