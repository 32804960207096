import { FC } from 'react'

import Box, { BoxProps } from '@mui/material/Box'

export type FlowDialogContentProps = BoxProps

export const FlowDialogContent: FC<FlowDialogContentProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box height={'100%'} mb={4} {...rest}>
      {children}
    </Box>
  )
}
