import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'

export const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2),
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.rfhColors.white,
  height: '80px',
  cursor: 'pointer',
}))
