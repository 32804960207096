import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Save } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Grid, { GridProps } from '@mui/material/Grid'
import { InputBaseProps } from '@mui/material/InputBase'
import TextField from '@rfh/ui/components/Inputs/TextField'
import { AddIcon, DeleteIcon, EditIcon } from '@rfh/ui/shared/floriday-icons'

import { StyledSelect } from 'src/components/CountTable.styles'
import { transformCountRowInputTypesToFustAmountTypes } from 'src/components/CountTable.utils'
import { RoundIconButton } from 'src/components/RoundIconButton'
import {
  FustAmount,
  FustAmountColumnLabel,
  FustAmountKeys,
  ItemStatus,
  ItemStatusLabels,
} from 'src/types/fust'

export type CountTableProps = {
  rows?: FustAmount[]
  columns: FustAmountKeys[]
  handleRowAdd: (row: FustAmount) => void
  handleRowRemove: (index: number) => void
  handleRowEdit: (row: FustAmount, index: number) => void
  setCanContinue: Dispatch<SetStateAction<boolean>>
  maxStwLength?: number
  maxStkLength?: number
  itemStateDefault?: string
}

/**
 * NOTE: Type should only be used in CountTable (utils).
 * other place FustAmount should be used
 */
export type CountRowInput = {
  fustCode: number | string
  itemState: string
  itemStateNew?: string
  slepen: number | string
  stapels: number | string
  stapelwagens: number | string
  stuks: number | string
  totaal: number | string
}

type AddOrEditRowProps =
  | {
      handleRowInput: CountTableProps['handleRowAdd']
      handleRowRemove: CountTableProps['handleRowRemove']
      setCanContinue: Dispatch<SetStateAction<boolean>>
      mode: 'add'
      row?: never
      index?: never
      columns: FustAmountKeys[]
      maxStwLength?: number
      maxStkLength?: number
      itemStateDefault?: string
    }
  | {
      handleRowInput: CountTableProps['handleRowEdit']
      handleRowRemove: CountTableProps['handleRowRemove']
      setCanContinue: Dispatch<SetStateAction<boolean>>
      mode: 'edit'
      row: FustAmount
      index: number
      columns: FustAmountKeys[]
      maxStwLength?: number
      maxStkLength?: number
      itemStateDefault?: string
    }

const AddOrEditRow: FC<AddOrEditRowProps> = ({
  handleRowInput,
  handleRowRemove,
  setCanContinue,
  columns,
  mode = 'add',
  row,
  index,
  maxStwLength = 2,
  maxStkLength = 4,
  itemStateDefault,
}) => {
  const { t } = useTranslation()
  const initialState: CountRowInput = {
    fustCode: '',
    stuks: '',
    stapels: '',
    stapelwagens: '',
    slepen: '',
    totaal: '',
    itemState: itemStateDefault || '',
    itemStateNew: undefined,
  }

  const state = mode === 'edit' && row ? (row as CountRowInput) : initialState
  const [rowInput, setRowInput] = useState<CountRowInput>(state)
  // stw, slp,stpls,stks atleast 1 should be more then 0
  const accumulatedCount = [
    t('countTable.stackingTrolleys'),
    t('countTable.stacks'),
    t('countTable.dragging'),
    t('countTable.pieces'),
  ].reduce((value, key) => value + Number(rowInput[key as keyof CountRowInput]), 0)

  useEffect(() => {
    if (itemStateDefault !== '') {
      setCanContinue(
        Object.entries(rowInput)
          .filter(([key]) => key !== 'itemState')
          .every(([_, value]) => !value)
      )
    } else {
      setCanContinue(Object.values(rowInput).every(value => !value))
    }
  }, [rowInput, mode])

  const isValid =
    Object.values(rowInput).filter(Boolean).length > 0 && accumulatedCount > 0
  const fustCodeInputRef = useRef<HTMLInputElement>()

  const handleInput =
    (code: FustAmountKeys) => (event: ChangeEvent<HTMLInputElement>) => {
      setRowInput(prev => ({ ...prev, [code]: event.target.value }))
    }

  const handleRowSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (mode === 'add') {
      handleRowInput(transformCountRowInputTypesToFustAmountTypes(rowInput), 0) // TODO figure out second param
    } else if (mode === 'edit') {
      handleRowInput(transformCountRowInputTypesToFustAmountTypes(rowInput), index!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
    setRowInput(initialState)
    fustCodeInputRef.current!.focus()
  }
  const inputProps: { [key: string]: InputBaseProps['inputProps'] } = {
    fustCode: {
      'aria-label': `${mode} code`,
      inputMode: 'numeric',
      pattern: '[0-9]*',
      maxLength: 3,
      required: true,
    },
    correct: {},
    stapels: {
      'aria-label': `${mode} stpl`,
      inputMode: 'numeric',
      pattern: '[0-9]*',
      maxLength: 2,
    },
    stuks: {
      'aria-label': `${mode} stk`,
      inputMode: 'numeric',
      pattern: '[0-9]*',
      maxLength: maxStkLength,
    },
    totaal: {},
    slepen: {
      'aria-label': `${mode} slp`,
      inputMode: 'numeric',
      pattern: '[0-9]*',
      maxLength: 2,
    },
    stapelwagens: {
      'aria-label': `${mode} stw`,
      inputMode: 'numeric',
      pattern: '[0-9]*',
      maxLength: maxStwLength,
    },
    itemState: {
      'aria-label': `${mode} itemState`,
      required: true,
    },
    itemStateNew: {
      'aria-label': `${mode} itemStateNew`,
      required: true,
    },
  }

  return (
    <GridContainer>
      <form onSubmit={handleRowSubmit} style={{ display: 'flex', width: '100%' }}>
        {columns.map(column => {
          if (column === 'fustCode') {
            return (
              <GridItem>
                <TextField
                  autoComplete={'off'}
                  inputRef={fustCodeInputRef}
                  value={rowInput.fustCode}
                  onChange={handleInput(column)}
                  autoFocus
                  inputProps={{ ...inputProps[column] }}
                />
              </GridItem>
            )
          } else if (column === 'itemState') {
            return (
              <GridItem>
                <StyledSelect
                  native
                  inputProps={{ ...inputProps[column] }}
                  fullWidth
                  value={rowInput.itemState}
                  onChange={event => {
                    setRowInput(prev => ({
                      ...prev,
                      itemState: event.target.value as CountRowInput['itemState'],
                    }))
                  }}
                >
                  <option disabled></option>
                  {Object.keys(ItemStatus).map(option => (
                    <option value={option}>{`${option} = ${
                      ItemStatusLabels[option as keyof typeof ItemStatusLabels]
                    }`}</option>
                  ))}
                </StyledSelect>
              </GridItem>
            )
          } else if (column === 'itemStateNew') {
            return (
              <GridItem>
                <StyledSelect
                  native
                  inputProps={{ ...inputProps[column] }}
                  fullWidth
                  value={rowInput.itemStateNew || ''}
                  onChange={event => {
                    setRowInput(prev => ({
                      ...prev,
                      itemStateNew: event.target.value as CountRowInput['itemStateNew'],
                    }))
                  }}
                >
                  <option disabled></option>
                  {Object.keys(ItemStatus).map(option => (
                    <option value={option}>{`${option} = ${
                      ItemStatusLabels[option as keyof typeof ItemStatusLabels]
                    }`}</option>
                  ))}
                </StyledSelect>
              </GridItem>
            )
          } else {
            return (
              <GridItem>
                <TextField
                  autoComplete={'off'}
                  value={rowInput[column as keyof CountRowInput]}
                  onChange={handleInput(column)}
                  inputProps={{ ...inputProps[column] }}
                />
              </GridItem>
            )
          }
        })}

        <GridItem justifyContent={'end'}>
          <Box>
            <Box mt={'5px'}>
              <RoundIconButton
                type='submit'
                label={
                  mode === 'add' ? t('countTable.addRow') : t('countTable.saveRow')
                }
                disabled={!isValid}
                icon={mode === 'add' ? <AddIcon /> : <Save />}
                height='35px'
                width='35px'
                borderRadius='20px'
                svgSize='20px'
              />
            </Box>
            {mode === 'edit' && (
              <Box mt={'15px'}>
                <RoundIconButton
                  onClick={() => handleRowRemove(index!)}
                  variant='outlined'
                  icon={<DeleteIcon />}
                  label={t('countTable.deleteRow')}
                  height='35px'
                  width='35px'
                  borderRadius='20px'
                  svgSize='20px'
                />
              </Box>
            )}
          </Box>
        </GridItem>
      </form>
    </GridContainer>
  )
}

export const HeadRowContainer: FC<GridProps> = ({ children, ...rest }) => (
  <GridContainer
    sx={{
      backgroundColor: ({ rfhColors }) => rfhColors.leafGreen,
      color: ({ rfhColors }) => rfhColors.white,
      fontWeight: 'bold',
    }}
    {...rest}
  >
    {children}
  </GridContainer>
)

const HeadRow: FC<{ columns: FustAmountKeys[] }> = ({ columns }) => (
  <HeadRowContainer columns={columns.length + 1}>
    {columns.map((column, index) => (
      <GridItem key={column + index}>
        {column === 'itemState' && columns.includes('itemStateNew')
          ? 'StO' // alternative label for rebook flow
          : FustAmountColumnLabel[column]}
      </GridItem>
    ))}
    <GridItem></GridItem>
  </HeadRowContainer>
)

export const BodyRowContainer: FC<GridProps> = ({ children, ...rest }) => (
  <GridContainer
    sx={{
      backgroundColor: ({ rfhColors }) => rfhColors.white,
      borderBottom: ({ rfhColors }) => `1px solid ${rfhColors.fogWhite}`,
    }}
    {...rest}
  >
    {children}
  </GridContainer>
)

const Row: FC<{
  row: FustAmount
  columns: FustAmountKeys[]
  index: number
  isEditing?: number
  setIsEditing: Dispatch<SetStateAction<number | undefined>>
  setCanContinue: Dispatch<SetStateAction<boolean>>
  handleRowRemove: CountTableProps['handleRowRemove']
  handleRowEdit: CountTableProps['handleRowEdit']
  itemStateDefault?: string
}> = ({
  row,
  index,
  columns,
  isEditing,
  handleRowRemove,
  setIsEditing,
  handleRowEdit,
  setCanContinue,
  itemStateDefault,
}) => {
  const { t } = useTranslation()

  if (isEditing === index) {
    return (
      <AddOrEditRow
        columns={columns}
        handleRowInput={updatedRow => {
          handleRowEdit(updatedRow, index)
          setIsEditing(undefined)
        }}
        handleRowRemove={() => {
          handleRowRemove(index)
          setIsEditing(undefined)
        }}
        setCanContinue={setCanContinue}
        mode='edit'
        row={row}
        index={index}
        itemStateDefault={itemStateDefault}
      />
    )
  }

  return (
    <GridContainer
      columns={columns.length + 1}
      sx={{
        backgroundColor: ({ rfhColors }) => rfhColors.white,
        borderBottom: ({ rfhColors }) => `1px solid ${rfhColors.fogWhite}`,
      }}
    >
      {columns.map(column => (
        <GridItem>
          <Box alignSelf={'center'}>{row[column]}</Box>
        </GridItem>
      ))}
      <GridItem alignItems={'center'} justifyContent={'end'} gap={1}>
        <RoundIconButton
          variant='outlined'
          icon={<EditIcon />}
          onClick={() => setIsEditing(index)}
          label={t('countTable.editRow')}
          height='35px'
          width='35px'
          borderRadius='20px'
          svgSize='20px'
        />
      </GridItem>
    </GridContainer>
  )
}

export const GridContainer: FC<GridProps> = ({ children, ...rest }) => (
  <Grid container px={1} spacing={0} columns={5} {...rest}>
    {children}
  </Grid>
)

export const GridItem: FC<GridProps> = ({ children, ...rest }) => (
  <Grid
    item
    xs={1}
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      padding: ({ spacing }) => spacing(1.5, 0.5),
    }}
    {...rest}
  >
    {children}
  </Grid>
)

export const CountTable: FC<CountTableProps> = ({
  rows,
  columns = [],
  setCanContinue,
  handleRowAdd,
  handleRowRemove,
  handleRowEdit,
  maxStwLength = 2,
  maxStkLength = 4,
  itemStateDefault,
}) => {
  const [isEditing, setIsEditing] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (isEditing === undefined) {
      setCanContinue(true)
    } else {
      setCanContinue(false)
    }
  }, [isEditing])
  return (
    <>
      <HeadRow columns={columns} />
      {[...rows!].map((row, index) => (
        <Row
          key={index}
          row={row}
          columns={columns}
          index={index}
          handleRowRemove={handleRowRemove}
          handleRowEdit={handleRowEdit}
          setCanContinue={setCanContinue}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          itemStateDefault={itemStateDefault}
        />
      ))}
      <AddOrEditRow
        mode='add'
        handleRowInput={handleRowAdd}
        handleRowRemove={handleRowRemove}
        columns={columns}
        setCanContinue={setCanContinue}
        maxStwLength={maxStwLength}
        maxStkLength={maxStkLength}
        itemStateDefault={itemStateDefault}
      />
    </>
  )
}
