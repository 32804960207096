export enum ItemStatus {
  S = 'S', // Schoon
  V = 'V', // Vuil
  P = 'P', // Schoon op pallet
  O = 'O', // Overig
}

export enum ItemStatusLabels {
  S = 'schoon',
  V = 'vuil',
  P = 'pallet',
  O = 'overig',
}

export type FustAmount = {
  correct?: boolean
  fustCode: number
  itemState?: ItemStatus
  itemStateNew?: ItemStatus
  slepen: number
  stapelwagens: number
  stapels: number
  stuks: number
  totaal: number
}

export type FustAmountKeys = keyof FustAmount

export enum FustAmountColumnLabel {
  fustCode = 'CODE',
  itemState = 'Staat',
  itemStateNew = 'StN',
  stapelwagens = 'STW',
  stapels = 'STPL',
  stuks = 'STK',
  totaal = 'Sub t.',
  slepen = 'SLP',
  correct = 'Correct', // Note: never shown
}
