import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useActor } from '@xstate/react'

import {
  MultiSelectableDataGrid,
  MultiSelectableDataGridProps,
} from 'src/components/MultiSelectableDataGrid'
import { IssuanceTransactionDetailDialog } from 'src/components/dialogs/IssuanceTransactionDetailDialog'
import nlTranslations from 'src/i18n/nl/translations.json'
import { issuanceTransactionsDataGridMachineModel } from 'src/machines/issuanceTransactionsDataGridMachine'
import { ScanFustPassFlowMachineContext } from 'src/machines/scanFustPassFlowMachine'
import { QueryTransactionsParam } from 'src/services/transactionService'
import { UserType } from 'src/types/customer'
import { FustTransaction, TransactionStatus } from 'src/types/transaction'
import { getSpawnedIssuanceTransactionsDataGridMachine } from 'src/utils'
import { getDataGridTransactionsRows } from 'src/utils/transactionsDataGrid'

const {
  VIEW_TRANSACTION_DETAIL,
  ASSIGN_FUST_AMOUNT,
  UPDATE_TRANSACTION_STATUS,
  ASSIGN_SELECTED_TRANSACTIONS,
  LOAD_TRANSACTIONS_LIST,
  CLOSE_TRANSACTION_DETAIL_VIEW,
} = issuanceTransactionsDataGridMachineModel.events

export type IssuanceTransactionsDataGridAlternativeProps = {
  dataGridService: ReturnType<typeof getSpawnedIssuanceTransactionsDataGridMachine>
  fustpassContext: ScanFustPassFlowMachineContext
  layout?: number[]
  columns?: string[]
}
export const IssuanceTransactionsTable: FC<
  IssuanceTransactionsDataGridAlternativeProps
> = ({
  layout = [1, 4, 4, 1.8, 1],
  columns = [
    '',
    nlTranslations.transactions.header.customer,
    nlTranslations.location,
    nlTranslations.countTable.headings.stw,
    '',
  ],
  dataGridService,
  fustpassContext,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(useMemo(() => dataGridService, []))

  const onCloseHandler = () => {
    send(CLOSE_TRANSACTION_DETAIL_VIEW())
  }
  const customerNumber =
    fustpassContext.additionalCustomerNumber ||
    fustpassContext.scannedFustPass!.klant.nummer

  const loadTransactionsParams: Partial<QueryTransactionsParam> =
    fustpassContext.scannedFustPass?.klant.type === UserType.Transporter
      ? { VervoerdersNummer: customerNumber }
      : { Klantnummer: customerNumber }

  useEffect(() => {
    send(LOAD_TRANSACTIONS_LIST(state.context.transactionType!, loadTransactionsParams))
  }, [])

  const onTransactionItemChecked: MultiSelectableDataGridProps['onItemChecked'] = (
    selectedRows,
    currentRow,
    selected
  ) => {
    const selectedTransactions = state.context.listOfTransactions.filter(
      (transaction: FustTransaction) =>
        selectedRows.find(row => row.id === transaction.id)
    )
    send(ASSIGN_SELECTED_TRANSACTIONS(selectedTransactions))
    send(
      ASSIGN_FUST_AMOUNT(
        selectedTransactions.flatMap(transaction => transaction.fustAantallen)
      )
    )
    send(
      UPDATE_TRANSACTION_STATUS(String(currentRow.id), {
        FustpasNummer: String(state.context.fustPassNumber),
        NewStatus: selected
          ? TransactionStatus['In behandeling']
          : TransactionStatus.Nieuw,
      })
    )
  }

  const rows = getDataGridTransactionsRows({
    listOfTransactionsState: state.context.listOfTransactions,
    onDetailClickHandler: transaction => {
      send(VIEW_TRANSACTION_DETAIL(transaction))
    },
    transactionType: state.context.transactionType,
  })

  return (
    <>
      {state.context.selectedTransactionDetail && (
        <IssuanceTransactionDetailDialog
          transaction={state.context.selectedTransactionDetail}
          open={state.matches('viewing_transaction_details')}
          handleClose={onCloseHandler}
          onClose={onCloseHandler}
        />
      )}
      <MultiSelectableDataGrid
        updating={state.matches('updating_transaction_status')}
        onItemChecked={onTransactionItemChecked}
        rows={rows}
        layout={layout}
        label={t('issuance.transactions')}
        defaultHeaderRow={{
          values: columns,
          header: true,
        }}
        listOfTransactions={state.context.listOfTransactions}
      />
    </>
  )
}
