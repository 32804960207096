import { FC } from 'react'

import { TypographyProps } from '@mui/material/Typography'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

export type FlowDialogTitleProps = TypographyProps

export const FlowDialogTitle: FC<FlowDialogTitleProps> = ({ children, ...rest }) => {
  return (
    <RfhTypography
      sx={{ flexGrow: 1 }}
      color={RfhColors.black}
      variant='title-display-h4-bold'
      {...rest}
    >
      {children}
    </RfhTypography>
  )
}
