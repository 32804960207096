import { FC } from 'react'

import Box from '@mui/material/Box'

import { AppBar } from 'src/components/AppBar'

type LayoutProps = {
  title: string
}

export const Layout: FC<LayoutProps> = ({ title, children }) => (
  <Box
    sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <AppBar title={title} />
    <Box sx={{ display: 'flex', flexGrow: '1', pt: 7, pb: 4 }}>{children}</Box>
  </Box>
)
