import { FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import RfhTheme from '@rfh/ui/components/RfhTheme/RfhTheme'

import { IntakeFlowStart } from 'src/components/IntakeFlow/IntakeFlowStart'
import { FixedTransactionsFlowStart } from 'src/components/IntakeFlow/intakeFixed/FixedTransactionsFlowStart'
import { MobileTransactionsFlowStart } from 'src/components/IntakeFlow/intakeMobile/MobileTransactionsFlowStart'
import { IssuanceFlowStart } from 'src/components/IssuanceFlow/IssuanceFlowStart'
import { DirectTransactionsFlowStart } from 'src/components/IssuanceFlow/directTransactions/DirectTransactionsFlowStart'
import { DockTransactionsFlowStart } from 'src/components/IssuanceFlow/dockTransactions/DockTransactionsFlowStart'
import { StoreTransactionsFlowStart } from 'src/components/IssuanceFlow/storeTransactions/StoreTransactionsFlowStart'
import i18n from 'src/i18n/config'
import { queryClient } from 'src/services/queryClient'
import { BranchSelection } from 'src/views/BranchSelection'
import { FlowSelection } from 'src/views/FlowSelection'
import { IntakeFlow } from 'src/views/IntakeFlow'
import { InterDepotTransportFlow } from 'src/views/InterDepotTransportFlow'
import { InventoryManagementFlow } from 'src/views/InventoryManagementFlow'
import { IssuanceFlow } from 'src/views/IssuanceFlow'
import { RebookItemStateFlow } from 'src/views/RebookItemStateFlow'

import { BranchContextProvider } from './hooks/useBranch'

const App: FC = () => (
  <I18nextProvider i18n={i18n}>
    <RfhTheme>
      <QueryClientProvider client={queryClient}>
        <BranchContextProvider>
          <Router>
            <Routes>
              <Route path='/' element={<FlowSelection />} />
              <Route path='/vestiging' element={<BranchSelection />} />
              <Route path='/inname' element={<IntakeFlow />}>
                <Route path='start' element={<IntakeFlowStart />} />
                <Route path='mobiel' element={<MobileTransactionsFlowStart />} />
                <Route path='vast' element={<FixedTransactionsFlowStart />} />
                <Route path='/inname' element={<Navigate to='start' replace />} />
              </Route>
              <Route path='/uitgifte' element={<IssuanceFlow />}>
                <Route path='start' element={<IssuanceFlowStart />} />
                <Route path='winkel' element={<StoreTransactionsFlowStart />} />
                <Route path='direct' element={<DirectTransactionsFlowStart />} />
                <Route path='dock' element={<DockTransactionsFlowStart />} />
                <Route path='/uitgifte' element={<Navigate to='start' replace />} />
              </Route>
              <Route path='/voorraadtelling' element={<InventoryManagementFlow />} />
              <Route path='/omboeken' element={<RebookItemStateFlow />} />
              <Route path='/idt' element={<InterDepotTransportFlow />} />
            </Routes>
          </Router>
        </BranchContextProvider>
      </QueryClientProvider>
    </RfhTheme>
  </I18nextProvider>
)

export default App
