import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { useActor, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { DivideTransaction } from 'src/components/IssuanceFlow/dockTransactions/DivideTransaction'
import { EditTransaction } from 'src/components/IssuanceFlow/dockTransactions/EditTransaction'
import { ListOfDockTransactions } from 'src/components/IssuanceFlow/dockTransactions/ListOfDockTransactions'
import { ReviewTransactions } from 'src/components/IssuanceFlow/dockTransactions/ReviewTransactions'
import { Layout } from 'src/components/Layouts'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import {
  issuanceDockTransactionsMachineModel,
  issuanceDockTransactionsService,
} from 'src/machines/issuanceDockTransactionsMachine'
import { IssuanceCreateFlowContext } from 'src/views/IssuanceFlow'

export type DockTransactionsDefaultViewProps = {
  service: typeof issuanceDockTransactionsService
}

const { RESET_ERROR } = issuanceDockTransactionsMachineModel.events

export const DockTransactionsFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service: mainFlowService } = useContext(IssuanceCreateFlowContext)

  const childTransactionsService = useMemo(() => {
    return mainFlowService.state
      ? (mainFlowService.state.children
          .issuanceDockTransactionsFlow as typeof issuanceDockTransactionsService)
      : issuanceDockTransactionsService
  }, [mainFlowService])

  const [, send] = useActor(childTransactionsService)

  const viewingListState = useSelector(childTransactionsService, currentState =>
    currentState.matches('viewing_list_of_transactions')
  )

  const reviewingTransactionsState = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('reviewing_transactions') ||
      matches('confirming_with_fust_pass') ||
      matches('completed_transactions') ||
      matches('submitting_divide_transactions') ||
      matches('submitting_transactions')
  )

  const editingState = useSelector(
    childTransactionsService,
    currentState =>
      currentState.matches('editing_transaction') ||
      currentState.matches('validating_transaction')
  )

  const dividingState = useSelector(childTransactionsService, ({ matches }) =>
    matches('dividing_transaction')
  )

  const scannedCustomerNumber = useSelector(
    childTransactionsService,
    currentState =>
      currentState?.context?.scannedFustPassContext?.scannedFustPass?.klant.nummer
  )
  const scannedCustomerName = useSelector(
    childTransactionsService,
    currentState =>
      currentState?.context?.scannedFustPassContext?.scannedFustPass?.klant.naam
  )

  const dataGridService = useSelector(
    childTransactionsService,
    ({ context: { transactionsDataGridService } }) => transactionsDataGridService
  )

  const customerNumberFromList = useSelector(
    dataGridService,
    ({ context: { selectedTransactions } }) => selectedTransactions[0]?.klantnummer
  )

  const customerNameFromList = useSelector(
    dataGridService,
    ({ context: { selectedTransactions } }) => selectedTransactions[0]?.klantNaam
  )

  const numberOfCustomers = useSelector(
    dataGridService,
    ({ context: { selectedTransactions } }) =>
      selectedTransactions
        .map(transaction => transaction.klantnummer)
        .filter((value, index, self) => self.indexOf(value) === index).length
  )

  const selectedTransactionsFromDataGrid = useSelector(
    dataGridService,
    ({ context: { selectedTransactions } }) => selectedTransactions
  )

  const error = useSelector(
    childTransactionsService,
    currentState => currentState.context.error
  )

  return (
    <>
      <Layout title={t('issuance.dock')}>
        <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />
        <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
          <>
            {viewingListState && (
              <ListOfDockTransactions service={childTransactionsService}>
                <TransactionsHeader
                  items={[
                    {
                      label: 'transporter',
                      value: `${scannedCustomerNumber} - ${scannedCustomerName}`,
                    },
                  ]}
                />
              </ListOfDockTransactions>
            )}
            {reviewingTransactionsState && (
              <ReviewTransactions
                service={childTransactionsService}
                numberOfCustomers={numberOfCustomers}
                selectedTransactions={selectedTransactionsFromDataGrid}
                customerNameFromList={customerNameFromList}
                customerNumberFromList={customerNumberFromList}
                scannedCustomerName={scannedCustomerName}
                scannedCustomerNumber={scannedCustomerNumber}
              />
            )}
            {editingState && (
              <EditTransaction service={childTransactionsService}>
                <TransactionsHeader
                  items={[
                    {
                      label: 'transporter',
                      value: `${scannedCustomerNumber} - ${scannedCustomerName}`,
                    },
                    {
                      label: 'customers',
                      value: `${customerNumberFromList} - ${customerNameFromList}`,
                    },
                  ]}
                />
              </EditTransaction>
            )}
            {dividingState && (
              <DivideTransaction service={childTransactionsService}>
                <TransactionsHeader
                  items={[
                    {
                      label: 'transporter',
                      value: `${scannedCustomerNumber} - ${scannedCustomerName}`,
                    },
                    {
                      label: 'customers',
                      value: `${customerNumberFromList} - ${customerNameFromList}`,
                    },
                  ]}
                />
              </DivideTransaction>
            )}
          </>
        </Box>
      </Layout>
    </>
  )
}
