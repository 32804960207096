import { AxiosError, AxiosResponse } from 'axios'
import { assign, interpret } from 'xstate'
import { createModel } from 'xstate/lib/model'

import {
  submitRebookItemState,
  validateRebookItemState,
} from 'src/services/RebookItemStateService'
import { Depot } from 'src/types/depot'
import { FustAmount } from 'src/types/fust'

export type RebookItemStateMachineContext = {
  selectedLocation?: Depot
  fustAmount: FustAmount[]
  error?: string
}

export const rebookItemStateMachineModel = createModel(
  {
    selectedLocation: undefined,
    fustAmount: [],
    error: undefined,
  } as RebookItemStateMachineContext,
  {
    events: {
      SELECT_LOCATION: (location: Depot) => ({ location }),
      CONTINUE: () => ({}),
      ASSIGN_ITEM_STATE: (fustAmount: FustAmount[]) => ({ fustAmount }),
      EDIT_ITEM_STATE: () => ({}),
      SUBMIT_ITEM_STATE: () => ({}),
      RESET_ERROR: () => ({}),
    },
  }
)

export const rebookItemStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCcwCMD2GDWBJALmALYDK+AhoQLLkDGAFgJYB2YAdObNi1APoA2GWpUYZmAYhIBRADJSAwgBVeMgPLyAgotyqAcolAAHDLEb5RzAyAAeiACwAmADQgAnogAcATjZe-XjwBmL0C7QIBWAEYABnCAXziXVEwcAmIySjAaBhZ2Tm5mPkFhczFxeT1tXQBVKStjU1LLJBtEBwcPNnC7ADYvSIceh36PB0iXdwRI8Icu6PnY6MGAdm7lnoSk9Cw8QlIKajomVjZknZ5eM2JeWAOwcQ0SElwAcV1eXEUpKl4SRS06i0GmYLFZbAhwks2MsYl4HIFloNostAj07BNEAE2EFAoFInZwl55ut4ZsQGdUnsMoccicKQU+FciDc7uVKrgaoCjCYQWIwYhltE7L4PGEPJCess7JEvOEMVNBb5-H5JdNAkEPGT6Wl9plssd2KgAG6MMAAdwuTJZmXEUgAIp8Pl8fn8AfUeU1+Qh1pEutMhssPNFRsHxm5EANAmwFtE8UNUas7HYtdtKek7vrcqcwCbzZa9tbCJJqgAhKiOz7fX7-L7uxqglrgyKRYK+BEzcLinr4lvyhzBuYLIbRFsdFMpXbpvVHLOwACuaCIZnMhUuBduNogYnYLCNOENqcnuppBrY88Xy-z1w3hAQu6EIjEAG1ogBdOu85qgJvd4WE1GyridirAE8oyrMOK4tEsohHYQobIk5KHjq1JZDOJznku+Aroy66smAyDIBgyBsIY-CUAAZsRRDZhOKEZuh7CYZeq5WjeYB3swe4lBYL7vkCHoNt+EYtj4ISrA4nbhL+vbhlMPTRGwdj+FJ0oeD07YJIhzAYBAcBWNqVIMbSeRcBcxSPl+IDAp6jaIOE4RsJEaKxH0+JBM5XhgaM0YxqiLaBPMgTjjs9HTiZtHnKxeGZB+tnCRC8xsME0RoqiqV4qEPRgUS2K4kBgxEpJ8SIYZU4nlmxqmha0XXnccVCa0CAhJ04kEv2yyrJE6k5VG+UFX0SzdMmpXIUZ4Wnsx2FXsy7ENXydlTBErWpZJaIhFJyxgd2eWBB0cLhCi0HtCFabHmhEWwPQGA1Xw860LQcCwLwECMOQghQPNVlNkEPROQ4ylojKwHIt5f2Qe0ETSoSI1bHR40VawX1et13laXEQA */
  rebookItemStateMachineModel.createMachine(
    {
      context: rebookItemStateMachineModel.initialContext,
      tsTypes: {} as import('./rebookItemStateMachine.typegen').Typegen0,
      schema: {
        services: {} as {
          submitItemState: { data: AxiosResponse<void> }
          validateItemState: { data: AxiosResponse<FustAmount[]> }
        },
      },
      predictableActionArguments: true,
      id: 'rebookItemStateMachine',
      initial: 'asking_location',
      states: {
        asking_location: {
          on: {
            SELECT_LOCATION: {
              actions: 'assignLocation',
            },
            CONTINUE: {
              cond: 'hasSelectedLocation',
              target: 'rebooking_item_state',
            },
          },
        },
        rebooking_item_state: {
          on: {
            ASSIGN_ITEM_STATE: {
              actions: 'assignItemState',
            },
            CONTINUE: {
              cond: 'hasFustAmount',
              target: 'validating_item_state',
            },
          },
        },
        validating_item_state: {
          invoke: {
            src: 'validateItemState',
            onDone: { target: 'reviewing_item_state' },
            onError: { target: 'rebooking_item_state', actions: 'assignError' },
          },
          tags: 'loading',
        },
        reviewing_item_state: {
          on: {
            EDIT_ITEM_STATE: {
              target: 'rebooking_item_state',
            },
            SUBMIT_ITEM_STATE: {
              target: 'submitting_item_state',
            },
          },
        },
        submitting_item_state: {
          invoke: {
            src: 'submitItemState',
            onDone: [
              {
                target: 'showing_success_dialog',
              },
            ],
            onError: [
              {
                target: 'reviewing_item_state',
                actions: 'assignError',
              },
            ],
          },
        },
        showing_success_dialog: {
          type: 'final',
        },
      },
      on: {
        RESET_ERROR: {
          actions: 'resetError',
        },
      },
    },
    {
      guards: {
        hasSelectedLocation: context => !!context.selectedLocation,
        hasFustAmount: context => context.fustAmount.length > 0,
      },
      actions: {
        assignError: assign((_context, event) => ({
          error: String((event.data as AxiosError).response?.data),
        })),
        resetError: assign(_ => ({ error: undefined })),
        assignLocation: assign((_context, event) => ({
          selectedLocation: event.location,
        })),
        assignItemState: assign((_context, event) => ({
          fustAmount: event.fustAmount,
        })),
      },
      services: {
        submitItemState: async context =>
          submitRebookItemState(context.selectedLocation!, context.fustAmount),
        validateItemState: async context =>
          validateRebookItemState(context.selectedLocation!, context.fustAmount),
      },
    }
  )

export const rebookItemStateService = interpret(rebookItemStateMachine)
