import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { BaseDialog, BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import abortedScanIllustration from 'src/illustrations/RFH_illustratie_niet-opgeslagen.svg'

export interface AbortedTransactionDialogProps extends BaseDialogProps {
  title: string
}

export const AbortedTransactionDialog: FC<AbortedTransactionDialogProps> = ({
  isOpen,
  title,
  onSubmit,
  setIsOpen,
}) => {
  const { t } = useTranslation()
  return (
    <BaseDialog setIsOpen={setIsOpen} isOpen={isOpen} onSubmit={onSubmit}>
      <Stack
        gap={2.5}
        direction={'column'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <img
          src={abortedScanIllustration}
          alt='Aborted scan illustration'
          width={152}
        />
        <RfhTypography variant={'text-micro-bold'}>{title}</RfhTypography>
        <Button fullWidth variant={'contained'} color={'primary'} onClick={onSubmit}>
          {t('button.continue')}
        </Button>
      </Stack>
    </BaseDialog>
  )
}
