import { ChangeEvent, FC, MouseEventHandler, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Box, { BoxProps } from '@mui/material/Box'
import DataGrid, { DataGridProps, Row } from '@rfh/ui/components/DataGrid/DataGrid'
import RfhCheckbox from '@rfh/ui/components/Inputs/RfhCheckbox'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { FustTransaction, TransactionStatus } from 'src/types/transaction'

const DataCellComponent: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      width={'100%'}
      aria-label={'data-value-cell'}
      whiteSpace={'nowrap'}
      component={'span'}
      {...rest}
    >
      {children}
    </Box>
  )
}
export type MultiSelectableDataGridColumn = {
  component: JSX.Element
}
export type MultiSelectableDataGridRow = {
  id: number | string
  columns: MultiSelectableDataGridColumn[]
}
export type MultiSelectableDataGridProps = Omit<DataGridProps, 'rows'> & {
  defaultHeaderRow: Row
  onDataCellClickHandler?: MouseEventHandler
  onItemChecked: (
    selectedRows: MultiSelectableDataGridRow[],
    selectedRow: MultiSelectableDataGridRow,
    selected: boolean
  ) => void
  rows: MultiSelectableDataGridRow[]
  updating: boolean
  listOfTransactions?: FustTransaction[]
}
export const MultiSelectableDataGrid: FC<MultiSelectableDataGridProps> = ({
  defaultHeaderRow,
  onDataCellClickHandler,
  onItemChecked,
  rows,
  updating,
  listOfTransactions,
  ...rest
}) => {
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState<MultiSelectableDataGridRow[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    selectedRowId: string | number
  ) => {
    const currentTargetRow = rows.find(
      item => item.id === selectedRowId
    ) as MultiSelectableDataGridRow

    if (event.target.checked) {
      const currentSelectedRows = [...selectedRows, currentTargetRow]
      onItemChecked(currentSelectedRows, currentTargetRow, true)
      setSelectedRows(currentSelectedRows)
    } else {
      const currentSelectedRows = selectedRows.filter(row => row.id !== selectedRowId)
      onItemChecked(currentSelectedRows, currentTargetRow, false)
      setSelectedRows(currentSelectedRows)
    }
  }

  const createRowsColumns = (): Row[] => {
    return rows.map(row => {
      const status = listOfTransactions?.find(
        transaction => transaction.id === row.id
      )?.status

      return {
        values: [
          <RfhCheckbox
            className={status === TransactionStatus['In behandeling'] ? 'reserved' : ''}
            label={''}
            disabled={updating}
            handleChange={event => {
              handleCheckboxChange(event, row.id)
            }}
            inputRef={inputRef}
          />,
          ...row.columns.map((column: MultiSelectableDataGridColumn) => {
            return (
              <DataCellComponent
                onClick={event => {
                  onDataCellClickHandler && onDataCellClickHandler(event)
                }}
              >
                {column.component}
              </DataCellComponent>
            )
          }),
        ],
      }
    })
  }

  const dataGridRows = [defaultHeaderRow, ...createRowsColumns()]

  useEffect(() => {
    const reservedTransactionRows = document.getElementsByClassName('reserved')

    for (const row of reservedTransactionRows) {
      const rowContainer = row.closest('.MuiGrid-root.MuiGrid-container')
      rowContainer && rowContainer.classList.add('reserved')
    }

    if (inputRef.current && listOfTransactions && listOfTransactions.length === 1) {
      inputRef.current?.click()
    }
  }, [listOfTransactions])

  return (
    <Box
      sx={{
        '& .MuiGrid-root.MuiGrid-container': {
          alignItems: 'center',
        },
        '& .MuiGrid-root.MuiGrid-container.reserved': {
          backgroundColor: '#FBDFC6',
        },
      }}
    >
      <DataGrid headColor={RfhColors.leafGreen} rows={dataGridRows} {...rest} />
      {rows.length <= 0 && (
        <Box
          bgcolor={RfhColors.white}
          border={`1px solid ${RfhColors.lightGrey}`}
          p={1}
        >
          {t('transactions.noTransactions')}
        </Box>
      )}
    </Box>
  )
}
