import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import { DataGrid } from '@rfh/ui/components/DataGrid'
import { Row } from '@rfh/ui/components/DataGrid/DataGrid'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'
import { useActor, useSelector } from '@xstate/react'

import { FlowView } from 'src/components/FlowView/FlowView'
import { FlowViewActions } from 'src/components/FlowView/FlowViewActions'
import { FlowViewContent } from 'src/components/FlowView/FlowViewContent'
import { CustomerNumberDialog } from 'src/components/dialogs/CustomerNumberDialog'
import { ScanFustPassFlowDialogs } from 'src/components/dialogs/ScanFustPassFlowDialogs'
import { TransactionsMobileDetailDialog } from 'src/components/dialogs/TransactionsMobileDetailDialog'
import nlTranslations from 'src/i18n/nl/translations.json'
import {
  intakeMobileTransactionsMachineModel,
  intakeMobileTransactionsService,
} from 'src/machines/intakeMobileTransactionsMachine'
import { FustTransaction, TransactionStatus } from 'src/types/transaction'

const dataGridLayout = [4, 6, 2]

const ClickableCell = styled(Box)(() => ({
  cursor: 'pointer',
}))

const {
  LOAD_TRANSACTIONS,
  CONTINUE_UNANNOUNCED,
  CONTINUE,
  CLOSE_TRANSACTION_DETAIL_VIEW,
  ASSIGN_SELECTED_TRANSACTION,
  ASSIGN_SCANNED_FUST_PASS_CONTEXT,
  CLOSE_FUST_PASS_NUMBER_DIALOG,
  CLOSE_CUSTOMER_NUMBER_DIALOG,
  ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER,
} = intakeMobileTransactionsMachineModel.events

const createRowsItems = (
  transactions: FustTransaction[],
  handleSend: typeof intakeMobileTransactionsService.send
) => {
  return transactions.map((transaction, index) => {
    return {
      values: [
        <ClickableCell
          className={
            transaction.status === TransactionStatus['In behandeling'] ? 'reserved' : ''
          }
          onClick={() => {
            handleSend(ASSIGN_SELECTED_TRANSACTION(transaction))
          }}
        >
          {transaction.klantNaam}
        </ClickableCell>,
        <ClickableCell
          onClick={() => {
            handleSend(ASSIGN_SELECTED_TRANSACTION(transaction))
          }}
        >
          {transaction.klantLocatie}
        </ClickableCell>,
        <ClickableCell
          onClick={() => {
            handleSend(ASSIGN_SELECTED_TRANSACTION(transaction))
          }}
        >
          {transaction.fustAantallen
            .map(({ stapelwagens }) => stapelwagens)
            .reduce((previousValue, currentValue) => previousValue + currentValue)}
        </ClickableCell>,
      ],
    }
  })
}

const defaultHeaderRow: Row = {
  values: [
    nlTranslations.transactions.header.customers,
    nlTranslations.location,
    nlTranslations.countTable.headings.stw,
  ],
  header: true,
}

export const Transactions: FC<{ service: typeof intakeMobileTransactionsService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [transactionsRows, setTransactionsRows] = useState<Row[]>([
    {
      values: [],
    },
  ])

  const [state, send] = useActor(service)

  const transactions = useSelector(
    service,
    currentState => currentState.context.listOfTransactions
  )

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction
  )

  const viewingTransactionDetails = useSelector(service, ({ matches }) =>
    matches('viewing_transaction_details')
  )
  const viewingFustPassFlowDialogs = useSelector(
    service,
    ({ matches }) =>
      matches('handling_fust_pass_scan') ||
      matches('handling_unannounced_fust_pass_scan')
  )

  useEffect(() => {
    send(LOAD_TRANSACTIONS())
  }, [])

  useEffect(() => {
    if (transactions.length) {
      setTransactionsRows([defaultHeaderRow, ...createRowsItems(transactions, send)])
    } else {
      setTransactionsRows([defaultHeaderRow])
    }
  }, [transactions])

  useEffect(() => {
    if (transactions.length && transactionsRows.length) {
      const reservedTransactionRows = document.getElementsByClassName('reserved')

      for (const row of reservedTransactionRows) {
        const rowContainer = row.closest('.MuiGrid-root.MuiGrid-container')
        rowContainer && rowContainer.classList.add('reserved')
      }
    }
  }, [transactions, transactionsRows])

  return (
    <>
      <ScanFustPassFlowDialogs
        onCompleted={scanContext => {
          send(ASSIGN_SCANNED_FUST_PASS_CONTEXT(scanContext))
        }}
        startProcess={viewingFustPassFlowDialogs}
        handleClose={() => {
          send(CLOSE_FUST_PASS_NUMBER_DIALOG())
        }}
        selectedTransactions={selectedTransaction ? [selectedTransaction] : undefined}
        hasMasterpasOverride={true}
      />
      <CustomerNumberDialog
        isOpen={state.matches('asking_customer_number')}
        setIsOpen={value => {
          if (!value) {
            send(CLOSE_CUSTOMER_NUMBER_DIALOG())
          }
          return value
        }}
        onSubmit={value => {
          send(ASSIGN_UNANNOUNCED_CUSTOMER_NUMBER(value.nummer, value.naam))
          return Promise.resolve()
        }}
      />
      {viewingTransactionDetails && (
        <TransactionsMobileDetailDialog
          open
          handleClose={() => send(CLOSE_TRANSACTION_DETAIL_VIEW())}
          transaction={selectedTransaction!}
          onActionHandler={() => send(CONTINUE())}
        />
      )}
      <FlowView loading={state.hasTag('loading')}>
        <FlowViewContent>
          <Stack
            direction={'column'}
            gap={2}
            sx={{
              '& .MuiGrid-root.MuiGrid-container.reserved': {
                backgroundColor: '#FBDFC6',
              },
            }}
          >
            <RfhTypography variant={'subtitle1'}>
              {t('transactions.newTransactions')}
            </RfhTypography>
            <DataGrid
              label={t('intake.transactionList')}
              layout={dataGridLayout}
              headColor={RfhColors.leafGreen}
              rows={transactionsRows}
            />
            {transactions.length === 0 && (
              <Box
                mt={-2}
                bgcolor={RfhColors.white}
                border={`1px solid ${RfhColors.lightGrey}`}
                p={1}
              >
                {t('transactions.noTransactions')}
              </Box>
            )}
          </Stack>
        </FlowViewContent>
        <FlowViewActions>
          <Button
            fullWidth
            variant={'contained'}
            onClick={() => send(LOAD_TRANSACTIONS())}
          >
            {t('button.refresh')}
          </Button>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={() => send(CONTINUE_UNANNOUNCED())}
          >
            {t('button.unannounced')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
