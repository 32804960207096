import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useInterpret, useSelector, useActor } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { Layout } from 'src/components/Layouts'
import { Location } from 'src/components/RebookItemStateFlow/Location'
import { Rebooking } from 'src/components/RebookItemStateFlow/Rebooking'
import { Review } from 'src/components/RebookItemStateFlow/Review'
import {
  rebookItemStateMachine,
  rebookItemStateMachineModel,
} from 'src/machines/rebookItemStateMachine'

const { RESET_ERROR } = rebookItemStateMachineModel.events

export const RebookItemStateFlow: FC = () => {
  const { t } = useTranslation()
  const defaultService = useInterpret(rebookItemStateMachine, {
    devTools: process.env.REACT_APP_XSTATE_INSPECT !== undefined,
  })

  const [, send] = useActor(defaultService)

  const askingLocation = useSelector(defaultService, state =>
    state.matches('asking_location')
  )

  const rebookingItemState = useSelector(
    defaultService,
    state =>
      state.matches('rebooking_item_state') || state.matches('validating_item_state')
  )

  const reviewingItemState = useSelector(
    defaultService,
    state =>
      state.matches('reviewing_item_state') ||
      state.matches('submitting_item_state') ||
      state.matches('showing_success_dialog')
  )

  const error = useSelector(defaultService, currentState => currentState.context.error)

  return (
    <Layout title={t('rebook.general')}>
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />
      {askingLocation && <Location service={defaultService} />}
      {rebookingItemState && <Rebooking service={defaultService} />}
      {reviewingItemState && <Review service={defaultService} />}
    </Layout>
  )
}
