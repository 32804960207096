import { FC } from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import nlTranslations from 'src/i18n/nl/translations.json'
import SuccessIllustration from 'src/illustrations/RFH_success_illustration.svg'

export type SuccessDialogProps = {
  isOpen: boolean
  handleClose: () => void
  title?: string
}

export const SuccessDialog: FC<SuccessDialogProps> = ({
  isOpen,
  handleClose,
  title = nlTranslations.dialog.successTransaction,
}) => {
  return (
    <Dialog open={isOpen} maxWidth={'xs'}>
      <DialogContent>
        <DialogActions onClick={handleClose}>
          <Stack direction={'column'} gap={1}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              width={'100%'}
              mb={3}
            >
              <Box mb={2}>
                <img width={'100%'} src={SuccessIllustration} alt={'Success'} />
              </Box>
              <RfhTypography variant={'h5'} textAlign='center'>
                {title}
              </RfhTypography>
            </Box>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
