import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useInterpret, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { Layout } from 'src/components/Layouts'
import { AbortedTransactionDialog } from 'src/components/dialogs/AbortedTransactionDialog'
import { ArrivalLocation } from 'src/components/interDepotTransportFlow/ArrivalLocation'
import { Composing } from 'src/components/interDepotTransportFlow/Composing'
import { DepartureLocation } from 'src/components/interDepotTransportFlow/DepartureLocation'
import { Review } from 'src/components/interDepotTransportFlow/Review'
import {
  interDepotTransportMachine,
  interDepotTransportMachineModel,
} from 'src/machines/interDepotTransportMachine'

const { RESET_ERROR, CONTINUE } = interDepotTransportMachineModel.events

export const InterDepotTransportFlow: FC = () => {
  const { t } = useTranslation()
  const service = useInterpret(interDepotTransportMachine, {
    devTools: process.env.REACT_APP_XSTATE_INSPECT !== undefined,
  })

  const { send } = service

  const askingDepartureLocation = useSelector(service, state =>
    state.matches('asking_departure_location')
  )

  const composingTransaction = useSelector(
    service,
    state =>
      state.matches('composing_transaction') ||
      state.matches('showing_warning_dialog') ||
      state.matches('validating_transaction') ||
      state.matches('updating_status')
  )

  const askingArrivalLocation = useSelector(service, state =>
    state.matches('asking_arrival_location')
  )

  const reviewTransaction = useSelector(
    service,
    state =>
      state.matches('reviewing_transaction') ||
      state.matches('submitting_transaction') ||
      state.matches('showing_success_dialog')
  )

  const error = useSelector(service, currentState => currentState.context.error)

  const checkingAbortedTransactionState = useSelector(service, state =>
    state.matches('showing_aborted_transaction_dialog')
  )

  return (
    <Layout title={t('interDepotTransport.general')}>
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />
      {checkingAbortedTransactionState && (
        <AbortedTransactionDialog
          title={t('dialog.abortedIDT')}
          isOpen={checkingAbortedTransactionState}
          onSubmit={
            /* istanbul ignore next */
            () => {
              send(CONTINUE())
            }
          }
        />
      )}
      {askingDepartureLocation && <DepartureLocation service={service} />}
      {composingTransaction && <Composing service={service} />}
      {askingArrivalLocation && <ArrivalLocation service={service} />}
      {reviewTransaction && <Review service={service} />}
    </Layout>
  )
}
