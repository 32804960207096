/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { produce } from 'immer'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { handleChange, SplitOrEditTable } from 'src/components/SplitOrEditTable'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import { issuanceDirectTransactionsService } from 'src/machines/issuanceDirectTransactionsMachine'
import { issuanceDirectTransactionsMachineModel } from 'src/machines/issuanceDirectTransactionsMachine'

const { UPDATE_SELECTED_TRANSACTION_FUST_AMOUNT, HANDLE_EDIT_TRANSACTION } =
  issuanceDirectTransactionsMachineModel.events

export type EditTransactionProps = {
  service: typeof issuanceDirectTransactionsService

  customerName?: string
}

export const EditTransaction: FC<EditTransactionProps> = ({
  customerName,
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const selectedTransaction = useSelector(
    service,
    currentState => currentState.context.selectedTransaction!
  )

  const updatedFustAmount = useSelector(
    service,
    currentState => currentState.context.updatedFustAmount!
  )

  const handleSplitChange: handleChange = (key, amount) => event => {
    const fustAmount = produce(updatedFustAmount, draft => {
      const index = draft.findIndex(
        editAmount => editAmount.fustCode === amount.fustCode
      )

      /* istanbul ignore next */
      if (index !== -1) {
        // eslint-disable-next-line
        // @ts-ignore
        draft[index][key] = Number(event.target.value)
      }
    })

    send(UPDATE_SELECTED_TRANSACTION_FUST_AMOUNT(fustAmount))
  }
  // ignored bc scenario is not realistic, but this check is defensive programming
  /* istanbul ignore next */
  if (!selectedTransaction && !updatedFustAmount) {
    return null
  }

  return (
    <FlowView loading={state.hasTag('loading')} p={0}>
      <FlowViewContent>
        <TransactionsHeader items={[{ label: 'customer', value: customerName }]} />

        <Stack direction={'column'} gap={2}>
          <Box px={2} pt={2}>
            <RfhTypography variant={'subtitle1'}>
              {t('transactions.edit')}
            </RfhTypography>
          </Box>

          <SplitOrEditTable
            fustAmount={updatedFustAmount}
            handleChange={handleSplitChange}
            tableType='edit'
          />
        </Stack>
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          onClick={() => {
            send(HANDLE_EDIT_TRANSACTION(selectedTransaction.id, updatedFustAmount))
          }}
        >
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
