import { FC } from 'react'

import { useActor } from '@xstate/react'

import { ReviewSingleTransactionView } from 'src/components/IssuanceFlow/views/ReviewSingleTransactionView'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import {
  issuanceDirectTransactionsMachineModel,
  issuanceDirectTransactionsService,
} from 'src/machines/issuanceDirectTransactionsMachine'

export type ReviewSingleTransactionProps = {
  service: typeof issuanceDirectTransactionsService
  customerName?: string
}

const { HANDLE_TRANSACTION, ASSIGN_SELECTED_TRANSACTION } =
  issuanceDirectTransactionsMachineModel.events

export const ReviewSingleTransaction: FC<ReviewSingleTransactionProps> = ({
  service,
  customerName,
}) => {
  const [state, send] = useActor(service)

  return (
    <ReviewSingleTransactionView
      loading={state.hasTag('loading')}
      submissionClickHandler={() => {
        send(HANDLE_TRANSACTION())
      }}
      selectedTransactions={state.context.selectedTransactions}
      isDividable={true}
      isEditable={false}
      onSplitTransactionHandler={currentTransaction => {
        send(ASSIGN_SELECTED_TRANSACTION(currentTransaction))
      }}
    >
      <TransactionsHeader
        items={[
          {
            label: 'transporter',
            value: `${state.context.selectedTransactions[0].vervoerderNummer} - ${state.context.selectedTransactions[0].vervoerderNaam}`,
          },
          { label: 'customers', value: customerName },
        ]}
      />
    </ReviewSingleTransactionView>
  )
}
