import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import {
  AppBar as RfhAppBar,
  AppBarList,
  AppBarListItem,
  AppBarNativeSelect,
} from '@rfh/ui/components/Layout/AppBar'

import { authContext, DO_NOT_LOGIN } from 'src/configs/adalConfig'
import { useBranch } from 'src/hooks/useBranch'
import { Branch } from 'src/types/branch'
import { flows } from 'src/views/FlowSelection'

/*
 * when we release a specific version we follow the rule of bumping up version number in package.json
 * hence this version number is reliable number to communicate to stakeholders for verifying and testing purposes
 * */
import packageJson from '../../package.json'

export const AppBar: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const { branch, setBranch } = useBranch()

  const navigate = useNavigate()

  const handleMenuClick = (to: string): void => {
    navigate(to)
    setMenuOpen(false)
  }

  const menuContent = (
    <>
      <AppBarList>
        {flows.map(flow => (
          <AppBarListItem
            key={flow.label}
            text={flow.label}
            onClick={() => handleMenuClick(flow.route)}
          />
        ))}
      </AppBarList>

      <AppBarList subheader={t('appBar.account')}>
        <AppBarListItem
          text={
            DO_NOT_LOGIN
              ? /* istanbul ignore next */
                t('appBar.rfhProfile')
              : authContext.getCachedUser()?.userName
          }
        />
        <AppBarListItem
          text={t('appBar.logout')}
          onClick={() => authContext.logOut()}
        />
        <AppBarListItem
          text={
            <AppBarNativeSelect
              inputProps={{ 'aria-label': t('appBar.selectLocation') }}
              value={branch}
              onChange={event => {
                setBranch(event.target.value as Branch)
                setMenuOpen(false)
              }}
            >
              {Object.values(Branch).map(branchName => (
                <option value={branchName}>
                  {t('appBar.location')} {branchName}
                </option>
              ))}
            </AppBarNativeSelect>
          }
        />
      </AppBarList>
    </>
  )

  return (
    <Box
      sx={{
        pt: 'env(safe-area-inset-top)',
        pl: 'env(safe-area-inset-left)',
        pr: 'env(safe-area-inset-right)',
      }}
    >
      <Box>
        <RfhAppBar
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          menuOpen={menuOpen}
          menuContent={menuContent}
          onLogoClick={() => {
            navigate('/')
            setMenuOpen(false)
          }}
          title={title}
          menuOpenTitle={t('appBar.menu')}
          versionTag={`versie ${packageJson.version}`}
        />
      </Box>
    </Box>
  )
}
