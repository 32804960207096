import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DatePicker from '@rfh/ui/components/DatePickers/DatePicker'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { CalendarIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import { inventoryManagementMachineModel } from 'src/machines/inventoryManagementMachine'
import { Depot } from 'src/types/depot'
import { InventoryManagementFlowContext } from 'src/views/InventoryManagementFlow'

const { ASSIGN_SELECTED_DATE, ASSIGN_SELECTED_DEPOT, CONTINUE } =
  inventoryManagementMachineModel.events

export const PreInformation: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(InventoryManagementFlowContext)
  const [state, send] = useActor(service)
  return (
    <FlowView loading={state.hasTag('loading')}>
      <FlowViewContent>
        <RfhTypography variant={'subtitle1'}>
          {t('inventoryManagement.general')}
        </RfhTypography>
        <Box
          sx={{
            // @todo in @rfh/ui fixen
            '& .react-calendar__navigation__next-button:disabled, .react-calendar__navigation__prev-button:disabled':
              {
                display: 'none',
              },
            '& .react-calendar__month-view__days__day--weekend:disabled': {
              color: 'rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <DatePicker
            maxDate={new Date()}
            locale={'nl'}
            nativeInputAriaLabel={t('selectDate')}
            onChange={date => send(ASSIGN_SELECTED_DATE(date))}
            calendarIcon={
              <CalendarIcon
                style={{ fontSize: '1rem', color: RfhColors.dutchOrange }}
              />
            }
            value={state.context.selectedDate}
          />
        </Box>
        <Box mt={3.5}>
          <DepotsAutocomplete
            value={state?.context?.selectedDepot}
            onChange={(_event, value) => {
              send(ASSIGN_SELECTED_DEPOT(value as Depot))
            }}
          />
        </Box>
      </FlowViewContent>
      <FlowViewActions>
        <Button
          variant='contained'
          onClick={() => {
            send(CONTINUE())
          }}
          disabled={!state.can('CONTINUE')}
        >
          {t('button.continue')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
