import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import { DepotsAutocomplete } from 'src/components/inputs/DepotsAutocomplete'
import {
  intakeMobileTransactionsMachineModel,
  intakeMobileTransactionsService,
} from 'src/machines/intakeMobileTransactionsMachine'
import { Depot } from 'src/types/depot'

/* eslint-disable @typescript-eslint/no-non-null-assertion */

type ReturnLocationProps = {
  service: typeof intakeMobileTransactionsService
}

const { ASSIGN_SELECTED_RETURN_LOCATION, CONTINUE, CLOSE_SUCCESS_DIALOG } =
  intakeMobileTransactionsMachineModel.events

export const ReturnLocation: FC<ReturnLocationProps> = ({ service }) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)

  const returnLocation = useSelector(
    service,
    currentState => currentState.context.selectedReturnLocation
  )

  return (
    <>
      <SuccessDialog
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => send(CLOSE_SUCCESS_DIALOG())}
      />
      <FlowView loading={state.hasTag('loading')}>
        <FlowViewContent>
          <RfhTypography pb={2} variant={'subtitle1'}>
            {t('inputs.scanOrSearchLocations')}
          </RfhTypography>

          <DepotsAutocomplete
            value={returnLocation}
            onChange={(_, newValue) => {
              /* istanbul ignore else */
              if (newValue) {
                send(ASSIGN_SELECTED_RETURN_LOCATION(newValue as Depot))
              }
            }}
          />
        </FlowViewContent>
        <FlowViewActions>
          <RfhButton
            onClick={() => send(CONTINUE())}
            variant='block--contained'
            disabled={!state.can('CONTINUE')}
          >
            {t('button.confirm')}
          </RfhButton>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
