import format from 'date-fns/format'

import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import EditIcon from '@rfh/ui/shared/floriday-icons/icons/EditIcon'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { RoundIconButton } from 'src/components/RoundIconButton'
import { ValidatedCountTable } from 'src/components/ValidatedCountTable'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import { inventoryManagementMachineModel } from 'src/machines/inventoryManagementMachine'
import { FustAmountKeys } from 'src/types/fust'
import { InventoryManagementFlowContext } from 'src/views/InventoryManagementFlow'

import { TransactionsHeader } from '../TransactionsHeader'

const {
  EDIT_INVENTORY_FUST_AMOUNT,
  SUBMIT_INVENTORY_FUST_AMOUNT,
  RESET_TO_INITIAL_STATE,
} = inventoryManagementMachineModel.events

export const ReviewInventoryCount: FC = () => {
  const { t } = useTranslation()
  const { service } = useContext(InventoryManagementFlowContext)
  const [state, send] = useActor(service)

  const isBulk = useSelector(service, currentState => currentState.context.isBulk)

  const columns: FustAmountKeys[] = isBulk
    ? ['fustCode', 'itemState', 'slepen', 'stapelwagens', 'stuks']
    : ['fustCode', 'itemState', 'stapelwagens', 'stapels', 'stuks']

  return (
    <>
      <SuccessDialog
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => {
          send(RESET_TO_INITIAL_STATE())
        }}
      />
      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <TransactionsHeader
            items={[
              {
                label: 'date',
                value: format(new Date(state.context.selectedDate), 'dd-MM-yyyy'),
              },
              {
                label: 'depot',
                value: String(state?.context?.selectedDepot?.naam),
              },
            ]}
          />
          <Stack
            direction={'row'}
            px={2}
            py={2}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <RfhTypography variant={'subtitle1'}>
              {t('inventoryManagement.validateCount')}
            </RfhTypography>
            <RoundIconButton
              variant='outlined'
              icon={<EditIcon />}
              label={t('button.edit')}
              onClick={() => {
                send(EDIT_INVENTORY_FUST_AMOUNT())
              }}
            />
          </Stack>
          <ValidatedCountTable
            columns={columns}
            isAnnouncedTransaction={true}
            rows={state.context.validatedFustAmount}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() => {
              send(SUBMIT_INVENTORY_FUST_AMOUNT())
            }}
          >
            {t('button.handle')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
