import { AxiosPromise } from 'axios'
import format from 'date-fns/format'

import { Depot } from 'src/types/depot'
import { FustAmount } from 'src/types/fust'
import { api } from 'src/utils/api'

export const validateInventoryFustAmount = (
  depotCode: Depot['code'],
  fustAmount: FustAmount[]
): AxiosPromise<FustAmount[]> =>
  api.post(`/voorraad/${depotCode}/controleer`, fustAmount)

export const submitInventoryFustAmount = (
  depotCode: Depot['code'],
  auditDate: Date,
  fustAmount: FustAmount[]
): AxiosPromise<void> =>
  api.post(
    `/voorraad/${depotCode}/verwerken/${format(auditDate, 'yyyy-MM-dd')}`,
    fustAmount
  )

export const loadPreviouslyCountFustAmount = (
  depotCode: Depot['code']
): AxiosPromise<FustAmount[]> => api.get(`/voorraad/laatste/${depotCode}`)
