import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import { useActor, useSelector } from '@xstate/react'

import { ErrorNotification } from 'src/components/ErrorNotification'
import { Count } from 'src/components/IntakeFlow/Count'
import { ValidatedCount } from 'src/components/IntakeFlow/ValidatedCount'
import { Transactions } from 'src/components/IntakeFlow/intakeFixed/Transactions'
import { Layout } from 'src/components/Layouts'
import {
  TransactionsHeaderItem,
  TransactionsHeaderProps,
} from 'src/components/TransactionsHeader'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  intakeFixedTransactionsMachineModel,
  intakeFixedTransactionsService,
} from 'src/machines/intakeFixedTransactionsMachine'
import { IntakeCreateFlowContext } from 'src/views/IntakeFlow'

const { RESET_ERROR, CLOSE_SUCCESS_DIALOG } = intakeFixedTransactionsMachineModel.events

export const FixedTransactionsFlowStart: FC = () => {
  const { t } = useTranslation()
  const { service: mainFlowService } = useContext(IntakeCreateFlowContext)

  const childTransactionsService = mainFlowService.state
    ? (mainFlowService.state.children
        .intakeFixedTransactionsMachine as typeof intakeFixedTransactionsService)
    : intakeFixedTransactionsService

  const [state, send] = useActor(childTransactionsService)

  const navigate = useNavigate()

  const error = useSelector(
    childTransactionsService,
    currentState => currentState.context.error
  )

  const viewingListOfTransactions = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('viewing_list_of_transactions') ||
      matches('loading_transactions') ||
      matches('asking_customer_number') ||
      matches('evaluatue_customer_type')
  )

  const viewingCount = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('count_transaction') ||
      matches('validating_unannounced_transaction') ||
      matches('validating_transaction')
  )

  const viewingReviewTransaction = useSelector(
    childTransactionsService,
    ({ matches }) =>
      matches('reviewing_transaction') ||
      matches('confirming_with_fust_pass') ||
      matches('handling_transactions_submission') ||
      matches('showing_success_dialog')
  )

  const confirmingWithFustPassState = useSelector(
    childTransactionsService,
    ({ matches }) => matches('confirming_with_fust_pass')
  )

  const transactionsFixedHeaderItems: TransactionsHeaderItem[] = [
    {
      label: 'depot',
      value: `${state.context.selectedDepot?.naam}`,
    },
    ...(state.context.selectedAuthority
      ? ([
          {
            label: 'transporter',
            value: `${state.context.scannedFustPassContext?.scannedFustPass?.klant.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
          },
          {
            label: 'customer',
            value: state.context.scannedFustPassContext?.additionalCustomerNumber
              ? `${state.context.scannedFustPassContext?.additionalCustomerNumber} - ${state.context.scannedFustPassContext.additionalCustomerName}`
              : `${state.context.selectedAuthority.id} - ${state.context.selectedAuthority.name}`,
          },
        ] as TransactionsHeaderProps['items'])
      : ([
          {
            label: 'customer',
            value: `${state.context.scannedFustPassContext?.scannedFustPass?.klant.nummer} - ${state.context.scannedFustPassContext?.scannedFustPass?.klant.naam}`,
          },
        ] as TransactionsHeaderProps['items'])),
  ]

  return (
    <Layout title={t('intake.fixed')}>
      <SuccessDialog
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => {
          navigate('/inname/start')
          send(CLOSE_SUCCESS_DIALOG())
        }}
      />
      <ErrorNotification error={error} onClose={() => send(RESET_ERROR())} />

      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        {viewingListOfTransactions && (
          <Transactions service={childTransactionsService} />
        )}
        {viewingCount && (
          <Count
            service={childTransactionsService}
            transactionsHeaderItems={transactionsFixedHeaderItems}
          />
        )}
        {viewingReviewTransaction && (
          <ValidatedCount
            service={childTransactionsService}
            confirmingWithFustPassState={confirmingWithFustPassState}
            transactionsHeaderItems={transactionsFixedHeaderItems}
          />
        )}
      </Box>
    </Layout>
  )
}
