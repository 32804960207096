import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { useActor, useSelector } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { IssuanceTransactionsTable } from 'src/components/IssuanceFlow/IssuanceTransactionsTable'
import { DockTransactionsDefaultViewProps } from 'src/components/IssuanceFlow/dockTransactions/DockTransactionsFlowStart'
import { issuanceDockTransactionsMachineModel } from 'src/machines/issuanceDockTransactionsMachine'

export type ListOfDockTransactionsProps = DockTransactionsDefaultViewProps

const { REVIEW_TRANSACTIONS } = issuanceDockTransactionsMachineModel.events

export const ListOfDockTransactions: FC<ListOfDockTransactionsProps> = ({
  service,
  children,
}) => {
  const { t } = useTranslation()
  const [, send] = useActor(service)

  const dataGridService = useSelector(
    service,
    currentState => currentState.context.transactionsDataGridService
  )

  const noTransactionsSelected = useSelector(
    dataGridService,
    currentState => currentState.context.selectedTransactions.length <= 0
  )

  const fustpassContext = useSelector(
    service,
    currentState => currentState.context.scannedFustPassContext
  )

  const loading = useSelector(dataGridService, currentState =>
    currentState.hasTag('loading')
  )

  return (
    <FlowView p={0}>
      <FlowViewContent>
        {children}
        <Stack direction={'column'} gap={2} pt={2} px={2}>
          <RfhTypography variant={'text-micro-bold'}>
            {t('transactions.selectMultiple')}
          </RfhTypography>
          <IssuanceTransactionsTable
            columns={[
              '',
              t('transactions.header.date'),
              t('transactions.header.customer'),
              t('countTable.headings.stw'),
              '',
            ]}
            dataGridService={dataGridService}
            fustpassContext={fustpassContext}
          />
        </Stack>
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button
          variant={'contained'}
          onClick={() => {
            send(REVIEW_TRANSACTIONS())
          }}
          disabled={loading || noTransactionsSelected}
        >
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
