import {
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@rfh/ui/components/Inputs/TextField'
import Button from '@rfh/ui/components/RfhButton/RfhButton'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'

import { OrangeScanIcon } from 'src/components/common.styles'
import { BaseDialog } from 'src/components/dialogs/BaseDialog'
import { useCard } from 'src/hooks/useCard'
import { Authority } from 'src/types/authority'
import { Card } from 'src/types/card'
import { UserType } from 'src/types/customer'
import { FustTransaction } from 'src/types/transaction'

export type CardValidation = {
  isCustomer: boolean
  isEmployee: boolean
  cardIsValid: boolean
  hasAuthorities: boolean
  isTransporter: boolean
}

export type FustPassNumberDialogProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onCardIsValid: (
    card: Card,
    cardValidation: CardValidation,
    authorities: Authority[]
  ) => void
  hasAuthorityApi?: boolean
  selectedTransactions?: FustTransaction[]
  hasMasterpasOverride?: boolean
}
export const FustPassNumberDialog: FC<FustPassNumberDialogProps> = ({
  onCardIsValid,
  isOpen,
  setIsOpen,
  hasAuthorityApi = false,
  selectedTransactions,
  hasMasterpasOverride = false,
}) => {
  const { t } = useTranslation()

  const { card, getCard, authorities, apiError, loading } = useCard()

  const [customerNumber, setCustomerNumber] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState<string>(
    apiError ? t('errorMessage.incorrectFustpass') : ''
  )

  useEffect(() => {
    if (apiError) {
      setErrorMessage(t('errorMessage.incorrectFustpass'))
    }
  }, [apiError])

  useEffect(() => {
    const cardIsValid = card && !card?.isGeblokkeerd && card?.klant.isGeactiveerd

    const customerNumberIsValid = selectedTransactions
      ? selectedTransactions.filter(transaction => {
          if (card?.klant.type === UserType.Transporter) {
            return transaction.vervoerderNummer === card.klant.nummer
          } else {
            return transaction.klantnummer === card?.klant.nummer
          }
        }).length > 0
      : true

    if (cardIsValid) {
      const isEmployee = card?.klant.type === UserType.Employee
      const isCustomer = card?.klant.type === UserType.Customer
      const isTransporter = card?.klant.type === UserType.Transporter

      const hasAuthorities = authorities && authorities.length > 0
      if (customerNumberIsValid || (isEmployee && hasMasterpasOverride)) {
        onCardIsValid(
          card,
          {
            isCustomer,
            isEmployee,
            cardIsValid,
            hasAuthorities,
            isTransporter,
          },
          authorities
        )
      } else {
        setErrorMessage(t('errorMessage.incorrectFustpass'))
      }
    } else if (card) {
      if (card?.isGeblokkeerd) {
        setErrorMessage(t('errorMessage.blockedCard'))
      } else if (!card?.klant.isGeactiveerd) {
        setErrorMessage(t('errorMessage.notActivatedCard'))
      }
    }
  }, [card])

  const handleSubmit = () => {
    getCard(customerNumber?.replace(/\s/g, ''), hasAuthorityApi)
  }
  const handleChange: ChangeEventHandler<HTMLInputElement> = event =>
    setCustomerNumber(event.target.value)

  return (
    <BaseDialog isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleSubmit}>
      <RfhTypography variant={'subheader'}>{t('dialog.fustpassScan')}</RfhTypography>

      <TextField
        autoComplete={'off'}
        error={!!errorMessage}
        helperText={errorMessage}
        prefixTextOrIcon={<OrangeScanIcon />}
        autoFocus
        value={customerNumber}
        onChange={handleChange}
        fullWidth
        inputProps={{
          'aria-label': 'fustpasnummer',
          inputMode: 'numeric',
          pattern: '[0-9\\s]*',
          maxLength: 17,
        }}
      />
      <Button
        variant='block--contained'
        color='primary'
        fullWidth
        type={'submit'}
        isLoading={loading}
      >
        {t('button.confirm')}
      </Button>
    </BaseDialog>
  )
}
