import { createModel } from 'xstate/lib/model'

import { Authority } from 'src/types/authority'
import { Customer, UserType } from 'src/types/customer'

import { Card } from '../types/card'

export type ScanFustPassFlowMachineContext = {
  scannedFustPass?: Card
  additionalCustomerNumber?: number
  additionalCustomerName?: string
  authorityList?: Authority[]
}
export const scanFustPassFlowMachineModel = createModel(
  {} as ScanFustPassFlowMachineContext,
  {
    events: {
      SUBMIT_VALIDATED_FUST_PASS: (card: Card, authorityList: Authority[]) => ({
        card,
        authorityList,
      }),
      CLOSE_DIALOG: () => ({}),
      START_SCAN_PROCESS: () => ({}),
      SUBMIT_VALIDATED_MASTERPAS_CUSTOMER: (customer: Customer) => ({
        customer,
      }),
    },
  }
)
export const getCustomerNumber = (
  context: ScanFustPassFlowMachineContext,
  selectedAuthority?: Authority
): number =>
  context.additionalCustomerNumber ||
  selectedAuthority?.id ||
  context.scannedFustPass!.klant.nummer

export const isAuthorizedUser = (context: ScanFustPassFlowMachineContext): boolean =>
  context.scannedFustPass?.klant.type === UserType.Customer ||
  context.scannedFustPass?.klant.type === UserType.Transporter

export const isEmployeeMastercard = (
  context: ScanFustPassFlowMachineContext
): boolean => context.scannedFustPass?.klant.type === UserType.Employee

export const scanFustPassFlowMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5SwMYEMB2AxArrALgApqyxYA2A9gO4B0AlhOWAMQCCAygNID6AKgHkeHAMJsAcjywBVDnx6FOHRKAAOlWPXz1KGFSAAeiAKwAmADQgAnogAcAdgAstAAwuAzKYCMXxwE4A9wA2ewBfUMtUTFwCYlIKGloSAGt6DCgeADNKACcsvHweVRJYHgwcAFsAIzAc2gA3NHJGNHxIfIIikpZ9dU1tXX0jBGM-INp3LyDHL2C-Lxd7e0sbBCDbP1oN4ydJ21NHF2NwyPRsAriyKjoUtIzsvMyCrtIyypqclg5pACEAWQAkvIAGpsAAyAIAImw+ABRSFSWTyRQcZRIEB9LQ6PTo4aeFxbEJePy2Mz2LxOYyOFZ2RymVw+FwzUwuUyePwnEBRc6xEoJG6wVLpLK5DqFYqvcrVWosERggQcWE8SEA8ECADivQ0WMGuMQfiZtGmniCxn2ZgOxhpa3JtEOdK8B1MfmMLi8xwiXLOMSIfOuSUFdxFeRQBUoFVqb2ldUazQgrXaoYI4cjUo+PXRmIGONAw3JBPsTPcG3sLNs-mtXgcdqCLlJthcQRZjnJnO5Psu-IDQvuoqT+BTeTTMu+-yBPFBEOhcIRIiRAj+sIASjxxNI-j9l1r+tihohS9bTKZjLQXftbLWzS3TR7TtELn7ErdhQ8eP3B1H03KFUqVWrNZm2rZnuCD5rQhaOMWfilnWFbWIgx4ni6TIwUEtaTGa4SehglAQHA+jtg+8T+gAFvQBDbjqOaGIgji2Na8z0rYkwko6xjuC4fimEEbbekRVyJCg4aqMwbQQJRwF6ggtjurQOwOrMfjuMY7qVjJdrGEESncQc9hBMpYSeoRvLEYkjDMBJu5SQ44zsXpYzuOSjgzIerp2i2F7EmyVLce4vH3iZAkCj2wZii8pTDjGTQtGJYUSvAgE7rquaINM9LBI4ozzNMjr6daF7OMxxIyceHFcYZd48r6pnBUGr5PJ08WfrUlnJTRCDOuMF7kiSZIUpl1LwaBbiuIcJKkg5jkckZfGBV2z69iGYYRkO7y1A00XxrF74rc1OStdRwx0uMfgefYym6e4zmHu4mynfYzFLFSt2df5VWdv6C2hTtqZrftiVUSBdH5e4p62PskGOm6mUHG9HaPtQB0gQclZeKeATQZxmMsrdthYaEQA */
  scanFustPassFlowMachineModel.createMachine(
    {
      tsTypes: {} as import('./scanFustPassFlowMachine.typegen').Typegen0,
      predictableActionArguments: true,
      context: scanFustPassFlowMachineModel.initialContext,
      id: 'scanFustPassFlow',
      initial: 'idle',
      states: {
        completed: {
          id: 'completed',
          after: {
            500: {
              target: 'idle',
            },
          },
        },
        idle: {
          on: {
            START_SCAN_PROCESS: {
              actions: 'assignResetData',
              target: 'asking_for_fust_pass_number',
            },
          },
        },
        asking_for_fust_pass_number: {
          description: 'using <SmartFussPassNumberDialog /> to validate the number',
          on: {
            SUBMIT_VALIDATED_FUST_PASS: {
              actions: 'assignValidatedFustPass',
              target: 'validated_fust_pass',
            },
            CLOSE_DIALOG: {
              target: 'idle',
            },
          },
        },
        validated_fust_pass: {
          always: [
            {
              cond: 'isAuthorizedUser',
              target: '#completed',
            },
            {
              cond: 'isEmployeeMastercard',
              target: 'asking_for_masterpas_customer_number',
            },
          ],
        },
        asking_for_masterpas_customer_number: {
          description: 'using <MasterFustPassDialog /> to validate the number',
          on: {
            SUBMIT_VALIDATED_MASTERPAS_CUSTOMER: {
              actions: 'assignValidatedMasterpasCustomer',
              target: 'validated_customer_number',
            },
            CLOSE_DIALOG: {
              target: '#scanFustPassFlow.idle',
            },
          },
        },
        validated_customer_number: {
          always: {
            target: '#completed',
          },
        },
      },
    },
    {
      guards: {
        isAuthorizedUser,
        isEmployeeMastercard,
      },
      actions: {
        assignResetData: scanFustPassFlowMachineModel.assign(() => {
          return {
            additionalCustomerNumber: undefined,
            scannedFustPass: undefined,
          }
        }),
        assignValidatedFustPass: scanFustPassFlowMachineModel.assign(
          (_context, event) => {
            return {
              scannedFustPass: event.card,
              authorityList: event.authorityList,
            }
          }
        ),
        assignValidatedMasterpasCustomer: scanFustPassFlowMachineModel.assign(
          (context, event) => {
            return {
              scannedFustPass: { ...context.scannedFustPass!, klant: event.customer },
              additionalCustomerNumber: event.customer.nummer,
              additionalCustomerName: event.customer.naam,
            }
          }
        ),
      },
    }
  )
