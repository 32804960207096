import { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import {
  FustAmountDataGrid,
  FustAmountDataGridProps,
} from 'src/components/IssuanceFlow/FustAmountDataGrid'
import nlTranslations from 'src/i18n/nl/translations.json'
import { FustTransaction } from 'src/types/transaction'

export type ReviewSingleTransactionViewProps = {
  title?: string
  loading?: boolean
  selectedTransactions: FustTransaction[]
  submissionClickHandler: MouseEventHandler
} & Partial<
  Pick<
    FustAmountDataGridProps,
    | 'onSplitTransactionHandler'
    | 'onEditTransactionHandler'
    | 'isEditable'
    | 'isDividable'
    | 'hasTotals'
  >
>

export const ReviewSingleTransactionView: FC<ReviewSingleTransactionViewProps> = ({
  children,
  loading,
  selectedTransactions,
  submissionClickHandler,
  onEditTransactionHandler,
  onSplitTransactionHandler,
  isEditable,
  isDividable,
  hasTotals,
  title = nlTranslations.issuance.validateOrders,
}) => {
  const { t } = useTranslation()
  return (
    <FlowView loading={loading} p={0}>
      <FlowViewContent>
        {children}
        {selectedTransactions.map(transaction => (
          <FustAmountDataGrid
            hasTotals={hasTotals}
            isEditable={isEditable}
            isDividable={isDividable}
            key={transaction.id}
            transaction={transaction}
            fustAmount={transaction.fustAantallen}
            title={title}
            onSplitTransactionHandler={onSplitTransactionHandler}
            onEditTransactionHandler={onEditTransactionHandler}
          />
        ))}
      </FlowViewContent>
      <FlowViewActions px={2}>
        <Button variant={'contained'} onClick={submissionClickHandler}>
          {t('button.handle')}
        </Button>
      </FlowViewActions>
    </FlowView>
  )
}
