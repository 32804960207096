import { FC } from 'react'

import { SvgIcon } from '@mui/material'

export const ErrorIcon: FC = () => (
  <SvgIcon viewBox='0 0 24 24'>
    <rect x='10.97' y='6.02' width='2' height='8' />
    <path d='M12,2c5.51,0,10,4.49,10,10s-4.49,10-10,10S2,17.51,2,12,6.49,2,12,2m0-2C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.63,0,12,0h0Z' />
    <rect x='10.97' y='15.02' width='2' height='2' />
  </SvgIcon>
)
