import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import RfhTypography from '@rfh/ui/components/RfhTypography/RfhTypography'
import { EditIcon } from '@rfh/ui/shared/floriday-icons'
import { useActor } from '@xstate/react'

import { FlowView, FlowViewActions, FlowViewContent } from 'src/components/FlowView'
import { RoundIconButton } from 'src/components/RoundIconButton'
import { TransactionsHeader } from 'src/components/TransactionsHeader'
import { ValidatedCountTable } from 'src/components/ValidatedCountTable'
import { SuccessDialog } from 'src/components/dialogs/SuccessDialog'
import {
  interDepotTransportMachineModel,
  interDepotTransportService,
} from 'src/machines/interDepotTransportMachine'

const { SUBMIT_TRANSACTION, EDIT_TRANSACTION } = interDepotTransportMachineModel.events

export const Review: FC<{ service: typeof interDepotTransportService }> = ({
  service,
}) => {
  const { t } = useTranslation()
  const [state, send] = useActor(service)
  const navigate = useNavigate()

  return (
    <>
      <SuccessDialog
        title={t('interDepotTransport.captured')}
        isOpen={state.matches('showing_success_dialog')}
        handleClose={() => {
          navigate('/')
        }}
      />
      <FlowView p={0} loading={state.hasTag('loading')}>
        <FlowViewContent>
          <TransactionsHeader
            items={[
              {
                label: 'departureLocation',
                value: state.context.selectedDepartureLocation,
              },
              {
                label: 'arrivalLocation',
                value: state.context.selectedArrivalLocation,
              },
            ]}
          />
          <Stack
            direction={'row'}
            px={2}
            py={2}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <RfhTypography variant={'subtitle1'}>
              {t('transactions.validate')}
            </RfhTypography>
            <RoundIconButton
              variant='outlined'
              icon={<EditIcon />}
              label={t('button.edit')}
              onClick={() => {
                send(EDIT_TRANSACTION())
              }}
            />
          </Stack>
          <ValidatedCountTable
            columns={['fustCode', 'itemState', 'stapelwagens', 'stapels', 'stuks']}
            isAnnouncedTransaction={false}
            rows={state.context.fustAmount}
          />
        </FlowViewContent>
        <FlowViewActions px={2}>
          <Button
            disabled={!state.can('SUBMIT_TRANSACTION')}
            variant={'contained'}
            onClick={() => send(SUBMIT_TRANSACTION())}
          >
            {t('button.handle')}
          </Button>
        </FlowViewActions>
      </FlowView>
    </>
  )
}
